import {Component, NgZone, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private app: AppComponent, private router: Router, public auth: AuthService) { }

  ngOnInit() {
    this.app.setTitle('Admin');
  }

  navTo(url) {
    this.router.navigateByUrl(url);
  }

}
