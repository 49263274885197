export enum ControlsEnum {
    TEXT = 1,
    DATE = 2,
    TIME = 3,
    BOOL_TF = 4,
    BOOL_YN = 5,
    MULTIPLE_CHOICE = 6,
    SINGLE_CHOICE = 7,
    RANGE_SLIDER = 8,
    ROUND_SLIDER = 9,
    CONDITIONAL_YN = 10,
    DATETIME = 11,
    EMAIL = 12,
    PASSWORD = 13,
    SINGLE_CHOICE_INLINE = 14,
    NUMBER = 15,
    PHONE = 16,
    ENTRIES_COUNT = 17,
    ENTRIES_PER_DAY = 18,
    ENTRIES_PER_DAY_WEEK = 19,
    EMA_FEEDBACK = 20,
    ENTRIES_PER_ACTIVITY = 21,
    POPULAR_ACTIVITIES = 22,
    POPULAR_PEOPLE = 23,
    DONUT_CHART = 24,
    COLUMN_GRAPH = 25,
    SERIES_TIME_CHART = 26,
    BUBBLE_GRAPH = 27,
    HORIZONTAL_BAR_GRAPH = 28,
}
