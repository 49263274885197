import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/internal/operators';
import {Client} from '../models/client';
import {Assessment} from '../models/assessment';
import {AssessmentStatus} from './assessment.service';
import {Observable} from 'rxjs';
import {Domain} from '../models/domain';
import {ProviderService} from './provider.service';


@Injectable({
  providedIn: 'root'
})
export class DomainService implements OnDestroy {
  private sub: any;
  domains: any[];

  constructor(private fs: FirebaseService, private db: AngularFirestore) {
  }

  async init() {

    if (this.sub != null) { 
      this.sub.unsubscribe(); 
    }

    const collection = await this.db.collection('/domains', ref => ref.orderBy('name'));
    const list = await collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data: any = a.payload.doc.data();
          data.ref = a.payload.doc.ref;
          return data as Domain;
        });
      }));
    this.sub = list.subscribe((domains) => {
      this.domains = domains;
      console.log(this.domains);
      return this.domains;
    });

    return list;

  }

  get() {
    return this.domains.filter(x => !x.isScreening).sort(x => x.order).reverse();
  }

  ngOnDestroy(): void {
    if (this.sub != null) {
      setTimeout(() => {
        this.sub.unsubscribe();
      }, 1000);

    }
  }

  refreshData() {

  }
}
