import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Entry} from '../models/entry';
import { Person } from '../models/person';

import {map} from 'rxjs/internal/operators';
import {Subscription} from 'rxjs';
import {AngularFirestore, Query, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class PeopleService implements OnDestroy {
    people: Person[] = []; 
    private subscription: Subscription;

    constructor(private firestore: FirebaseService, 
        private db: AngularFirestore) {
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async init(uid) { 
        this.people = [];
        
        let collection = await this.db.collection(`clients/${uid}/people`, ref => ref) ; 
        await collection.get().toPromise()
        .then(people => {  
            if (people.docs && people.docs.length > 0) {
                people.docs.map(doc => {
                    const data: any = doc.data();
                    data.ref =  doc.ref;
                    this.people.push(data as Person); 
                });
 
                this.people = this.people.filter((x) => {
                    return x.inuse === true;
                });
            } else {
                this.people = [];
                console.log('No people in storage.');
            }
        });  
    }

    /**
     * Returns the list of in use people
     */
    queryall() {
        return this.people;
    }

    /**
     * Returns the list of in use people
     */
    query() {
        return this.people.filter((person) => {
            if (person.inuse) {return person;}
        });
    }

    /**
     * Returns the list of in use people async
     */
    async queryAsync() {
        return this.people.filter((person) => {
            if (person.inuse) {return person;}
        });
    }

    /**
     * Adds the person to the list.
     * @param person Person to be added to the list.
     */
    async add(person: Person) {
        this.people.push(person);
        this.firestore.add('people', person);
        return this.people;
    }

    /**
     * Deletes the person from the list.
     * @param person Person to be deleted from the list.
     */
    async delete(id: number) {

            return this.getAsync(id).then(person => {
                person.inuse = false;
                this.update(person);
                return this.people;
            });

    }

    update(person: Person) {
        this.firestore.update('people', person);
    }

    /**
     * Undoes a deletion
     * @param id
     */
    async undoDelete(id: number) {

            return this.getAsync(id).then(person => {
                person.inuse = true;
                this.update(person);
                return this.people;
            });

    }

    /**
     * Returns the person with the same ID as the parameter.
     * @param id The ID of the person to be returned
     */
    async getAsync(id: number) { 
        // return this.init().then(r => {
            for (const person of this.people) {
                if (person.id == id) { 
                    return person;
                }
            }
        // });
    }

    get(id: number) {
        for (const person of this.people) {
            if (person.id == id) {
                return person;
            }
        }
    }

    /**
     * Returns the highest person ID + 1
     */
    getNextID() {
        let highestNumber = 0;
        for (const person of this.people) {
            if (person.id > highestNumber) {
                highestNumber = person.id;
            }
        }
        return (highestNumber + 1);
    }
}