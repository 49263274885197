import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {AngularFirestore, Query} from '@angular/fire/firestore';
import {map} from 'rxjs/internal/operators'; 
import {Observable} from 'rxjs';  
import {AuthService} from './auth.service';
import {Notification} from '../models/notification';


@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy { 
  private subscription: any;
  notifications: number; 

  constructor(private auth: AuthService, private db: AngularFirestore) {
  }

  async init() {
    if (this.auth.intuiUser != null) {
      this.getNotifications(this.auth.intuiUser.providerId);
    }
  }


  async getNotifications(providerRef) {   
      
    if (this.subscription) {
      this.subscription.unsubscribe(); 
    }  
    
    let collection =  await this.db.collection(`providers/${providerRef.id}/notifications`, ref => {
      let query : Query = ref; 
      query = query.where('completed', '==', false).where('state', '==', 'New');// number; //  New -> PENDING: ACTION TAKEN -> COMPLETED* -> IGNORE*  
      
      if(!this.auth.isAdmin() && !this.auth.isProviderAdmin()){
        query = query.where('advisorRef', '==', this.auth.intuiUser.ref) ; 
      }
      return query;
    });  

    const list = await collection.snapshotChanges().pipe(
      map(clients => {   
        return clients.length; 
      })
    ); 

    //let firstQuery = true;
    this.subscription = list.subscribe(async (notifications) => {   
      this.notifications = notifications;     
      console.log('snapShot.docs.length', this.notifications ); 
    } , error => { 
      this.notifications = 99;     
    });          
  } 

  
  async getAllNotifications(clientRef, completed, state) {   
    const notifications : Notification [] = []; 

    //Notifiation  
    const notificationCollection = this.db.collection(this.auth.intuiUser.providerId.path + '/notifications', ref => {
      let query : Query = ref;  
      query = query.where('clientRef', '==', clientRef);  
      //query = query.where('completed', '==', false);  
      //query = query.where('state', '==', 'New');   
      query = query.orderBy('modified', 'desc'); 
      return query;
    });  

    const notificationSnap = await notificationCollection.get().toPromise();

    for (const doc of notificationSnap.docs) {
      const data = doc.data() as Notification;  
      data.ref = doc.ref;
      notifications.push(data);  
    } 
    return notifications;
  } 

  ngOnDestroy(): void { 
    if (this.subscription != null) {
      setTimeout(() => {
        this.subscription.unsubscribe();
      }, 1000);

    }
  }

  refreshData() { 
  }
}
