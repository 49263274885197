import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import {XAxisOptions} from 'highcharts'; 

@Component({
  selector: 'app-spline-graph',
  templateUrl: './spline-graph.component.html',
  styleUrls: ['./spline-graph.component.scss']
})
export class SplineGraphComponent implements OnInit {
    charts : any;

  //constructor(private alertCtrl: AlertController) { }

    @Input()
    id: any;

    @Input()
    title: any;

    @Input()
    subTitle: any;

    @Input()
    yLabel: any;

    @Input()
    xLabel: any;

    @Input()
    data: any;

    @Input()
    helpText: string;

    @Input()
    pointFormatType: string; 

    ngOnInit() {

      console.log('pointFormatType', this.pointFormatType);
      
      let pointFormat =  '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y}</b></td></tr>' +
                         '<tr><td style="color:{series.color};padding:0">Activities: </td><td style="padding:0"><b>{point.a}</b></td></tr>' +
                         '<tr><td style="color:{series.color};padding:0">People: </td><td style="padding:0"><b>{point.p}</b></td></tr>';
                            
      if(this.pointFormatType && this.pointFormatType == '1'){ 
        pointFormat = '<tr><td style="color:{series.color};padding:0">{series.name}: </td><td style="padding:0"><b>{point.y}</b></td></tr>';
      }

      setTimeout(() => {

        this.charts = new Chart({

              chart: {
                  spacingRight: 10,
                  spacingLeft: 0,
              },
              title: {
                  text:(this.subTitle) ? this.subTitle : ''
              },
              xAxis: {
                  type: 'datetime',
                  dateTimeLabelFormats: {
                      month: '%e. %b',
                      year: '%b'
                  },
                  title: {
                      text: this.xLabel
                  }
              } as XAxisOptions,
              yAxis: {
                  title: {
                      text: this.yLabel
                  },
                  min: 0,
                  max: 100
              },
              legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle'
              }, 
              tooltip: {
                  headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                  pointFormat: pointFormat,
                  footerFormat: '</table>',
                  shared: true,
                  useHTML: true
              },
              plotOptions: {
                  series: {
                      label: {
                          connectorAllowed: false
                      }
                  }
              },

             // colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

              // Define the data points. All series have a dummy year
              // of 1970/71 in order to be compared on the same x axis. Note
              // that in JavaScript, months start at 0 for January, 1 for February etc.
              series: this.data,

              responsive: {
                  rules: [{
                      condition: {
                          maxWidth: 500
                      },
                      chartOptions: {
                          legend: {
                              layout: 'horizontal',
                              align: 'center',
                              verticalAlign: 'bottom'
                          }
                      }
                  }]
              }
          });
      }, 500);
  }

  getChart() {  
    return this.charts;
  } 

/*
    async presentAlert() {
        const title = this.title;
        const subTitle = this.helpText;


        const alert = await this.alertCtrl.create({
            header: title,
            message: subTitle,
            buttons: ['OK']
        });
        alert.present();

    }
*/
}
