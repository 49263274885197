export function plainifyObj(obj: any) {
  return Object.assign({}, obj);
}

export function compare(a, b) {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
} 

export function encodeBase64(parameters: string []) {
  const paramString = parameters.join();
  return btoa(paramString);
}
 
export function decodeBase64(parameter: string) {
  console.log(parameter);
  console.log(atob(parameter));
  const params = atob(parameter).split(',');  
  return params;
}



