import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';

export function MustMatch(control: AbstractControl, matchingControl: AbstractControl): ValidatorFn {
// export function MustMatch(controlName: string, matchingControlName: string) {
  return (): { [key: string]: boolean } | null => {
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };

}
