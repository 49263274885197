import {Component, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import {AppComponent} from '../../app.component';
import {MatDialog, MatSlideToggleChange, MatSnackBar} from '@angular/material';
import {FirebaseService} from '../../services/firebase.service';
import {ClientsDialogComponent} from './clients.dialog/clients.dialog.component';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {DeleteDialogComponent} from '../../components/dialog/delete/delete.dialog.component';
import {CodeDialogComponent} from '../../components/dialog/code/code.dialog.component';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore, DocumentReference, Query} from '@angular/fire/firestore';
import {HealthAssessmentDialogComponent} from '../../components/dialog/health-assessment/health-assessment.component';
import {animate, style, transition, trigger} from '@angular/animations';
import {AssessmentService} from '../../services/assessment.service';
import {User} from '../../models/user';
import {map} from 'rxjs/internal/operators';
import {CircleMember} from '../../models/circle_member';
import {CognitoMember} from '../../models/cognito_member'
import {Client} from '../../models/client';
import {ClientService} from '../../services/client.service';
import {DomainService} from '../../services/domain.service';
import {environment} from '../../../environments/environment';
import {HttpClientModule, HttpClientJsonpModule, HttpClient, HttpHeaders } from '@angular/common/http';
import {PageEvent, MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-users',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ]),
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity: 1}))
      ])

    ])
  ]
})
export class ClientsComponent implements OnInit, OnDestroy {

  @ViewChild('paginator', null) paginator: MatPaginator;
  @ViewChild('content', {static: false}) content: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef;


  // MatPaginator Inputs
  pageLength: number = 0;
  pageSize: number = 5;  //displaying 10 cards each row
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pagedList: Client[]= [];

  clients: Client [];
  private dialogRef;
  private collName: string;
  public loading: boolean;
  showArchived = false;
  client: Client;
  clientId: string | null;
  actionId: string | null;
  circle: any[];
  notifications: number = 0;
  active: string;
  currentStateTitle: string;
  public breakpoint: number;
  private notificationsub: Subscription;
  private clientsub: Subscription;
  private circlesub: Subscription;

  searchText: string;

  constructor(private app: AppComponent,
              private dialog: MatDialog,
              private fs: FirebaseService,
              public auth: AuthService,
              private router: Router,
              private assessmentService: AssessmentService,
              private domainService: DomainService,
              private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              public appComponent: AppComponent,
              private db: AngularFirestore,
              private snackBar: MatSnackBar,
              private http: HttpClient) { }

  ngOnInit() {

    this.app.setTitle('Clients');
    this.loading = true;
    this.clientId = this.activatedRoute.snapshot.paramMap.get('id');
    this.actionId = this.activatedRoute.snapshot.paramMap.get('actionId'); 
    this.getData();
    
    if(this.actionId && this.actionId === 'notification'){
      this.showNotification();  
    }else{
      this.showSummary(); 
    }

    this.appComponent.menuOpened.subscribe(n => {
      this.breakpoint = (window.innerWidth <= 900 || n && window.innerWidth <= 1150) ? 1 : 2;
    });

    this.breakpoint = (window.innerWidth <= 900 || this.appComponent.opened && window.innerWidth <= 1150) ? 1 : 2;

  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900 || this.appComponent.opened && window.innerWidth <= 1150) ? 1 : 2;
  }

  onChangedNewAssessment(event, client){
    console.log('onChangedNewAssessment.event.value', event.value, client);
    if(event.value == 'email'){
      this.sendHealthAssessment(client);
    }else if(event.value == 'new'){
      this.newHealthAssessment(client);
    }
  }

  private async  getData() {

    if (this.clientId != null) {

      if (this.circlesub) {
        this.circlesub.unsubscribe();
      }
      if (this.notificationsub) {
        this.notificationsub.unsubscribe();
      }

      setTimeout(() => {
          this.client = this.clientService.clients.find(x => x.ref.id === this.clientId);
          //Circle memeber
          const circleSnap = this.db.collection(this.client.ref.path + '/circle', ref => ref.where('deleted', '==', false)).snapshotChanges().pipe(
            map(actions => {
              return actions.map(a => {
                const data = a.payload.doc.data() as CircleMember;
                data.ref = a.payload.doc.ref;
                return data as CircleMember;
              });
            }));

          this.circlesub = circleSnap.subscribe((circle) => {
            this.circle = circle;
            this.getCogintoUsers(this.circle);
          });

          //Notifiation
          const notificationCollection = this.db.collection(this.auth.intuiUser.providerId.path + '/notifications', ref => {
            let query : Query = ref;
            query = query.where('clientRef', '==', this.client.ref);
            query = query.where('state', '==', 'New');
            query = query.orderBy('modified', 'desc');
            return query;
          });

          const notificationSnap = notificationCollection.snapshotChanges().pipe(
            map(actions => {
              return actions.length; 
            }));

          this.notificationsub = notificationSnap.subscribe((notifications : any) => {
            this.notifications = notifications;   
          } , error => { 
            this.notifications = 0;     
          });

      }, 1000);

    } else  {
      this.client = null;
      if (this.auth.intuiUser) {
        if(this.clients != null && this.clients.length > 0){
          this.loading = false;
        } else {
          await this.getClients(this.auth.intuiUser.providerId);
        }
      } else {
        setTimeout(() => { this.getData(); }, 1000);
      }
    }
  }

  async initializePage(){
    this.pageLength = 0;
    this.pagedList = [];
    if(this.clients && this.clients.length > 0){
      this.pageLength = this.clients.length;
      this.pagedList = this.clients.slice(0, this.pageSize);

      await this.getCogintoUsers(this.pagedList);
      await this.getCogintoUsersForAdvisor(this.pagedList);
    }else{
      this.loading = false;
    }
    if(this.paginator)  this.paginator.firstPage();
  }


  async OnPageChange(event: PageEvent){

    this.loading = true;

    let startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    this.pageSize = event.pageSize;
    if(endIndex > this.pageLength){
      endIndex = this.pageLength;
    }
    this.pagedList = this.clients.slice(startIndex, endIndex);

    this.getCogintoUsers(this.pagedList);
  }

  async getClients(providerRef) {

    if (this.clientsub) {
      this.clientsub.unsubscribe();
    }

    let collection =  await this.db.collection('clients', ref => {
        let query : Query = ref;
        query = query.where('providers', 'array-contains', providerRef)
            .where('deleted', '==', this.showArchived) ;
            //.where('status', '<', 6)//Unknown
            //.where('deleted', '==', showArchived)
        //if(this.showMyClients){
        //  query = query.where('userRef', '==', this.auth.intuiUser.ref) ;
        //}
        query = query.orderBy('created', 'desc');

        //if(!this.showRisk){
        //  query = query.limit(environment.pagination.client);
        //}
        return query;
      });

      const list = await collection.snapshotChanges().pipe(
        map(clients => {
          const clientList = clients.map(a => {
            const data:any = a.payload.doc.data();
            data.ref = a.payload.doc.ref;
            return data as Client;
          });
          /*
          if(this.showRisk){
            return clientList.reduce((result, element) =>{
              if(this.getTrend(element.trends) < 0){
                result.push(element);
              }
              return result;
            }, []);
          }
          */
          return clientList;
        })
      );

      //let firstQuery = true;
      this.clientsub = list.subscribe((clients) => {
        this.clients = clients;
        this.clientService.clients = clients; //commnet out clientService.init(aaa)
        //if(firstQuery){
          this.initializePage();
        //}
        //if(firstQuery) firstQuery = false;
      } , error => {
        console.log('this.data.err 111===' + this.auth.intuiUser.uid);
        console.log('this.data.err 111===' + error);
      });
  }

  async getCogintoUsers(list){

    let uids = list.reduce((result, element) =>{
      if(!element.firstName && element.uid){
        result.push(element.uid);
      }
      return result;
    }, []);

    if(uids.length == 0){
      this.loading = false;
      return;
    }

    let cognitoUserMap = new Map<string, CognitoMember>();
    const url = environment.awsApis.userList + '&uids=' + uids;
    const res = await this.http.get(url).toPromise()
    .then(res => {

      res['Users'].map(item => {
        const row = new CognitoMember(item);
        cognitoUserMap.set(row.username, row);
      });

      list.map(member => {
        let row = cognitoUserMap.get(member.uid) as CognitoMember;
        if(row){
          member.firstName = row.firstName;
          member.lastName = row.lastName;
          member.email = row.email;
          member.gender = row.gender ? row.gender.toLowerCase() : '';
          member.dob = new Date(row.birthdate);
        }
      });

      //console.log('Client List',this.clients);
      this.loading = false;
    }).catch(err=>{
      this.loading = false;
      console.log('this.data.err 222===' + err);
    });
  }


  async getCogintoUsersForAdvisor(list){
    let uids = list.reduce((result, element) =>{
      if(!element.userName && element.userRef && element.userRef.id){
        result.push(element.userRef.id);
      }
      return result;
    }, []);

    if(uids.length == 0){
      this.loading = false;
      return;
    }

    let cognitoUserMap = new Map<string, CognitoMember>();
    const url = environment.awsApis.userList + '&uids=' + uids;
    const res = await this.http.get(url).toPromise()
    .then(res => {

      res['Users'].map(item => {
        const row = new CognitoMember(item);
        cognitoUserMap.set(row.username, row);
      });

      list.map(member => {
        if(member.userRef && member.userRef.id){
          let row = cognitoUserMap.get(member.userRef.id) as CognitoMember;
          if(row){
            member.userName = `${row.firstName} ${row.lastName}`;
          }
        }
      });

      //console.log('Client List',this.clients);
      this.loading = false;
    }).catch(err=>{
      this.loading = false;
      console.log('this.data.err 222===' + err);
    });
  }


  async searchInput(){
    if(this.searchText == null || this.searchText.trim().length == 0){
      return await this.getClients(this.auth.intuiUser.providerId);
    } else if(this.searchText.trim().length < 3){
      return ;
    }

    this.loading = true;
    let cognitoUserMap = new Map<string, CognitoMember>();

    let url = environment.awsApis.userList + '&email=' + this.searchText.trim();

    const res = await this.http.get(url).toPromise()
    .then(async res => {
      res['Users'].map(item => {
        const row = new CognitoMember(item);
        cognitoUserMap.set(row.username, row);
      });
      await this.getSearchClients(this.auth.intuiUser.providerId, cognitoUserMap);
      this.loading = false;

      /*
      let uids = Array.from( cognitoUserMap.keys() );
      let promises = uids.map(uid => {
        return this.getClient(this.auth.intuiUser.providerId, uid);
      });
      Promise.all(promises).then(members => {
        this.clients = [];
        this.clients = members.map(member => {
          if(member == null){
            return null;
          }
          let row = cognitoUserMap.get(member.uid) as CognitoMember;
          if(row){
            member.email = row.email;
            member.firstName = row.firstName;
            member.lastName = row.lastName;
            member.gender = row.gender ? row.gender.toLowerCase() : '';
            member.dob = new Date(row.birthdate);
          }
          return member;
        });
        this.clients = this.clients.filter(obj => obj != null);//not found in firebase even if cognito has
        this.clientService.clients = this.clients; //commnet out clientService.init(aaa)

        this.initializePage();

        console.log('Client Search', this.clients);
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
      */

    }).catch(err=>{
      this.loading = false;
      console.log('this.data.err 222===' + err);
    });
  }

  async getSearchClients(providerRef, cognitoUserMap : Map<string, CognitoMember>) {

    if (this.clientsub) {
      this.clientsub.unsubscribe();
    }

    let collection =  await this.db.collection('clients', ref => {
        let query : Query = ref;
        query = query.where('providers', 'array-contains', providerRef)
            .where('deleted', '==', this.showArchived) ;
            //.where('status', '<', 6)//Unknown
            //.where('deleted', '==', showArchived)
        //if(this.showMyClients){
        //  query = query.where('userRef', '==', this.auth.intuiUser.ref) ;
        //}
        query = query.orderBy('created', 'desc');

        //if(!this.showRisk){
        //  query = query.limit(environment.pagination.client);
        //}
        return query;
      });

      const list = await collection.snapshotChanges().pipe(
        map(clients => {
          let clientList = clients.map(a => {
            const data:any = a.payload.doc.data();
            data.ref = a.payload.doc.ref;
            let member = data as Client;

            let row = cognitoUserMap.get(member.uid) as CognitoMember;
            if(row){
              member.email = row.email;
              member.firstName = row.firstName;
              member.lastName = row.lastName;
              member.gender = row.gender ? row.gender.toLowerCase() : '';
              member.dob = new Date(row.birthdate);
              return member;
            }else{
              return null;
            }
          });

          clientList = clientList.filter(function(client) {
            if (client) {
              return true; // skip
            }
            return false;
          })
          /*
          if(this.showRisk){
            return clientList.reduce((result, element) =>{
              if(this.getTrend(element.trends) < 0){
                result.push(element);
              }
              return result;
            }, []);
          }
          */
          return clientList;
        })
      );

      //let searchQuery = true;
      this.clientsub = list.subscribe((clients) => {
        this.clients = clients;
        this.clientService.clients = clients; //commnet out clientService.init(aaa)
        //if(searchQuery){
          this.initializePage();
        //}
        //if(searchQuery) searchQuery = false;
      } , error => {
        console.log('this.getSearchClients.err 111===' + this.auth.intuiUser.uid);
        console.log('this.getSearchClients.err 111===' + error);
      });
  }


  ngOnDestroy(): void {
    if (this.circlesub) {
      this.circlesub.unsubscribe();
    }
    if (this.clientsub) {
      this.clientsub.unsubscribe();
    }
    if (this.notificationsub) {
      this.notificationsub.unsubscribe();
    }
  }

  addClient() {

      this.dialogRef = this.dialog.open(ClientsDialogComponent, {
        height: 'auto',
        width: '80vw',
        data: {isEdit : false}
      });

      this.dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (result) {
          //result.providers = [this.auth.intuiUser.providerId];
          //result.interum = true;
          //this.fs.add('clients', result);
          //TODO Send code invite
          //this.code()
          //this.getData();

          this.loading = true;
          this.getClients(this.auth.intuiUser.providerId);
        }
      });

  }

  addCircleMember() {

    this.dialogRef = this.dialog.open(ClientsDialogComponent, {
      height: 'auto',
      width: '80vw',
      data: {title: 'Add Circle Member', isCircleMember: true, clientIdForCircleMember: this.clientId, isEdit : false}
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        //result.providers = [this.auth.intuiUser.providerId];
        //result.primary = false;
        //result.interum = true;
        //this.fs.add('clients/' + this.clientId + '/circle', result);
        //TODO Send code invite
        //this.code()
        this.getData();
      }
    });

  }

  getNotificationsText(){
    if(!this.notifications){
      return '';
    }else if(this.notifications == 0){
      return 'No Data';
    }else if(this.notifications == 1){
      return "There is 1 'new' Notification for this client.";
    }
    return `There are ${this.notifications} 'new' Notifications for this client.`;
  }

  getAge(date) {
    if(!date || typeof date.getTime !== "function"){
      return 'unknown ';
    }
    const timeDiff = Math.abs(Date.now() - date.getTime() );
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  getAvatar(client) {
    return (client.avatarUrl != null) ?
      client.avatarUrl : `https://ui-avatars.com/api/?size=130&font-size=0.4&color=ff7f5c&name=${client.firstName}+${client.lastName}`;
  }

  viewClient(client) {
    console.log(client);
    // TODO open up whole client screen.
    this.router.navigateByUrl('client/' + client.ref.id);

  }

  edit(client) {
    client.title = 'Edit Client';
    client.isEdit = true;
    this.dialogRef = this.dialog.open(ClientsDialogComponent, {
      height: 'auto',
      width: '80vw',
      data: client
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      console.log(result);
      if (result) {
        //result.providers = [this.auth.intuiUser.providerId];
        //result.ref = client.ref;
        //this.fs.update('clients', result);
        this.getData();
      }
    });
  }

  delete(client, cc) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      height: '230px',
      width: '300px',
      data: {collection: (cc) ? `${client.ref.path}/circle` : 'clients', obj: (cc) ? cc : client, name: (cc) ? cc.firstName : client.firstName}
    });
  }

  code(client) {
    this.dialogRef = this.dialog.open(CodeDialogComponent, {
      height: '260px',
      width: '340px',
      data: {collection: 'clients', obj: client, provider: this.auth.intuiUser.providerId, isClient: true}
    });
  }

  registered(created) {
    return moment(created.toDate()).format('ddd DD MMM YYYY');
  }

  getEmail(email){
    if(email == null || email === ''){
      return email;
    }else if(email.startsWith('intui-care-client') && email.endsWith('goact.com.au')){
      return '';
    }
    return email;
  }

  showSummary() {
    this.active = 'summary';
    return;
  }

  showHealth() {
    this.active = 'health';
    return;
  }

  showNotification() {
    this.active = 'notification';
    return;
  }

  showReport() {
    this.active = 'report';
    return;
  }

  showGoals() {
    this.active = 'goals';
  }

  showExperiences() {
    this.active = 'experiences';
  }

  newHealthAssessment(clientObj) {

    this.dialogRef = this.dialog.open(HealthAssessmentDialogComponent, {
      height: '90%',
      width: '80vw',
      data: {title: 'New Health Assessment', client: clientObj, invite: false}
    });

    this.dialogRef.afterClosed().subscribe( async (result) => {
      console.log(result);
      if (result) {
          const snap = await result.domain.get();
          console.log('starting assessment..', snap.data().name);
          this.router.navigateByUrl('/client/' + clientObj.ref.id + '/assessment/' + result.ref.id);
      }
      clientObj.newAssessment = '';
    });
  }


  sendHealthAssessment(clientObj) {

    this.dialogRef = this.dialog.open(HealthAssessmentDialogComponent, {
      height: '90%',
      width: '80vw',
      data: {title: 'Send Health Assessment', client: clientObj, invite: true}
    });

    this.dialogRef.afterClosed().subscribe( async (result) => {
      console.log('afterClosed', result);
      if (!result) {
        //DO NOTHING when user click 'OK' button
      }else if (result.invited) {
        this.snackBar.open('Sent successfully!', 'Ok');
      } else if (!result.invited) {
        this.snackBar.open('The system  encountered an unexpected error. Please try again later.', 'Ok');
      }
      clientObj.newAssessment = '';
    });
  }

  makePrimary($event, circle_member) {
    circle_member.primary = $event.target.checked;
    this.db.doc(circle_member.ref.path).update(circle_member);
    console.log();
  }

  switchArchived($event: MatSlideToggleChange) {
    this.showArchived = $event.checked;
    this.loading = true;
    this.getClients(this.auth.intuiUser.providerId);
  }

}
