import {Injectable, OnInit} from '@angular/core'; 
import * as moment from 'moment'; 
import * as Highcharts from 'highcharts'; 

import { Entry } from '../models/entry';
import { Activity } from '../models/activity';
import { Person } from '../models/person';

import { BoundariesService } from './boundaries.service';
import { ControlsService } from './controls.service';
import { ActivitiesService } from './activities.service';
import { PeopleService } from './people.service';
import { EntriesService } from './entries.service'; 
 
import {ControlsEnum} from '../enums/controls.enum';
import {ColorString} from 'highcharts';

import {map} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})

export class StatisticsService  {

  private mood_insights = true;
  private mood_threshold = 50;
  entryValues: Entry[] | null = [];

  // Initialise the statistic variables
  noOfEntries = 0; // Number of entries in use
  noOfActivitiesInUse = 0; // Number of activities in use
  noOfPeopleInUse = 0; // Number of people in use
  noOfEntriesMonth = 0; // Number of entries in the last 30 days + today
  noOfEntriesWeek = 0; // Number of entries in the last week + today
  entriesPerCat : any = {}; 
  noEntriesPerActivityCatArray: number[] = [0, 0, 0, 0, 0, 0]; // The number of entries per activity category (Other, Social, Work, Physical, Routine, Leisure, -Travel-)
  // noEntriesPerBaseEmotionArray: number[] = [0, 0, 0, 0, 0, 0, 0]; // The number of entries per base emotion (angry, surprised, happy, disgusted, sad, fearful, bad)
  // avgIntensityArray: number[] = [0, 0, 0, 0, 0, 0, 0]; // The average intensity of each emotion
  dateDataArray: number[] = []; // An array with the number of entries for each day of the last 30 days + today
  dateLabelArray: string[] = []; // An array with the string of each date for the last 30 days + today
  // emoGranularity = 0; // The average emotional granularity
  popularActivities: Activity[] = []; // The top 5 most popular activities
  popularPeople: Person[] = []; // The top 5 most popular people
  positiveActivities: any[] = []; // The top 5 most popular activities
  positivePeople: any[] = []; // The top 5 most popular people
  // popularEmotions: { id: number, timesused: number }[] = []; // The top 5 most popular emotions
  entriesPerWeekDayArray: number[] = [0, 0, 0, 0, 0, 0, 0]; // The number of entries per week day
  positiveEntriesPerWeekDayArray: number[] = [0, 0, 0, 0, 0, 0, 0];
  avgEntriesPerWeekDayArray: number[] = [0, 0, 0, 0, 0, 0, 0]; // The avg number of entries per week day
  entriesPerTimeArray: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // The number of entries per every 3 hours (00:00-03:00, ..., 20:00-23:00, 01:00-04:00, ..., 21:00-00:00, 02:00-05:00, ..., 22:00-01:00)
  avgNoteLength = 0; // The average number of characters of entry notes
  avgNoActivitiesPerEntry = 0; // The average number of activities per entry
  avgNoPeoplePerEntry = 0; // The average number of people per entry
  avgNoEmotionsPerEntry = 0; // The average number of emotions per entry
  diffHighLowEntriesPerDay = 0; // The difference between the highest number of entries per day and the lowest.
  avgNoEntriesPerDay = 0; // Average number of entries per day
  activitiesVsEmotionsArray: { emotionid: number, activityid: number, times: number }[] = []; // Correlations between activities and base emotions
  activitiesVsBaseEmotionsArray: { emotionid: number, activityid: number, times: number }[] = []; // Correlations between activities and emotions
  activitiesInUseArray: { id: number }[] = []; // The number of activities that are in use
  peopleInUseArray: { id: number }[] = []; // The number of people in use
  peopleVsEmotionsArray: { emotionid: number, personid: number, times: number }[] = []; // Correlations between people and base emotions
  peopleVsBaseEmotionsArray: { emotionid: number, personid: number, times: number }[] = []; // Correlations between people and emotions
  smallestDayArray: string[] = []; // The days of the week with the smallest number of entries
  totalNoOfEmotions = 0;
  entriesPerDay: { date: string, times: number }[] = [];
  statsNotify = 0;

  trendsPageStructure: any;
  alerts: any;
  allActivities: any[] = [];
  private totalCatCount = 0;
  emaResponseData: any[];
  activityPercents: {};
  positiveActivityData: any[];
  positiveActivityBubbleGraphData: any[];
  positiveEntriyCountPerPerson: any[];

  get controlsEnum() {
      return this._controlsEnum;
  }
  private _controlsEnum = ControlsEnum;
  private RANGE_SLIDER  = 'range-slider';

  private stats: any;

  constructor( 
      public controls: ControlsService,
      public activities: ActivitiesService,
      public people: PeopleService,
      public entries: EntriesService,
      //private events: Events,  //comment out by Seoyong
      public boundaries: BoundariesService) {
/* comment out by Seoyong
      this.events.unsubscribe('reloadDetails');
      this.events.subscribe('reloadDetails', () => {
         try {
             this.restat();
         } catch (e) {}
      });
*/
      // TODO. in future this will be in memory and loaded on app startup from server.
      // this.storage.get('insights').then(insights => {
      //     this.insightsStructure = insights;
      // });

      this.alerts = {
          insights: [
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000)},
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000) },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000) },
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000) },
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000), seen: true },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000), seen: true },
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000), seen: true},
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000), seen: true },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000), seen: true },
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000), seen: true },
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000), seen: true },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000), seen: true },
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000), seen: true },
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000), seen: true },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000), seen: true },
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000), seen: true },
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000), seen: true },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000), seen: true },
              {message: 'Your MOOD is up in the past four days.', trend: 'up', date: new Date(Date.now() - 200000000), seen: true },
              {message: 'Your MOOD is down in the past three days.', trend: 'down', date: new Date(Date.now() - 400000000), seen: true },
              {message: 'Your MOOD is consistent in the past two days.', trend: 'flat', date: new Date(Date.now() - 800000000), seen: true },
          ]
      };
  }


  initialiseVariables() {
      this.entryValues = null;
      // Initialise the statistic variables
      this.noOfEntries = 0; // Number of entries in use
      this.noOfActivitiesInUse = 0; // Number of activities in use
      this.noOfPeopleInUse = 0; // Number of people in use
      this.noOfEntriesMonth = 0; // Number of entries in the last 30 days + today
      this.noOfEntriesWeek = 0; // Number of entries in the last week + today
      this.noEntriesPerActivityCatArray = [0, 0, 0, 0, 0, 0];
     // this.noEntriesPerBaseEmotionArray = [0, 0, 0, 0, 0, 0, 0]; // The number of entries per base emotion (angry, surprised, happy, disgusted, sad, fearful, bad)
      // this.avgIntensityArray = [0, 0, 0, 0, 0, 0, 0]; // The average intensity of each emotion
      this.dateDataArray = []; // An array with the number of entries for each day of the last 30 days + today
      this.dateLabelArray = []; // An array with the string of each date for the last 30 days + today
     // this.emoGranularity = 0; // The average emotional granularity
      this.popularActivities = []; // The top 5 most popular activities
      this.popularPeople = []; // The top 5 most popular people
      this.positiveActivities = []; // The top 5 most popular activities
      this.positivePeople = []; // The top 5 most popular people
      // this.popularEmotions = []; // The top 5 most popular emotions
      this.entriesPerWeekDayArray = [0, 0, 0, 0, 0, 0, 0]; // The number of entries per week day
      this.positiveEntriesPerWeekDayArray = [0, 0, 0, 0, 0, 0, 0];      
      this.avgEntriesPerWeekDayArray = [0, 0, 0, 0, 0, 0, 0]; // The avg number of entries per week day
      this.entriesPerTimeArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // The number of entries per every 3 hours (00:00-03:00, ..., 20:00-23:00, 01:00-04:00, ..., 21:00-00:00, 02:00-05:00, ..., 22:00-01:00)
      this.avgNoteLength = 0; // The average number of characters of entry notes
      this.avgNoActivitiesPerEntry = 0; // The average number of activities per entry
      this.avgNoPeoplePerEntry = 0; // The average number of people per entry
      this.avgNoEmotionsPerEntry = 0; // The average number of emotions per entry
      this.diffHighLowEntriesPerDay = 0; // The difference between the highest number of entries per day and the lowest.
      this.avgNoEntriesPerDay = 0; // Average number of entries per day
      this.activitiesVsEmotionsArray = []; // Correlations between activities and base emotions
      this.activitiesVsBaseEmotionsArray = []; // Correlations between activities and emotions
      this.activitiesInUseArray = []; // The number of activities that are in use
      this.peopleInUseArray = []; // The number of people in use
      this.peopleVsEmotionsArray = []; // Correlations between people and base emotions
      this.peopleVsBaseEmotionsArray = []; // Correlations between people and emotions
      this.smallestDayArray = []; // The days of the week with the smallest number of entries
      this.totalNoOfEmotions = 0;
      this.entriesPerDay = [];
      this.positiveEntriyCountPerPerson = [];
      this.positiveActivityBubbleGraphData = []; 
      this.emaResponseData = [];
  }
  
  getEntries(uid) { 
      //const ema = this.user.isEMA(); //comment out by Seoyong
      this.entryValues = this.entries.query();
      return this.entryValues;

  }
  

  async getNoOfEntries(uid) {
      this.noOfEntries = this.getEntries(uid).length;
      return this.noOfEntries;
  }

  async getNoOfActivitiesInUse(entry: Entry) {
      if (entry.activities != null && entry.activities.length > 0) {
          for (const activity of entry.activities) {
              const act = await this.activities.get(activity.id);
              if (act && act.inuse) {
                  let found = false;
                  for (const activityInUse of this.activitiesInUseArray) {
                      if (activity.id == activityInUse.id) {
                          found = true;
                          break;
                      }
                  }
                  if (!found) {
                      this.activitiesInUseArray.push({ id: activity.id });
                  }
              }
          }
      }
      if (entry == this.entries.query()[this.entries.query().length - 1]) {
          this.noOfActivitiesInUse = this.activitiesInUseArray.length;
      }

  }
  async getNoOfPeopleInUse(entry: Entry) {
      try {
          if (entry.people != null && entry.people.length > 0) {
              for (const person of entry.people) {
                  const p = await this.people.getAsync(person.id);
                  if (p && p.inuse) {
                      let found = false;
                      for (const personInUse of this.peopleInUseArray) {
                          if (person.id == personInUse.id) {
                              found = true;
                              break;
                          }
                      }
                      if (!found) {
                          this.peopleInUseArray.push({id: person.id});
                      }
                  }
              }
          }
          if (entry == this.entries.query()[this.entries.query().length - 1]) {
              this.noOfPeopleInUse = this.peopleInUseArray.length;
          }
      } catch (e) {
          console.log(e);
      }
  }
  /**
   * Check in storage whether the no of activities, entries, people are the same.
   * If not, and a statistic has been unlocked, we need to notify the user of new statistics.
   */
  /* comment out by Seoyong
  checkStorage() {
      this.storage.ready().then(() => {
          this.storage.get('noOfDataStore').then((data) => {
              let statsNotify = 0;
              // If there is nothing saved we create the new 0 value
              if (!data) {
                  data = { noOfEntries: 0, noOfActivitiesInUse: 0, noOfPeopleInUse: 0 };
              }
              // We don't want a notification to appear if someone deletes an entry
              if (data.noOfEntries < this.noOfEntries) {
                  if (this.noOfEntries == 1 || this.noOfEntries == 10 || this.noOfEntries == 15 || this.noOfEntries == 20 || this.noOfEntries == 30) {
                      statsNotify++;
                      console.log('New Stats (noOfEntries): ' + statsNotify);
                  } else if (this.noOfEntries == 5) {
                      statsNotify += 2;
                  }
              }
              if (data.noOfActivitiesInUse < this.noOfActivitiesInUse) {
                  if (this.noOfActivitiesInUse >= 20) {
                      if (data.noOfActivitiesInUse >= 20) { } else if (data.noOfActivitiesInUse >= 10) { statsNotify++; } else if (data.noOfActivitiesInUse >= 5) { statsNotify += 2; } else { statsNotify += 3; }
                  } else if (this.noOfActivitiesInUse >= 10) {
                      if (data.noOfActivitiesInUse >= 10) { } else if (data.noOfActivitiesInUse >= 5) { statsNotify++; } else { statsNotify += 2; }
                  } else if (this.noOfActivitiesInUse >= 5) {
                      if (data.noOfActivitiesInUse >= 5) { } else { statsNotify++; }
                  }
              }
              if (data.noOfPeopleInUse < this.noOfPeopleInUse) {
                  if (this.noOfPeopleInUse >= 20) {
                      if (data.noOfPeopleInUse >= 20) { } else if (data.noOfPeopleInUse >= 10) { statsNotify++; } else if (data.noOfPeopleInUse >= 5) { statsNotify += 2; } else { statsNotify += 3; }
                  } else if (this.noOfPeopleInUse >= 10) {
                      if (data.noOfPeopleInUse >= 10) { } else if (data.noOfPeopleInUse >= 5) { statsNotify++; } else { statsNotify += 2; }
                  } else if (this.noOfPeopleInUse >= 5) {
                      if (data.noOfPeopleInUse >= 5) { } else { statsNotify++; }
                  }
              }

              // Set the storage when it is not equal
              if (data != { noOfEntries: this.noOfEntries, noOfActivitiesInUse: this.noOfActivitiesInUse, noOfPeopleInUse: this.noOfPeopleInUse }) {
                  this.storage.set('noOfDataStore', { noOfEntries: this.noOfEntries, noOfActivitiesInUse: this.noOfActivitiesInUse, noOfPeopleInUse: this.noOfPeopleInUse });
              }
              console.log('New Stats (total): ' + statsNotify);
              if (statsNotify > 0) {
                  this.storage.get('statsNotify').then((notifyData) => {
                      if (data) { statsNotify += notifyData; }
                      this.storage.set('statsNotify', statsNotify);
                      //this.events.publish('refreshNotifications');
                  });
              }
          });
      });

  }
  */

  /**
   * Get the number of entries in the last week and last 30 days.
   */
  getNoOfRecentEntries(entry: Entry) {
      this.dateLabelArray = [];
      const date: any = moment().subtract(30, 'days');

      for (let i = 0; i < 31; i++) {
          this.dateLabelArray.push(date.format('MMM DD'));
          const entrymoment = moment(new Date(entry.date));
          if (this.dateDataArray[i] == null) {
              this.dateDataArray[i] = 0;
          }
          if (entrymoment.isSame(date, 'days')) {
              this.dateDataArray[i]++;

              this.noOfEntriesMonth++;
              if (i >= 25) {
                  this.noOfEntriesWeek++;
              }
          }
          date.add(1, 'day');
      }
  }


  getNoEntriesPerActivityCatArray(entry: Entry) {
  if (entry.activity_category_id != null) {  
    this.noEntriesPerActivityCatArray[entry.activity_category_id.id]++;
    this.entriesPerCat[entry.activity_category_id.name].count++;
    this.totalCatCount++;
  }
  if (entry.activities) {
          entry.activities.forEach((act, i) => {
              this.entriesPerCat[entry.activity_category_id.name].drilldown.push({name: act.name, value: 1});
          });
      }

  }


  /**
  * Gets the 5 most popular activities
  */
  async getPopularActivities() {
      this.popularActivities = this.activities.query();
      for (let i = 0; i < this.popularActivities.length; i++) {
          if (!(this.popularActivities[i].inuse && this.popularActivities[i].visibletimesused > 0)) {
              this.popularActivities.splice(i, 1);
              i--;
          }
      }
      // Sort by timesused
      this.popularActivities.sort(function (a, b) {
          if (a.visibletimesused > b.visibletimesused) {
              return -1;
          }
          if (a.visibletimesused < b.visibletimesused) {
              return 1;
          }
          return 0;
      });
      // Return a capped list at 10
      if (this.popularActivities.length > 10) {
          this.popularActivities.splice(10, this.popularActivities.length - 10);
      }

      return this.popularActivities;
  }

  async getPositiveActivities() {
      const positiveEntries = this.entries.query('positive_mood') as Entry[];
      const activities = this.activities.query() as Activity[];
      const tempList = [];
      const clusterList = [];
      const intensityMap: Map<number, []> = new Map();
 
      positiveEntries.forEach(e => {
      // for (let e in positiveEntries) { 
          if (e.activities && e.activities.length > 0) {
              tempList.push(...e.activities);

              const intensities = [];

              e.responses.forEach(r => {
                  const base = this.controls.get(r.id);
                  
                  // above threshold entries are already filtered in at this point. so no need to test for threshold.
                  //if (base && base.mood_insights) {
                  if (base && this.mood_insights && r.response.intensity) {
                      intensities.push(r.response.intensity);
                  }
              });

              e.activities.forEach(x => {
                  intensityMap[x.id] = intensities;
              });
          }
      }); 

      let popularPositive = [];
      let posActivities = [];

      this.activities.query().forEach(a => { 

      //for(let a in activities) {
          const count = tempList.reduce((n, x) => n + (x.id === a.id), 0);
          if (intensityMap[a.id]) {
              popularPositive.push({count: count, activity: a, intensity_avg: this.calcAvg(intensityMap[a.id])});
          }
      });

      popularPositive.sort(function (a, b) {
          if (a.count > b.count) {
              return -1;
          }
          if (a.count < b.count) {
              return 1;
          }
          return 0;
      });

      this.positiveActivityData = popularPositive;

      posActivities = popularPositive.map(x => {
          return x.activity;
      });

      this.positiveActivities = posActivities;
      return this.positiveActivities;
  }

  async getPositivePeople() {
      const positiveEntries = this.entries.query('positive_mood');
      const tempList = [];
      positiveEntries.forEach(e => {
          tempList.push(...e.people);
      });

      let popularPositive = [];

      this.people.query().forEach(p => {
          const count = tempList.reduce((n, x) => n + (x.id === p.id), 0);
          popularPositive.push({count: count, person: p});
      });

      popularPositive.sort(function (a, b) {
          if (a.count > b.count) {
              return -1;
          }
          if (a.count < b.count) {
              return 1;
          }
          return 0;
      });

      this.positiveEntriyCountPerPerson = popularPositive;
      this.positiveEntriyCountPerPerson.splice(5);

      popularPositive = popularPositive.map(x => {
         return x.person;
      });

      this.positivePeople = popularPositive;
      return this.positivePeople;

  }

  /**
  * Gets the 5 most popular people
  */
  getPopularPeople() {
      this.popularPeople = this.people.query();
      for (let i = 0; i < this.popularPeople.length; i++) {
          if (!this.popularPeople[i].inuse || this.popularPeople[i].visibletimesused == 0) {
              this.popularPeople.splice(i, 1);
              i--;
          }
      }
      // Sort by timesused
      this.popularPeople.sort(function (a, b) {
          if (a.visibletimesused > b.visibletimesused) {
              return -1;
          }
          if (a.visibletimesused < b.visibletimesused) {
              return 1;
          }
          return 0;
      });
      // Return a capped list at 5
      if (this.popularPeople.length > 5) {
          this.popularPeople.splice(5, this.popularPeople.length - 5);
      }
  }

  getEntriesPerWeekDay(entry: Entry) {

      entry.responses.forEach(response => {
          const base = this.controls.get(response.id);
          //if (base && base.mood_insights && base.mood_threshold < response.response.intensity) {
          if (base && this.mood_insights && this.mood_threshold < response.response.intensity) {
              const entrymoment = moment(new Date(entry.date));

              switch (entrymoment.format('dddd')) {
                  case 'Monday':
                      this.positiveEntriesPerWeekDayArray[0]++;
                      break;
                  case 'Tuesday':
                      this.positiveEntriesPerWeekDayArray[1]++;
                      break;
                  case 'Wednesday':
                      this.positiveEntriesPerWeekDayArray[2]++;
                      break;
                  case 'Thursday':
                      this.positiveEntriesPerWeekDayArray[3]++;
                      break;
                  case 'Friday':
                      this.positiveEntriesPerWeekDayArray[4]++;
                      break;
                  case 'Saturday':
                      this.positiveEntriesPerWeekDayArray[5]++;
                      break;
                  case 'Sunday':
                      this.positiveEntriesPerWeekDayArray[6]++;
                      break;
              }
          }
      });
      const entrymoment = moment(new Date(entry.date));

      switch (entrymoment.format('dddd')) {
          case 'Monday':
              this.entriesPerWeekDayArray[0]++;
              break;
          case 'Tuesday':
              this.entriesPerWeekDayArray[1]++;
              break;
          case 'Wednesday':
              this.entriesPerWeekDayArray[2]++;
              break;
          case 'Thursday':
              this.entriesPerWeekDayArray[3]++;
              break;
          case 'Friday':
              this.entriesPerWeekDayArray[4]++;
              break;
          case 'Saturday':
              this.entriesPerWeekDayArray[5]++;
              break;
          case 'Sunday':
              this.entriesPerWeekDayArray[6]++;
              break;
      }

      for (let i = 0; i < 7; i++) {
          this.avgEntriesPerWeekDayArray[i] = (this.entriesPerWeekDayArray[i] / 7);
      }
  }

  getSmallestDays() {
      // Find the days of the week with the smallest number of entries
      let smallestDay = 999999;
      let currentDay: string;
      for (let i = 0; i < this.entriesPerWeekDayArray.length; i++) {
          switch (i) {
              case 0: currentDay = 'Monday'; break;
              case 1: currentDay = 'Tuesday'; break;
              case 2: currentDay = 'Wednesday'; break;
              case 3: currentDay = 'Thursday'; break;
              case 4: currentDay = 'Friday'; break;
              case 5: currentDay = 'Saturday'; break;
              case 6: currentDay = 'Sunday'; break;
          }
          if (this.entriesPerWeekDayArray[i] < smallestDay) {
              smallestDay = this.entriesPerWeekDayArray[i];
              this.smallestDayArray = [currentDay];
          } else if (this.entriesPerWeekDayArray[i] == smallestDay) {
              this.smallestDayArray.push(currentDay);
          }
      }
  }

  /**
   * Gets the average no of entries per day
   */
  getAvgNoEntriesPerDay(entry: Entry) {
      let found: Boolean = false;
      if (this.entriesPerDay != null) {
          for (const element of this.entriesPerDay) {
              const entrymoment = moment(new Date(entry.date));
              if (moment(new Date(entry.date)).format('YYYYMMDD') == (element.date)) {
                  element.times++;
                  found = true;
              }
          }
      }
      if (found == false) {
          this.entriesPerDay.push({ date: moment(new Date(entry.date)).format('YYYYMMDD'), times: 1 });
      }
      if (entry == this.entries.query()[this.entries.query().length - 1]) {
          // Calculate average entries per day
          let totalTimes = 0;
          for (const entry of this.entriesPerDay) {
              totalTimes += entry.times;
          }
          this.avgNoEntriesPerDay = totalTimes / this.entriesPerDay.length;

          if (this.entriesPerDay.length > 1) {
              // Get difference between highest and lowest entries per day
              this.entriesPerDay.sort(function (a, b) {
                  if (a.times > b.times) {
                      return -1;
                  }
                  if (a.times < b.times) {
                      return 1;
                  }
                  return 0;
              });
              this.diffHighLowEntriesPerDay = this.entriesPerDay[this.entriesPerDay.length - 1].times - this.entriesPerDay[0].times;
          }
      }
  }

  getEntriesPerTimeInterval(entry: Entry) {
      const timestring: string = moment(new Date(entry.date)).format('HH').toString();

      switch (timestring) {
          case '00':
              this.entriesPerTimeArray[0]++;
              this.entriesPerTimeArray[15]++;
              this.entriesPerTimeArray[23]++;
              break;
          case '01':
              this.entriesPerTimeArray[0]++;
              this.entriesPerTimeArray[8]++;
              this.entriesPerTimeArray[23]++;
              break;
          case '02':
              this.entriesPerTimeArray[0]++;
              this.entriesPerTimeArray[8]++;
              this.entriesPerTimeArray[16]++;
              break;
          case '03':
              this.entriesPerTimeArray[1]++;
              this.entriesPerTimeArray[8]++;
              this.entriesPerTimeArray[16]++;
              break;
          case '04':
              this.entriesPerTimeArray[1]++;
              this.entriesPerTimeArray[9]++;
              this.entriesPerTimeArray[16]++;
              break;
          case '05':
              this.entriesPerTimeArray[1]++;
              this.entriesPerTimeArray[9]++;
              this.entriesPerTimeArray[17]++;
              break;
          case '06':
              this.entriesPerTimeArray[2]++;
              this.entriesPerTimeArray[9]++;
              this.entriesPerTimeArray[17]++;
              break;
          case '07':
              this.entriesPerTimeArray[2]++;
              this.entriesPerTimeArray[10]++;
              this.entriesPerTimeArray[17]++;
              break;
          case '08':
              this.entriesPerTimeArray[2]++;
              this.entriesPerTimeArray[10]++;
              this.entriesPerTimeArray[18]++;
              break;
          case '09':
              this.entriesPerTimeArray[3]++;
              this.entriesPerTimeArray[10]++;
              this.entriesPerTimeArray[18]++;
              break;
          case '10':
              this.entriesPerTimeArray[3]++;
              this.entriesPerTimeArray[11]++;
              this.entriesPerTimeArray[18]++;
              break;
          case '11':
              this.entriesPerTimeArray[3]++;
              this.entriesPerTimeArray[11]++;
              this.entriesPerTimeArray[19]++;
              break;
          case '12':
              this.entriesPerTimeArray[4]++;
              this.entriesPerTimeArray[11]++;
              this.entriesPerTimeArray[19]++;
              break;
          case '13':
              this.entriesPerTimeArray[4]++;
              this.entriesPerTimeArray[12]++;
              this.entriesPerTimeArray[19]++;
              break;
          case '14':
              this.entriesPerTimeArray[4]++;
              this.entriesPerTimeArray[12]++;
              this.entriesPerTimeArray[20]++;
              break;
          case '15':
              this.entriesPerTimeArray[5]++;
              this.entriesPerTimeArray[12]++;
              this.entriesPerTimeArray[20]++;
              break;
          case '16':
              this.entriesPerTimeArray[5]++;
              this.entriesPerTimeArray[13]++;
              this.entriesPerTimeArray[20]++;
              break;
          case '17':
              this.entriesPerTimeArray[5]++;
              this.entriesPerTimeArray[13]++;
              this.entriesPerTimeArray[21]++;
              break;
          case '18':
              this.entriesPerTimeArray[6]++;
              this.entriesPerTimeArray[13]++;
              this.entriesPerTimeArray[21]++;
              break;
          case '19':
              this.entriesPerTimeArray[6]++;
              this.entriesPerTimeArray[14]++;
              this.entriesPerTimeArray[21]++;
              break;
          case '20':
              this.entriesPerTimeArray[6]++;
              this.entriesPerTimeArray[14]++;
              this.entriesPerTimeArray[22]++;
              break;
          case '21':
              this.entriesPerTimeArray[7]++;
              this.entriesPerTimeArray[14]++;
              this.entriesPerTimeArray[22]++;
              break;
          case '21':
              this.entriesPerTimeArray[7]++;
              this.entriesPerTimeArray[15]++;
              this.entriesPerTimeArray[22]++;
              break;
          case '22':
              this.entriesPerTimeArray[7]++;
              this.entriesPerTimeArray[15]++;
              this.entriesPerTimeArray[23]++;
              break;
      }
  }

  /**
   * Gets the average no of activities, people, emotions, note length per entry
   */
  getAvgNoOfEachPerEntry(entry: Entry) {
      if (entry.activities) {
          for (const activity of entry.activities) {
              this.avgNoActivitiesPerEntry++;
          }
      }
      if (entry.people) {
          for (const person of entry.people) {
              this.avgNoPeoplePerEntry++;
          }
      }
      for (const emotion of entry.responses) {
          this.avgNoEmotionsPerEntry++;
      }
      if (entry.note != null) {
          this.avgNoteLength += entry.note.length;
      }

      if (entry == this.entries.query()[this.entries.query().length - 1]) {
          this.avgNoActivitiesPerEntry /= this.entries.query().length;
          this.avgNoPeoplePerEntry /= this.entries.query().length;
          this.avgNoEmotionsPerEntry /= this.entries.query().length;
          this.avgNoteLength /= this.entries.query().length;
      }
  }

  async getStatsData() {
      this.stats = {}; 
      this.stats.noOfEntries = this.noOfEntries;
      this.stats.noOfEntriesMonth = this.noOfEntriesMonth;
      this.stats.noOfEntriesWeek = this.noOfEntriesWeek;
      this.stats.noEntriesPerActivityCat = this.noEntriesPerActivityCatArray;
      this.stats.entriesPerWeekDay = this.entriesPerWeekDayArray;
      this.stats.dateDataArray = this.dateDataArray;
      this.stats.dateLabelArray = this.dateLabelArray;

      this.stats.entries = this.entries.query().map(entry => entry.responses.map(response => {
              const base = this.controls.get(response.id);
              if (base && base.type === this.RANGE_SLIDER) {  
                  return {
                      intensity: response.response.intensity,
                      name: base.name,
                      color: base.color,
                      date: new Date(entry.date)
                  };
              }
          }));

      this.stats.popularPeople = this.popularPeople;
      this.stats.popularActivities = this.popularActivities;

      // weigh the activities data into categories

      Object.keys(this.entriesPerCat).forEach(key => {
          const counts = {};
          this.entriesPerCat[key].drilldown.forEach(act => {
               counts[act.name] = (counts[act.name] || 0) + 1;
          });

          const temp = [];
          Object.keys(counts).forEach( counted => {
              temp.push({name: counted, value: counts[counted]});
          });

          this.entriesPerCat[key].drilldown = temp;
      });
 
      this.trendsPageStructure = {
          controls: [
              {id: 'spline-graph', type: 26, helpText: 'Test Help text.', title: 'How am I tracking over time?', hidden: (this.noOfEntries < this.boundaries.entriesBoundary2)},
              {
                  type: 17,
                  helpText: 'Test Help text.',
                  title: 'How many entries have I made?'
              },
              {
                  id: 'bar-graph-month',
                  type: 25,
                  xLabels: this.dateLabelArray,
                  yLabel: 'Entries per day of week',
                  helpText: 'Test Help text.',
                  title: 'When did I make entries?',
                  hidden: (this.noOfEntries < this.boundaries.entriesBoundary2),
                  data: [{
                      name: 'Entries Per Day',
                      data: this.dateDataArray
                  }]
              },
              {
                  id: 'bar-graph-week',
                  type: 25,
                  helpText: 'Test Help text.',
                  title: 'Days of the week I\'m making entries?',
                  hidden: (this.noOfEntries < this.boundaries.entriesBoundary3),
                  xLabels: ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
                  yLabel: 'Entries Per Day',
                  data: [
                      {   name: 'Positive entries per day of week',
                          data: this.positiveEntriesPerWeekDayArray
                      },
                      {   name: 'Entries per day of week',
                          data: this.entriesPerWeekDayArray
                      },

                      {
                          type: 'spline',
                          name: 'Average',
                          data: this.avgEntriesPerWeekDayArray,
                          marker: {
                              lineWidth: 2,
                              lineColor: Highcharts.getOptions().colors[3],
                              fillColor: Highcharts.getOptions().colors[3],
                          }
                      }
                  ]
              },
              {
                  id: 'bubble-graph',
                  type: 27,
                  xLabel: 'Average mood score',
                  yLabel: 'Activities',
                  helpText: 'Test help text.',
                  hidden: (this.activitiesInUseArray.length < this.boundaries.entriesBoundary2),
                  title: 'My most positive experiences',
                  data: this.positiveActivityBubbleGraphData
              },
              {
                  id: 'horizontal-bar-graph',
                  type: 28,
                  xLabels: this.positiveEntriyCountPerPerson.map(x => {
                      return x.person.name;
                  }),
                  yLabel: 'Activities',
                  helpText: 'Test help text.',
                  hidden: (this.noOfEntries < this.boundaries.entriesBoundary2),
                  title: 'Positive Experiences with People',
                  data: [
                      {
                          name: 'Positive entry count with person',
                          data: this.positiveEntriyCountPerPerson.map(x => {
                              return x.count;
                          }),
                      },
                      {
                          name: 'All Entry count with person',
                          data: this.positiveEntriyCountPerPerson.map(x => {
                              return x.person.visibletimesused;
                          }),
                      }
                  ]
              },
              {id: 'donut-graph', type: 24, helpText: 'Test Help text.', title: 'What types of activities am I doing?', hidden: (this.noOfEntries < this.boundaries.entriesBoundary3)},
              // {type: 22, helpText: 'Test Help text.', title: 'What activities am I doing most often?', data: this.popularActivities, showUseCount: true },
              // {type: 22, helpText: 'Test Help text.', title: 'What activities create a positive mood?', data: this.positiveActivities, showUseCount: false },
              // {type: 23, helpText: 'Test Help text.', title: 'Who am I spending time with most often?', data: this.popularPeople, showUseCount: true },
              // {type: 23, helpText: 'Test Help text.', title: 'What people created a positive mood?', data: this.positivePeople, showUseCount: false },

          ]
      };

      return this.stats;
  } 

  processEMAResponseData(uid) {
      const map =  this.getEntries(uid)
          .map(entry => {
                const activities = entry.activities ? entry.activities.map( a => {return a.name}) : [];
                const people = entry.people? entry.people.map( p => {return p.name}) : [];
                return entry.responses
                  .map(response => {
                      const base = this.controls.get(response.id);
                      if (base && base.type === this.RANGE_SLIDER) { 
                          return {
                              response,
                              name: base.name,
                              //mood_threshold: base.mood_threshold,
                              //mood_insights: base.mood_insights,
                              mood_threshold: this.mood_threshold,
                              mood_insights: this.mood_insights,
                              color: base.color,
                              date: new Date(entry.date),
                              activities : activities,
                              people: people
                          };  
                      } 
                  });
              // .filter(detail => {
              //     if (this.feedbackFilter === 'week') {
              //         return moment(detail.date).isSameOrAfter(Date.now(), 'week');
              //     }
              //     if (this.feedbackFilter === 'month') {
              //         return moment(detail.date).isSameOrAfter(Date.now(), 'month');
              //     }
              //     return true;
              // })
          })
          .reduce((map, details, index) => {
              details.forEach(detail => {

                  if (detail) {
                      map[detail.name] = map[detail.name] || {
                          name: detail.name,
                          data: [],
                          visible: (detail.mood_insights === true)
                      }; 
                      map[detail.name].data.push({
                          x: detail.date,
                          y: Math.abs(detail.response.response.intensity),
                          a: detail.activities.join(','),
                          p: detail.people.join(','),
                      });

                      if (map[detail.name].data != null) {
                          map[detail.name].data.sort(function (a, b) {
                              return (a.x - b.x);
                          });
                      }
                  }

              });
              return map; 
          }, {});

      this.emaResponseData = Object.keys(map).map(key => map[key]); 
  }

  public async restat(providerRef , uid) {

          // if (this.user.isLoggedIn()) {
            
          console.log('ReloadDetails Stats');         

          const startTime = new Date().getTime();
          if (this.boundaries.statisticsPageShown || this.boundaries.insightsPageShown) {
              this.initialiseVariables();
              
              // Get client's activities 
              await this.activities.init(uid); 
              this.entriesPerCat = await this.activities.getActvityCategories(providerRef); 
              // Get client's people
              await this.people.init(uid);
              // Get client's ema controls
              await this.controls.init(uid);
              // Get client's entries
              await this.entries.init(uid);
              // Get the number of entries
              this.getNoOfEntries(uid); 

              for (const entry of this.entries.query()) {
                  this.getNoOfActivitiesInUse(entry);
                  this.getNoOfPeopleInUse(entry);
              }
              // Check storage to determine if we need to notify the user about new stats.
              //this.checkStorage();

              // Because the number of entries will get quite large, we only iterate through them once to save processing time.
              this.getEntries(uid)
                  .forEach(entry => {
                      this.getNoOfRecentEntries(entry);
                      this.getEntriesPerTimeInterval(entry);
                      this.getSmallestDays();
                      this.getNoEntriesPerActivityCatArray(entry);
                      this.getEntriesPerWeekDay(entry);
                      this.getAvgNoEntriesPerDay(entry);
                      this.getAvgNoOfEachPerEntry(entry);
                  });

              this.processEMAResponseData(uid);

              await this.getPopularActivities();
              await this.getPopularPeople();
              await this.getPositivePeople();
              await this.getPositiveActivities();

               this.processActivityBreakdownData();

               this.processPositiveActivitiesChart();

               this.getStatsData();
          }

      //this.events.publish('statsHaveHappened'); 
  } 

  private async processActivityBreakdownData() {
      const series1 = [];
      const series2 = [];

      const colors: Array<ColorString> = Highcharts.getOptions().colors;
      let tally = 0;
      Object.keys(this.entriesPerCat).forEach((o) => {
          tally = tally + this.entriesPerCat[o].count;
      });

      // get category percent from count and save that as series 1 (inner pie)
      Object.keys(this.entriesPerCat).forEach((o, i) => {
          // series 1
          const s1percent = Math.floor(this.entriesPerCat[o].count / tally * 100);
          const obj = {color: colors[i], name: o, y: s1percent};
          series1.push(obj);

      });

      // for each category get the tallies of the various activities and make a percent of s1.
      Object.keys(this.entriesPerCat).forEach((o, i) => {
          const s1percent = Math.floor(this.entriesPerCat[o].count / tally * 100);

          if (this.entriesPerCat[o].drilldown) {
              let s2tally = 0;

              this.entriesPerCat[o].drilldown.forEach(item => {
                  s2tally = s2tally + item.value;
              });

              this.entriesPerCat[o].drilldown.forEach(item => {
                  const detailColor = new Highcharts.Color(colors[i]).brighten(.1).get();
                  const detailObj = {color: detailColor, name: item.name, y: parseFloat(((item.value / s2tally * 100) * s1percent / 100 ).toFixed(2)) };
                  series2.push(detailObj);
              });
          }
      });

      this.activityPercents = {series1: series1, series2: series2};
      return this.activityPercents;
  }

  private async  processPositiveActivitiesChart() {

      if (this.positiveActivityData.length > 0) {
          const temp = this.positiveActivityData.map(act => {
              return {y: act.activity.id, x: act.intensity_avg, z: act.count, name: act.activity.name};
          });

          this.positiveActivityBubbleGraphData = [{data: temp}];

          return this.positiveActivityBubbleGraphData;
      } else {
          console.log('no positive activity data')
          return [];
      }

  }

  calcAvg(intensities) {
      let sum, avg = 0;
      if (intensities.length) {
          sum = intensities.reduce(function(a, b) { return a + b; });
          avg = sum / intensities.length;
      }
      return avg;
  }
}
