import {Component, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {HttpClientModule, HttpClientJsonpModule, HttpClient, HttpHeaders } from '@angular/common/http';    
import {PageEvent, MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AppComponent} from '../../app.component';
import {MatDialog, MatSlideToggleChange, MatSnackBar} from '@angular/material'; 
import {FormControl} from '@angular/forms';
import {FirebaseService} from '../../services/firebase.service';
import {Observable, Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service'; 
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore, Query} from '@angular/fire/firestore'; 
import {animate, style, transition, trigger} from '@angular/animations';
import {AssessmentService} from '../../services/assessment.service'; 
import {map} from 'rxjs/internal/operators'; 
import {DomainService} from '../../services/domain.service';
import {CognitoMember} from '../../models/cognito_member';
import {NotificationService} from '../../services/notification.service';
import {Notification} from '../../models/notification'; 
import {environment} from '../../../environments/environment';
import {HealthAssessmentDialogComponent} from '../../components/dialog/health-assessment/health-assessment.component';
import {NotificationDialogComponent} from '../../components/dialog/notification/notification.component'; 


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ]),
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity: 1}))
      ])

    ])
  ]
})

export class NotificationsComponent implements OnInit, OnDestroy {  
  
  displayedColumns: string[] = ['client', 'advisor', 'recommendation', 'reason', 'created', 'modified', 'status', 'actions'];
  dataSource: MatTableDataSource<Notification>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator
  @ViewChild(MatSort, {static: true}) sort: MatSort;  

  cognitoUserMap = new Map<string, CognitoMember>(); 
  notifications: Notification [];
  private dialogRef;
  
  private subscription: Subscription; 
  private pendingSubscribe: Subscription;
  private completedSubscribe: Subscription;
  private allSubscribe: Subscription;
    
  newNotifications: number = 0;
  pendingNotifications: number = 0;
  completedNotifications: number = 0;
  allNotifications: number = 0;
 
  public loading: boolean = false; 
  activeNew: boolean = true;
  activePending: boolean = false;
  activeCompleted: boolean = false;
  activeAll: boolean = false;
  currentStateTitle: string;
  public breakpoint: number; 
  searchText: string;
  datepickerEvents: string[] = [];
  clusterDate = new FormControl(new Date());

  constructor(private app: AppComponent,
              private dialog: MatDialog,
              private fs: FirebaseService,
              public auth: AuthService,
              private router: Router,
              private assessmentService: AssessmentService,
              private domainService: DomainService,
              private activatedRoute: ActivatedRoute,
              public appComponent: AppComponent,
              private snackBar: MatSnackBar,
              private db: AngularFirestore,
              public notificationService: NotificationService,
              private http: HttpClient) { }

  ngOnInit() {  

    this.app.setTitle('Cluster Map');
    const now = new Date();
    this.getData();  

    this.appComponent.menuOpened.subscribe(n => {
      this.breakpoint = (window.innerWidth <= 900 || n && window.innerWidth <= 1150) ? 1 : 2;
    });

    this.breakpoint = (window.innerWidth <= 900 || this.appComponent.opened && window.innerWidth <= 1150) ? 1 : 2;
 
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900 || this.appComponent.opened && window.innerWidth <= 1150) ? 1 : 2;
  }
   

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

 
  
  private async getData() { 

    if (this.auth.intuiUser) { 
      /*
      this.subscription = this.fs.getClientsByProvider(this.auth.intuiUser.providerId, this.showArchived).subscribe(clients => {
        this.clients = clients;
        this.loading = false;
      });
      */ 
      //if(this.zones !=null && this.zones.length > 0){
      //  this.loading = false;
      //} else {
        await this.getNotifications(this.auth.intuiUser.providerId, false, 'New'); 
        await this.getPendingNotifications(this.auth.intuiUser.providerId);
        await this.getCompletedNotifications(this.auth.intuiUser.providerId);
        await this.getAllNotifications(this.auth.intuiUser.providerId);
      //} 
    } 
  }

  async onNewClickEvent(event: Event){ 
    await this.getNotifications(this.auth.intuiUser.providerId, false, 'New');
    this.activeNew = true;
    this.activePending = this.activeCompleted = this.activeAll = false;
  }

  async onPendingClickEvent(event: Event){ 
    await this.getNotifications(this.auth.intuiUser.providerId, false, 'Pending');
    this.activePending = true;
    this.activeNew = this.activeCompleted = this.activeAll = false;
  }

  async onCompletedClickEvent(event: Event){ 
    await this.getNotifications(this.auth.intuiUser.providerId, true, 'Completed');
    this.activeCompleted = true;
    this.activePending = this.activeNew = this.activeAll = false;
  }
 
  async onAllClickEvent(event: Event){ 
    await this.getNotifications(this.auth.intuiUser.providerId, null, null);
    this.activeAll = true;
    this.activePending = this.activeCompleted = this.activeNew = false;
  }

  async getPendingNotifications(providerRef) {   
      
    if (this.pendingSubscribe) {
      this.pendingSubscribe.unsubscribe(); 
    }  
    
    let collection =  await this.db.collection(`providers/${providerRef.id}/notifications`, ref => {
      let query : Query = ref; 
      query = query.where('completed', '==', false).where('state', '==', 'Pending'); 
      if(!this.auth.isAdmin() && !this.auth.isProviderAdmin()){
        query = query.where('advisorRef', '==', this.auth.intuiUser.ref) ; 
      }
      query = query.orderBy('modified', 'desc');
      return query;
    });  

    const list = await collection.snapshotChanges().pipe(
      map(clients => {   
        return clients.length; 
      })
    ); 
 
    this.pendingSubscribe = list.subscribe(async (notifications) => {   
      this.pendingNotifications = notifications;      
    } , error => { 
      this.pendingNotifications = 0;     
    });          
  } 

  async getCompletedNotifications(providerRef) {   
      
    if (this.completedSubscribe) {
      this.completedSubscribe.unsubscribe(); 
    }  
    
    let collection =  await this.db.collection(`providers/${providerRef.id}/notifications`, ref => {
      let query : Query = ref; 
      query = query.where('completed', '==', true).where('state', '==', 'Completed'); ;// number; //  New -> PENDING: ACTION TAKEN -> COMPLETED* -> IGNORE*  
      if(!this.auth.isAdmin() && !this.auth.isProviderAdmin()){
        query = query.where('advisorRef', '==', this.auth.intuiUser.ref) ; 
      }
      query = query.orderBy('modified', 'desc');
      return query;
    });  

    const list = await collection.snapshotChanges().pipe(
      map(clients => {   
        return clients.length; 
      })
    ); 
 
    this.completedSubscribe = list.subscribe(async (notifications) => {   
      this.completedNotifications = notifications;      
    } , error => { 
      this.completedNotifications = 0;     
    });          
  } 

  async getAllNotifications(providerRef) {   
      
    if (this.allSubscribe) {
      this.allSubscribe.unsubscribe(); 
    }  
    
    let collection =  await this.db.collection(`providers/${providerRef.id}/notifications`, ref => {
      let query : Query = ref; 
      if(!this.auth.isAdmin() && !this.auth.isProviderAdmin()){
        query = query.where('advisorRef', '==', this.auth.intuiUser.ref) ; 
      }
      query = query.orderBy('modified', 'desc');
      return query;
    });  

    const list = await collection.snapshotChanges().pipe(
      map(clients => {   
        return clients.length; 
      })
    ); 
 
    this.allSubscribe = list.subscribe(async (notifications) => {   
      this.allNotifications = notifications;      
    } , error => { 
      this.allNotifications = 0;     
    });          
  } 


  async getNotifications(providerRef, completed, state) {   
    
    this.loading = true; 
    if (this.subscription) {
      this.subscription.unsubscribe(); 
    } 
    
    let collection =  await this.db.collection(`providers/${providerRef.id}/notifications`, ref => {
        let query : Query = ref; 
        if(completed != null){
          query = query.where('completed', '==', completed);// number; //  New -> PENDING: ACTION TAKEN -> COMPLETED* -> IGNORE*
        }
        if(state){
          query = query.where('state', '==', state) ;
        }
        if(!this.auth.isAdmin() && !this.auth.isProviderAdmin()){
          query = query.where('advisorRef', '==', this.auth.intuiUser.ref) ; 
        }
        query = query.orderBy('modified', 'desc');

        return query;
      });  

      const list = await collection.snapshotChanges().pipe(
        map(clients => {   
          const clientList = clients.map(a => {
            const data:any = a.payload.doc.data();
            data.ref = a.payload.doc.ref; 
            return data as Notification;  
          });
          /*
          if(this.showRisk){  
            return clientList.reduce((result, element) =>{
              if(this.getTrend(element.trends) < 0){
                result.push(element);
              } 
              return result; 
            }, []);  
          } 
          */
          return clientList;
        })
      ); 

      //let firstQuery = true;
      this.subscription = list.subscribe(async (notifications) => {   
        this.notifications = notifications;    
        
        await this.getClients(this.notifications);   
        await this.getAdvisors(this.notifications); 
        this.retreiveData();

        this.loading = false;
      } , error => { 
        this.loading = false; 
        console.log('getNotifications.err===' + error); 
      });         
  } 
   
  retreiveData(){        
    //client name
    this.notifications.map(notification => {
      let row = this.cognitoUserMap.get(notification.clientRef.id) as CognitoMember; 
      if(row){ 
        notification.clientFirstName = row.firstName;
        notification.clientLastName = row.lastName;
        notification.clientEmail = row.email;     
        notification.color = "#C0C0C0";  
        if(notification.state === 'New'){ 
          notification.color = "#f44336";  
        }else if(notification.state === 'Pending'){ 
          notification.color = "#ff9800";  
        }else if(notification.state === 'Completed'){
          notification.color = "#2196F3";  
        }
      }
    });    

    //advisor name
    this.notifications.map(notification => {
      if(notification.advisorRef){
        let row = this.cognitoUserMap.get(notification.advisorRef.id) as CognitoMember; 
        if(row){ 
          notification.advisorName = `${row.firstName} ${row.lastName}`; 
        } 
      }
    });   

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.notifications);  
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;  
  }

  async getClients(list){        
    let uids = list.reduce((result, element) =>{
      if(!element.clientName && element.clientRef.id){
        result.push(element.clientRef.id);
      }  
      return result; 
    }, []);    
    await this.getCogintoUsers(uids); 
  }
  
  async getAdvisors(list){       
    
    let uids = list.reduce((result, element) =>{
      if(!element.clientName && element.advisorRef && element.advisorRef.id){
        result.push(element.advisorRef.id);
      }  
      return result; 
    }, []);    
    await this.getCogintoUsers(uids); 
  }
  
  async getCogintoUsers(uidList){    

    //onlyUnique
    let uids = uidList.filter((v, i, a) => a.indexOf(v) === i); 

    uids = uids.reduce((result, uid) =>{
      if(!this.cognitoUserMap.get(uid)){
        result.push(uid);
      } 
      return result; 
    }, []); 
      
    const CHUNCKED = 20;

    while (uids.length > 0){
      let willRequestUids =  uids.splice(0,  CHUNCKED);
      const url = environment.awsApis.userList + '&uids=' + willRequestUids;   

      await this.http.get(url).toPromise() 
      .then(res => { 
        res['Users'].map(item => {
          const row = new CognitoMember(item); 
          this.cognitoUserMap.set(row.username, row);  
        }); 
          
      }).catch(err=>{  
        console.log('this.getCogintoUsers.err ', err); 
      }); 
    }
  }
  
  ngOnDestroy(): void {
 
    if (this.pendingSubscribe) {
      this.pendingSubscribe.unsubscribe();
    }
    if (this.completedSubscribe) {
      this.completedSubscribe.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    } 
    if (this.allSubscribe) {
      this.allSubscribe.unsubscribe();
    }  
  }
 
  onChangedAction(event, element){   
    switch (event.value) {
      case 'changeState':
      case 'addComment':
      case 'viewComments':
      case 'viewComments': 
      case 'removeOrIgnore':
        this.modifyNotification(element, event.value);
        break;
      case 'newAssessment':
        this.newHealthAssessment(element);
        break; 
      case 'emailAssessment':
        this.sendHealthAssessment(element);
        break;
      default:
        setTimeout(() => { 
          element.newAction = ''; 
        }, 1000);
        break;
    }
      /*
      if(event.value === 'changeState'){
        this.modifyNotification(element, event.value); 
      } else { 
        setTimeout(() => { 
          element.newAction = ''; 
        }, 1000);
      }
      */
  } 

  modifyNotification(element, mode) {

    const data = {...element};  // Spread Syntax
    data.mode = mode;
    this.dialogRef = this.dialog.open(NotificationDialogComponent, {
      height: 'auto',
      width: '80vw',
      data: data
    });

    this.dialogRef.afterClosed().subscribe((result) => { 
      if(result){
        this.snackBar.open('Updated successfully!', 'Ok');   
      } 
      element.newAction = ''; 
    }); 
  }
 
  isValidEmail(email){ 
    if(email == null || email === ''){
      return false;
    }else if(email.startsWith('intui-care-client') && email.endsWith('goact.com.au')){
      return false;
    }
    return true;
  }

  registered(created) {
    return moment(created.toDate()).format('ddd DD MMM YYYY');
  }
  
  timeOfDay(created) {
    return moment(created.toDate()).format('DD/MM HH:mm');
  }

  
  newHealthAssessment(element) {  
    if (element.clientRef && element.assessmentRef) {
      this.router.navigateByUrl('/client/' + element.clientRef.id + '/assessment/' + element.assessmentRef.id);
    }
    element.newAction = '';   
  }

  
  async sendHealthAssessment(element) {
    
    console.log('element.assessmentRef', element.assessmentRef);

    const temp = await element.assessmentRef.get();
    const assessmentToSend = temp.data();
    assessmentToSend.ref = temp.ref;  

    const clientObj :any = {};
    clientObj.firstName = element.clientFirstName;
    clientObj.lastName = element.clientLastName;
    clientObj.email = element.clientEmail; 
    clientObj.ref = element.clientRef;
    clientObj.uid = element.clientRef.id;

    this.dialogRef = this.dialog.open(HealthAssessmentDialogComponent, {
      height: '40%',
      width: '45vw', 
      data: {title: 'Send Health Assessment', client: clientObj, invite: true, assessmentToSend: assessmentToSend}
    });

    this.dialogRef.afterClosed().subscribe( async (result) => {
      console.log('afterClosed', result);
      if (!result) {
        //DO NOTHING when user click 'OK' button 
      }else if (result.invited) { 
        this.snackBar.open('Sent successfully!', 'Ok');  
      } else if (!result.invited) { 
        this.snackBar.open('The system  encountered an unexpected error. Please try again later.', 'Ok');
      } 
      element.newAction = '';  
    }); 
  }

}