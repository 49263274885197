import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms'; 
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Navigation, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import { MustMatch } from '../../helpers/must-match.validator'; 
import {MatSnackBar} from '@angular/material';
import get = Reflect.get;
import {User} from '../../models/user';

@Component({
  selector: 'app-account-forgotten',
  templateUrl: './account-forgotten.component.html',
  styleUrls: ['./account-forgotten.component.scss']
})
 
export class AccountForgottenComponent implements OnInit {
 
  loginEmail = new FormControl('', [Validators.required]);
  loginPassword = new FormControl('', [Validators.required]);

  //email = new FormControl('', [Validators.required, Validators.email]);
  code = new FormControl('', [Validators.required]); 
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  password2 = new FormControl('', [Validators.required]);

  login: FormGroup = new FormGroup({
    loginEmail: this.loginEmail,
    loginPassword: this.loginPassword
  });

  newPasswordForm: FormGroup = new FormGroup({  
    //email: this.email,
    code: this.code,
    password: this.password,
    password2: this.password2
  }
  , [MustMatch(this.password, this.password2)]
  );
 
  codeSent = false; 
  public loading: boolean =false; 

  constructor(public auth: AuthService,
              private router: Router,
              private fba: AngularFireAuth,
              private snackBar: MatSnackBar, 
              private activatedRoute: ActivatedRoute) { 
  }

  ngOnInit() { 
  }
   
  getLoginEmailErrorMessage() {
    return this.loginEmail.hasError('required') ? 'You must enter a value' :
      '';
  } 

  getCodeErrorMessage() {
    return this.code.hasError('required') ? 'You must enter a value' :  '';
  } 

  getEmailErrorMessage() {
    //return this.email.hasError('required') ? 'You must enter a value' : '';
  }

  getPasswordErrorMessage() {
    let messages = '';
    messages += this.password.hasError('required') ? 'You must enter a value' : '';
    messages += this.password.hasError('minlength') ? 'password must be at least 6 characters' : '';
    return messages;
  }

  getPassword2ErrorMessage() {
    let messages = '';
    messages += this.password2.hasError('required') ? 'You must enter a value' : '';
    messages += this.password2.hasError('mustMatch') ? 'password must match' : '';
    return messages;
  } 

  toLogin(){ 
    this.router.navigateByUrl('/login');
  }

  /**
   * Request a new password (temporary)
   * Return the user to the account pages
   */
  async newPassword() {

      this.loading = true; 
      this.auth.forgotPassword(this.login.value.loginEmail).then((res: any) => {
        console.log('res', res);

        if (res) {
            this.codeSent = true;
        }
        this.loading = false; 
      }).catch(err => { 
        this.loading = false;  
        this.snackBar.open(err.message, 'Ok');  
      });  
    }
    
    async submitPackage() {

      this.loading = true; 
 
      this.auth.validateCode(this.loginEmail.value, this.newPasswordForm.value.code, this.newPasswordForm.value.password).then(async (res: any) => {
        this.router.navigateByUrl('login'); 
        this.snackBar.open('Password reset successfully', 'Ok');  
        this.loading = false; 
      }).catch(async err => {
        this.loading = false; 
        this.snackBar.open(err.message, 'Ok');  
    }); 
  }
}
