import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Navigation, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import { MustMatch } from '../../helpers/must-match.validator';
import {utils} from 'protractor';
import {MatSnackBar} from '@angular/material';
import get = Reflect.get;
import {User} from '../../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
 
export class LoginComponent implements OnInit {

  visible = 'visibility_off';
  showPass = 'password';

  loginEmail = new FormControl('', [Validators.required]);
  loginPassword = new FormControl('', [Validators.required]);

  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  password2 = new FormControl('', [Validators.required]);

  login: FormGroup = new FormGroup({
    loginEmail: this.loginEmail,
    loginPassword: this.loginPassword
  });

  register: FormGroup = new FormGroup({
    firstName: this.firstName,
    lastName: this.lastName,
    email: this.email,
    password: this.password,
    password2: this.password2
  }
  , [MustMatch(this.password, this.password2)]
  );

  public tenant: any;
  isRegister = false;
  private user: any;
  submitted = false;
  private isCodeRegister: boolean;
  private data: Navigation | null;

  constructor(public auth: AuthService,
              private router: Router,
              private fba: AngularFireAuth,
              private snackBar: MatSnackBar,
              private activatedRoute: ActivatedRoute) {
    this.data = this.router.getCurrentNavigation();
  }

  ngOnInit() {

    this.isCodeRegister = (this.router.url.indexOf('code_register') !== -1);
    if (this.isCodeRegister) {
      this.getCodeData();
    }
    this.isRegister = this.isCodeRegister;

    //this.user = this.fba.auth.currentUser;
    //if (this.user) {
    //  this.router.navigateByUrl('home');
    //} 
  }

  private async getCodeData() {
    console.log(this.data.extras);
    // api call to do this..?
    this.email.setValue(this.data.extras.queryParams.data.email);
    this.email.disable();
  }

  togglePass() {
    if (this.showPass == 'password') {
      this.showPass = 'text';
      this.visible = 'visibility';
    } else {
      this.visible = 'visibility_off';
      this.showPass = 'password';
    }
  }

  getLoginEmailErrorMessage() {
    return this.loginEmail.hasError('required') ? 'You must enter a value' :
      '';
  }

  getLoginPasswordErrorMessage() {
    return this.loginPassword.hasError('required') ? 'You must enter a value' :
      '';
  }

  getFirstNameErrorMessage() {
    return this.firstName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getLastNameErrorMessage() {
    return this.lastName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      '';
  }

  getPasswordErrorMessage() {
    let messages = '';
    messages += this.password.hasError('required') ? 'You must enter a value' : '';
    messages += this.password.hasError('minlength') ? 'password must be at least 6 characters' : '';
    return messages;
  }

  getPassword2ErrorMessage() {
    let messages = '';
    messages += this.password2.hasError('required') ? 'You must enter a value' : '';
    messages += this.password2.hasError('mustMatch') ? 'password must match' : '';
    return messages;
  } 

  toForgotten(){ 
    this.router.navigateByUrl('/account-forgotten');
  }

  submit() { 
    if (this.login.valid) {
      // TODO Hit firebase and auth..
      this.auth.loginAccount(this.login.value.loginEmail, this.login.value.loginPassword).then(res => {
        //this.router.navigateByUrl('clients');
      }, (error) => {
        this.snackBar.open(error.message, 'Ok'); 
      }) ;
    } else {
      this.login.markAllAsTouched(); 
    }
  }

  registerSocial(type) {
    this.auth.loginGoogle();
  }

  doRegister() {
    this.submitted = true;
    if (this.register.valid) {
      const user = {email: this.email.value, pass: this.password.value, firstName: this.firstName.value, lastName: this.lastName.value};
      this.auth.register(user).then(res => {
        console.log(res);
      }, error => {
        this.snackBar.open(error.message, 'Ok');
      });
    } else {
      this.register.markAllAsTouched();
      this.submitted = false;
    }
  }

  googleAuth() {
    this.auth.loginGoogle();
  }
}
