import {Component, Input, OnInit} from '@angular/core';
import {StatisticsService} from '../../../services/statistics.service';
import {BoundariesService} from '../../../services/boundaries.service';

@Component({
  selector: 'app-entry-count',
  templateUrl: './entry-count.component.html',
  styleUrls: ['./entry-count.component.scss']
})
export class EntryCountComponent implements OnInit {

  public breakpoint: number;

  constructor(public stats: StatisticsService, public boundaries: BoundariesService) { }

  @Input()
  title: any;

  @Input()
  data: any;

  @Input()
  helpText: string;

  ngOnInit() {
    this.breakpoint =  3;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 900 || window.innerWidth <= 1150) ? 1 : 3;
  }


    /**
     * When the user selects the help button, they receive information that explains the graph or data.
     * @param helpCode The number indicates which statistic has been selected.
     */
    async presentAlert() {
        const title = this.title;
        const subTitle = this.helpText;
/*
        const alert = await this.alertCtrl.create({
            header: title,
            message: subTitle,
            buttons: ['OK']
        });
        alert.present();
*/
    }

}
