import { Injectable } from '@angular/core';
import {Assessment} from '../models/assessment';
import {Domain} from '../models/domain';
import {Client} from '../models/client';
import {AssessmentService} from './assessment.service';
import {DomainService} from './domain.service';

@Injectable({
  providedIn: 'root'
})

// BM
// For MVP All scoring is hard coded and matched to questionaire json stored in DB..
// Not the best way but needed for now.

export class ScoringService {

  constructor(private assessmentService: AssessmentService, private domainService: DomainService) {
  }

  calculate(assessment) {

    if (this.domainService.domains.length > 0) {

      const domain = this.domainService.domains.find(x => x.ref.path === assessment.domain.path);

      switch (domain.name) {

        case 'Nutrition':
          return this.calcNutrition(assessment);
          break;

        case 'Mental Health':
          return this.calcMentalHealth(assessment);
          break;

        case 'Sleep':
          return this.calcSleep(assessment);
          break;

        case 'Quality of Life':
          return this.calcQualityOfLife(assessment);
          break;

        case 'Mobility':
          return this.calcMobility(assessment);
          break;

        case 'Balance':
          return this.calcBalance(assessment);
          break;

        case 'Falls':
          return this.calcFalls(assessment);
          break;

        case 'Diabetes':
          return this.calcDiabetes(assessment);
          break;

        case 'Social Engagement':
          return this.calcSocialEngagement(assessment);
          break;

        case 'Pelvic Floor':
          return this.calcPelvicFloor(assessment);
          break;

        case 'Hearing':
          return this.calcHearing(assessment);
          break;

        default:
          return null;
          break;
      }
    } else {
      return null;
    }
  }

  private calcNutrition(assessment) {
    if (assessment.responses) {
      return this.sumScore(assessment);
    } else {
      return 0;
    }
  }

  private calcMentalHealth(assessment: any) {
    if (assessment.responses) {
      return this.sumScore(assessment);
    } else {
      return 0;
    }
  }


  private calcSleep(assessment: any) {

    function calcRange(tot) {
      if (tot === 0) {
        return 0;
      }
      if (tot === 1 || tot === 2) {
        return 1;
      }
      if (tot === 3 || tot === 4) {
        return 2;
      }
      if (tot === 5 || tot === 6) {
        return 3;
      }
    }

    if (assessment.responses) {

      let c1Total = 0;
      let c2Total = 0;
      let c3Total = 0;
      let c4Total = 0;
      let c5Total = 0;
      let c6Total = 0;
      let c7Total = 0;
      let c8Total = 0;

      for (const x of assessment.responses) {

        if (x.response[0]) {  // C1
          if (x.id === '2-3') {
            c1Total = x.response[0];
          } else if (x.id === '0-1') { // C2
            c2Total = c2Total + x.response[0];
          } else if (x.id === '1-0') { // C2 - above and this one combined,
            c2Total = c2Total + x.response[0];
          } else if (x.id === '0-3') { // C3
            c3Total = x.response[0];
          } else if (x.id === '0-4') { // C8
            c4Total = x.response[0];
          } else if (x.id === '1-1' || x.id === '1-2' || x.id === '1-3' || x.id === '1-4'
            || x.id === '1-5' || x.id === '1-6' || x.id === '1-7' || x.id === '1-8' || x.id === '1-9') { // C5
            c5Total = x.response[0];
          } else if (x.id === '2-0') { // C6
            c6Total = x.response[0];
          } else if (x.id === '2-1') { // C7
            c7Total = x.response[0];
          } else if (x.id === '2-2') { // C8
            c8Total = x.response[0];
          }
        }
      }

      const c2 = calcRange(c2Total);
      const c7 = calcRange(c7Total + c8Total);
      const c4 = (c4Total !== 0 && c3Total !== 0) ? (c3Total / c4Total) * 100 : 0;
      const total = c1Total + c2 + c3Total + c4 + c5Total + c6Total + c7;
      return total;
    } else {
      return 0;
    }
  }

  private calcQualityOfLife(assessment: any) {

    if (assessment.responses) {

      //using the original binary logic.. not pretty but, definately made it simpler to debug!!

      let PF02_1 = 0;
      let PF02_2 = 0;
      let PF04_1 = 0;
      let PF04_2 = 0;
      let RP2_1 = 0;
      let PR3_1 = 0;
      let BP2_1 = 0;
      let BP2_2 = 0;
      let BP2_3 = 0;
      let BP2_4 = 0;
      let GH1_1 = 0;
      let GH1_2 = 0;
      let GH1_3 = 0;
      let GH1_4 = 0;
      let VT2_1 = 0;
      let VT2_2 = 0;
      let VT2_3 = 0;
      let VT2_4 = 0;
      let VT2_5 = 0;
      let SF2_1 = 0;
      let SF2_2 = 0;
      let SF2_3 = 0;
      let SF2_4 = 0;
      let RE2_1 = 0;
      let RE3_1 = 0;
      let MH3_1 = 0;
      let MH3_2 = 0;
      let MH3_3 = 0;
      let MH3_4 = 0;
      let MH3_5 = 0;
      let MH4_1 = 0;
      let MH4_2 = 0;
      let MH4_3 = 0;
      let MH4_4 = 0;
      let MH4_5 = 0;

      assessment.responses.forEach((x: any, i) => {
        if (x.response && x.response[0] > 0) {
          switch (x.id) {
            case '0-0': // GH1
              if (x.response[0] === 1) {
                GH1_4 = 1;
              }
              if (x.response[0] === 2) {
                GH1_3 = 1;
              }
              if (x.response[0] === 3) {
                GH1_2 = 1;
              }
              if (x.response[0] === 4) {
                GH1_1 = 1;
              }
              break;
            case '1-0': // PF02
              if (x.response[0] === 1) {
                PF02_1 = 1;
              }
              if (x.response[0] === 2) {
                PF02_2 = 1;
              }
              break;
            case '1-1': // PF04
              if (x.response[0] === 1) {
                PF04_1 = 1;
              }
              if (x.response[0] === 2) {
                PF04_2 = 1;
              }
              break;
            case '2-0': // RE2
              if (x.response[0] === 1) {
                RP2_1 = 1;
              }
              break;
            case '2-1': // PR3
              if (x.response[0] === 1) {
                PR3_1 = 1;
              }
              break;
            case '3-0': // RP2
              if (x.response[0] === 1) {
                RE2_1 = 1;
              }
              break;
            case '3-1': // RE3
              if (x.response[0] === 1) {
                RE3_1 = 1;
              }
              break;
            case '3-2': // BP2
              if (x.response[0] === 1) {
                BP2_4 = 1;
              }
              if (x.response[0] === 2) {
                BP2_3 = 1;
              }
              if (x.response[0] === 3) {
                BP2_2 = 1;
              }
              if (x.response[0] === 4) {
                BP2_1 = 1;
              }
              break;
            case '4-0': // MH3
              if (x.response[0] === 1) {
                MH3_5 = 1;
              }
              if (x.response[0] === 2) {
                MH3_4 = 1;
              }
              if (x.response[0] === 3) {
                MH3_3 = 1;
              }
              if (x.response[0] === 4) {
                MH3_2 = 1;
              }
              if (x.response[0] === 5) {
                MH3_1 = 1;
              }
              break;
            case '4-1': // VT2
              if (x.response[0] === 1) {
                VT2_5 = 1;
              }
              if (x.response[0] === 2) {
                VT2_4 = 1;
              }
              if (x.response[0] === 3) {
                VT2_3 = 1;
              }
              if (x.response[0] === 4) {
                VT2_2 = 1;
              }
              if (x.response[0] === 5) {
                VT2_1 = 1;
              }
              break;
            case '4-2': // MH4
              if (x.response[0] === 1) {
                MH4_1 = 1;
              }
              if (x.response[0] === 2) {
                MH4_2 = 1;
              }
              if (x.response[0] === 3) {
                MH4_3 = 1;
              }
              if (x.response[0] === 4) {
                MH4_4 = 1;
              }
              if (x.response[0] === 5) {
                MH4_5 = 1;
              }
              break;
            case '4-3': // SF2
              if (x.response[0] === 1) {
                SF2_1 = 1;
              }
              if (x.response[0] === 2) {
                SF2_2 = 1;
              }
              if (x.response[0] === 3) {
                SF2_3 = 1;
              }
              if (x.response[0] === 4) {
                SF2_4 = 1;
              }
              break;
          }

        }
      });

      // weightings
      let PCS12 = (-7.23216 * PF02_1) + (-3.45555 * PF02_2) +
        (-6.24397 * PF04_1) + (-2.73557 * PF04_2) + (-4.61617 * RP2_1) +
        (-5.51747 * PR3_1) + (-11.25544 * BP2_1) + (-8.38063 * BP2_2) +
        (-6.50522 * BP2_3) + (-3.80130 * BP2_4) + (-8.37399 * GH1_1) +
        (-5.56461 * GH1_2) + (-3.02396 * GH1_3) + (-1.31872 * GH1_4) +
        (-2.44706 * VT2_1) + (-2.02168 * VT2_2) + (-1.6185 * VT2_3) +
        (-1.14387 * VT2_4) + (-0.42251 * VT2_5) + (-0.33682 * SF2_1) +
        (-0.94342 * SF2_2) + (-0.18043 * SF2_3) + (0.11038 * SF2_4) +
        (3.04365 * RE2_1) + (2.32091 * RE3_1) + (3.46638 * MH3_1) +
        (2.90426 * MH3_2) + (2.37241 * MH3_3) + (1.36689 * MH3_4) +
        (0.66514 * MH3_5) + (4.61446 * MH4_1) + (3.41593 * MH4_2) +
        (2.34247 * MH4_3) + (1.28044 * MH4_4) + (0.41188 * MH4_5);

      let MCS12 = (3.93115 * PF02_1) + (1.8684 * PF02_2) +
        (2.68282 * PF04_1) + (1.43103 * PF04_2) + (1.44060 * RP2_1) +
        (1.66968 * PR3_1) + (1.48619 * BP2_1) + (1.76691 * BP2_2) +
        (1.49384 * BP2_3) + (0.90384 * BP2_4) + (-1.71175 * GH1_1) +
        (-0.16891 * GH1_2) + (0.03482 * GH1_3) + (-0.06064 * GH1_4) +
        (-6.02409 * VT2_1) + (-4.88962 * VT2_2) + (-3.29805 * VT2_3) +
        (-1.65178 * VT2_4) + (-0.92057 * VT2_5) + (-6.29724 * SF2_1) +
        (-8.26066 * SF2_2) + (-5.63286 * SF2_3) + (-3.13896 * SF2_4) +
        (-6.82672 * RE2_1) + (-5.69921 * RE3_1) + (-10.19085 * MH3_1) +
        (-7.92717 * MH3_2) + (-6.31121 * MH3_3) + (-4.09842 * MH3_4) +
        (-1.94949 * MH3_5) + (-16.15395 * MH4_1) + (-10.77911 * MH4_2) +
        (-8.09914 * MH4_3) + (-4.59055 * MH4_4) + (-1.95934 * MH4_5);

      //add constants.

      PCS12 = PCS12 + 56.57706;
      MCS12 = MCS12 + 60.75781;

      //return `PCS-12: <b>${PCS12.toFixed(5)}</b> MCS-12: <b>${MCS12.toFixed(5)}</b>`;

      return [Number(PCS12.toFixed(5)), Number(MCS12.toFixed(5))];
    } else {
      return [0, 0];
    }
  }

  private calcMobility(assessment: any) {
    if (assessment.responses) {
      let total = 0;
      assessment.responses.forEach((x: any, i) => {
        if (x.response && x.response[0] > 0) {
          total = total + x.response[0];
        }
      });
      return total;
    } else {
      return 0;
    }
  }

  private calcFalls(assessment: any) {
    if (assessment.responses) {
      let total = 0;
      assessment.responses.forEach((x: any, i) => {
        if (x.response && x.response[0] > 0) {
          total = total + x.response[0];
        }
      });
      return total;
    } else {
      return 0;
    }
  }

  private calcBalance(assessment: any) {
    if (assessment.responses) {
      let total = 0;
      assessment.responses.forEach((x: any, i) => {
        if (x.response && x.response[0] > 0) {
          total = total + x.response[0];
        }
      });
      return (total * 10) / 16;
    } else {
      return 0;
    }
  }

  private calcDiabetes(assessment: any) {

    if (assessment.responses) {


    let total = 0;
    let isAltOrigin = false;
    let isNativeOrigin = false;
    let isMale = false;
    for (const x of assessment.responses) {
      switch (x.id) {
        case '0-0': //age group
          if (x.response[0] === 1) {
            total = total + 2;
          }
          if (x.response[0] === 2) {
            total = total + 4;
          }
          if (x.response[0] === 3) {
            total = total + 6;
          }
          if (x.response[0] === 4) {
            total = total + 8;
          }
          break;
        case '0-1': // gender
          if (x.response[0] === 1) {
            total = total + 3;
            isMale = true;
          }
          break;
        case '0-2': // aboriginal torres strait islander
          if (x.response[0] === 1) {
            isNativeOrigin = true;
            total = total + 2;
          }
          break;
        case '0-3': // Birth location
          if (x.response[0] === 1) {
            isAltOrigin = true;
            total = total + 2;
          }
          break;
        case '1-0': // relatives
          if (x.response[0] === 1) {
            total = total + 3;
          }
          break;
        case '1-1': // blood pressure
          if (x.response[0] === 1) {
            total = total + 6;
          }
          break;
        case '1-2': // meds
          if (x.response[0] === 1) {
            total = total + 2;
          }
          break;
        case '1-3': // smoking
          if (x.response[0] === 1) {
            total = total + 2;
          }
          break;
        case '2-0': // fruit and veg
          if (x.response[0] === 1) {
            total = total + 1;
          }
          break;
        case '2-1': //
          if (x.response[0] === 1) {
            total = total + 2;
          }
          break;
        case '2-2':

          if (isAltOrigin || isNativeOrigin) {
            if (isMale) {
              if (x.response > 90 && x.response < 100) {
                total = total + 4;
              }
              if ( x.response < 100) {
                total = total + 7;
              }
            } else {
              if (x.response > 80 && x.response < 90) {
                total = total + 4;
              }
              if ( x.response < 90) {
                total = total + 7;
              }
            }
          } else {
            if (isMale) {
              if (x.response > 102 && x.response < 110) {
                total = total + 4;
              }
              if ( x.response < 110) {
                total = total + 7;
              }
            } else {
              if (x.response > 88 && x.response < 100) {
                total = total + 4;
              }
              if ( x.response < 100) {
                total = total + 7;
              }
            }
          }
          break;
      }
    }
    return total;
    } else {
      return 0;
    }

  }

  private calcSocialEngagement(assessment: any) {

    if (assessment.responses) {

      let total = 0;
      assessment.responses.forEach((x: any, i) => {
        if (x.response && x.response[0] > 0) {
          total++;
        }
      });
      return total;
    } else {
      return 0;
    }
  }

  private calcPelvicFloor(assessment: any) {
    if (assessment.responses) {
      let total = 0;
      assessment.responses.forEach((x: any) => {
        if (x.response2) {
          total = x.response2[0] + total;
        }
      });
      return total;
    } else {
      return 0;
    }
  }

  private calcHearing(assessment: any) {
    if (assessment.responses) {
      let total = 0;
      assessment.responses.forEach((x: any, i) => {
        if (x.response && x.response[0] > 0) {
          total++;
        }
      });
      return total;
    } else {
      return 0;
    }
  }

  private sumScore(assessment: any) {
    let total = 0;
    assessment.responses.forEach((x: any) => {
      total = x.response[0] + total;
    });
    return total;
  }

  getScoreDescription(domainRef) {
    //TODO implement a look up with some stored Thresholds.
    //return 'Not implemented';
    return '';
  }
}
