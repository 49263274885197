// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyBaKCFhhou-GLSndBu0sgLL7mPAvnunJCg",
    authDomain: "intui-care-prod.firebaseapp.com",
    databaseURL: "https://intui-care-prod.firebaseio.com",
    projectId: "intui-care-prod",
    storageBucket: "intui-care-prod.appspot.com",
    messagingSenderId: "659174056363",
    appId: "1:659174056363:web:ce2658a669910a5b5cb2ee",
    measurementId: "G-WE8F51V1V2"
  },
  awsConfig: {
    IdpEndpoint: 'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_ZNviz7k9T',
    ClientId: '75ja9ov684uq993jvs8fe4er2k',
    UserPoolId: 'ap-southeast-2_ZNviz7k9T',
  },
  amplifyConfig: {
      userPoolWebClientId: '75ja9ov684uq993jvs8fe4er2k',
      region: 'ap-southeast-2',
      userPoolId: 'ap-southeast-2_ZNviz7k9T',
      identityPoolId : 'ap-southeast-2:f2728eaf-773e-4403-8219-95791aa56e43'
  },
  awsApis: {
    aws_auth_validator :'https://uuzq9kytde.execute-api.ap-southeast-2.amazonaws.com/prod/validate',
    single_provider :'/providers/RW3DXGqTQ1YJo0ernNYn',
    userList: 'https://1z4fgm629j.execute-api.ap-southeast-2.amazonaws.com/v1/user/list?ap=inCareAfE2ZfnEfsE3j12CsicZpFa'
  },
  assessment: {
    standalone_url :'https://intuicare-app.goact.com.au'
  },
  highchart: {
    export_url :'https://us-central1-intui-care-prod.cloudfunctions.net/get_highchart_image'
  }

  // firebaseConfig: {
  //   apiKey: 'AIzaSyC3ypCcb8SqBTjOWCF4sh2tGK2xeBwKtpg',
  //   authDomain: 'goact-intui-care-dev.firebaseapp.com',
  //   databaseURL: 'https://goact-intui-care-dev.firebaseio.com',
  //   projectId: 'goact-intui-care-dev',
  //   storageBucket: 'goact-intui-care-dev.appspot.com',
  //   messagingSenderId: '16635876793',
  //   appId: '1:16635876793:web:b5e6c4d12fca4d44151268'
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
