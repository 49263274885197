import {Injectable, OnDestroy} from '@angular/core'; 
import { Entry } from '../models/entry';
import { ControlsService } from './controls.service';
import { ActivitiesService } from './activities.service';
import { ActivityCategoriesService } from './activity_categories.service';
import {AngularFirestore, Query, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';
import { PeopleService } from './people.service';
import * as moment from 'moment';
//import {User} from '../models/user'; 
import {FirebaseService} from './firebase.service'; 

import {map} from 'rxjs/internal/operators';
import {Observable, Subscription} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EntriesService implements OnDestroy {
     
  ngOnDestroy(): void {
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
  }

  private entries: Entry[] = [];
  private bounceHome: boolean;
  private subscription: Subscription;


  constructor(private controls: ControlsService,
              private activity_category: ActivityCategoriesService,
              private activities: ActivitiesService,
              private people: PeopleService, 
              private firestore: FirebaseService,
              //private user: User,
              private db: AngularFirestore) {
  }
/*
   init(uid) { 
      this.subscription = this.firestore.watchCollection(`clients/${uid}/entries`).subscribe(entries => {
        if (entries.length > 0) {
            this.entries = entries; 
        } else { 
            this.entries = [{
                id: 0,
                recorded: null,
                updated: null,
                date: null,
                responses: null,
                emotions: null,
                activity_category_id: null,
                activities: null,
                people: null,
                note: null,
                inuse: true,
                geolocation: null,
                questions: null,
                version: null,
                alias: null
            }];
            this.firestore.add('entries', this.entries[0]); 
        }
    });
  }
*/

  async init(uid) { 
                               
    this.entries = [];
               
    //let collection = await this.db.collection(`clients/${uid}/entries`, ref=>ref) ; 
    let collection = await this.db.collection(`clients/${uid}/entries`, ref => ref.where('deleted', '==', false)) ;
    
    await collection.get().toPromise()
    .then(entries => {
        if(entries.docs && entries.docs.length > 0){   
            entries.docs.map(doc => {
                const data: any = doc.data();
                data.ref =  doc.ref;
                this.entries.push(data as Entry); 
            });
        } 
    }).catch(err=>{  
        console.log('EntriesService.err ===' + err); 
    }); 
  } 
  

  /**
   * When !params, returns a list of all entries.
   * When params, returns any entries that have an emotion, activity or person with a substring equal to the parameter value.
   * @param params
   */
  query(params?: any) {

      if (!params) {
          return this.entries.filter((entry) => {
              //if (entry.id > 0 && entry.inuse) { return entry; } 
              if (entry.responses && entry.responses.length > 0 ) { return entry; }
          });
      } else {
          return this.entries.filter((entry) => {
            // Comment out by Seoyong, id is not exist in entries docs.
            //if (entry.id > 0) {
                  for (const key in params) {

                      // Search for emotions
                      if (entry.responses != null) {
                          for (const emotion of entry.responses) {
                              for (const emotion2 of this.controls.query(true)) {
                                  if (emotion.id == emotion2.id) {
                                  //    if (emotion2.name.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }

                                      // if (emotion2.parent1name != null) {
                                      //     if (emotion2.parent1name.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }
                                      // }
                                      // if (emotion2.parent2name != null) {
                                      //     if (emotion2.parent2name.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }
                                      // }
                                  }

                              }
                          }
                      }
                      // Search for activity_category
                      if (entry.activity_category_id != null) {
                              for (const activity_category2 of this.activity_category.query()) {
                                  if ((entry.activity_category_id.id == activity_category2.id) && (activity_category2.inuse)) {

                                      if (activity_category2.name.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }
                                  }
                              }

                      }
          // Search for activities
                      if (entry.activities != null) {
                          for (const activity of entry.activities) {
                              for (const activity2 of this.activities.query()) {
                                  if ((activity.id == activity2.id) && (activity2.inuse)) {

                                      if (activity2.name.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }
                                  }
                              }
                          }
                      }
                      // Search for people
                      if (entry.people != null) {
                          for (const person of entry.people) {
                              for (const person2 of this.people.query()) {
                                  if ((person.id == person2.id) && (person2.inuse)) {
                                      if (person2.name.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }
                                  }
                              }
                          }
                      }
                      // Search for note
                      if (entry.note != null) {
                          if (entry.note.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) { return entry; }
                      }

                      // responses which match controls containing mood thresholds are assumed to be a question which relates to mood.
                      if (entry.responses != null && params == 'positive_mood') {
                        const mood_insights = true;
                        const mood_threshold = 50;
                          for (const res of entry.responses) {
                              const base = this.controls.get(res.id);
                              //if (base && base.mood_insights && res.response.intensity > base.mood_threshold) {
                              if (base && mood_insights && res.response.intensity > mood_threshold) {
                                  return entry;
                              }
                          }
                      }
                  }
            //}
          }).sort(function (a, b) {
              if (a.date > b.date) {
                  return -1;
              }
              if (a.date < b.date) {
                  return 1;
              }
              return 0;
          });
      }
      // )};
  }

  /**
   * Adds the entry to the list of entries
   * @param entry The entry to add
   */
  
  add(entry: Entry) {
      this.entries.push(entry);
      this.firestore.add('entries', entry);

      // Legacy
      //this.storage.set('entries', JSON.stringify(this.entries));
      return this.entries;
  }
  

  /**
   * Deletes the entry from the list
   * @param entry entry to be deleted
   */
  
  async delete(id: number) {
      const entry = await this.get(id);
      entry.inuse = false;
      if (entry.activities != null && entry.activities.length > 0) {
          for (const entryactivity of entry.activities) {
              for (const activity of this.activities.query()) {
                  if (entryactivity.id == activity.id) {
                      activity.visibletimesused--;
                  }
              }
          }
          //comment out by Seoyong
          //this.activities.storage.set('activities', JSON.stringify(this.activities.query()))
      }
      // Check if there are people, then increase the 'timesused' for each person.
      if (entry.people != null && entry.people.length > 0) {
          for (const entryperson of entry.people) {
              for (const person of this.people.query()) {
                  if (entryperson.id == person.id) {
                      person.visibletimesused--;
                      this.people.update(person);
                  }
              }
          }
      }
      this.firestore.update('entries', entry);
  }
  
  
  async undoDelete(id: number) {

      const entry = await this.get(id);
      entry.inuse = true;

      if (entry.activities != null && entry.activities.length > 0) {
          for (const entryactivity of entry.activities) {
              for (const activity of this.activities.query()) {
                  if (entryactivity.id == activity.id) {
                      activity.visibletimesused++;
                      this.activities.update(activity);
                  }
              }
          }
          //comment out by Seoyong
          //this.activities.storage.set('activities', JSON.stringify(this.activities.query()));
      }
      // Check if there are people, then increase the 'timesused' for each person.
      if (entry.people != null && entry.people.length > 0) {
          for (const entryperson of entry.people) {
              for (const person of this.people.query()) {
                  if (entryperson.id == person.id) {
                      person.visibletimesused++;
                      this.people.update(person);
                  }
              }
          }

      }
      this.firestore.update('entries', entry);
  }
  


  /**
   * Resets the data in the temp entry (entries[0])
   */
  
  async deleteTemp() {
      const obj = await this.get(0);
      if (obj !== undefined) {
          obj.id = 0;
          obj.recorded = null;
          obj.updated = null;
          obj.date = null;
          obj.responses = null;
          obj.activities = null;
          obj.activity_category_id = null;
          obj.people = null;
          obj.note = null;
          obj.questions = null;
          this.update(obj);
      }

  }
  

  /**
   * Returns the entry specified
   * @param id The ID of the entry to be returned
   */
  
  async get(id: number): Promise<Entry> {
      if (id != 0) {
          return this.firestore.get('entries', id.toString()).then(r => {
              return r as Entry;
          });
      } else {
          let output = null;
          for (const entry of this.entries) {
              if (entry.id == id) {
                  output = entry;
              }
          }
          return output;
      }

  }
  

  /**
   * Returns the value of the highest entry ID + 1
   */
  getNextID() {
      let highestNumber = 0;
      for (const entry of this.entries) {
          if (entry.id > highestNumber) {
              highestNumber = entry.id;
          }
      }
      return (highestNumber + 1);
  }
  /**
   * Updates entries in storage
   * ; //comment out by Seoyong
   */
  /*
  updateStorage() {
      this.storage.set('entries', JSON.stringify(this.entries));
      //this.firestore.setCollection('entries', this.entries);
  }
  */
 
  update(entry: Entry) {
      this.firestore.update('entries', entry);
  }
  

  /**
   * Formats a date.
   * @param date
   */
  getFormattedDate(date: string) {
      return moment(new Date(date)).format('dddd, DD MMMM YYYY, h:mm a');
  }

  queryPositive() {

  }
}
