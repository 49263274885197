import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {MatDialog} from '@angular/material';
import {FirebaseService} from '../../services/firebase.service';
import {UsersDialogComponent} from './users.dialog/users.dialog.component';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {DeleteDialogComponent} from '../../components/dialog/delete/delete.dialog.component';
import {CodeDialogComponent} from '../../components/dialog/code/code.dialog.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireFunctions} from '@angular/fire/functions';
import {HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CognitoMember} from '../../models/cognito_member';
import {User} from '../../models/user';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoAccessToken
} from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';



@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  users: any[];
  private dialogRef;
  private subscription: Subscription;
  private collName: string;
  private awsAuth: CognitoUserPool;

  constructor(private app: AppComponent, 
    private fireauth: AngularFireAuth,
    private dialog: MatDialog, 
    private fs: FirebaseService, 
    public auth: AuthService, 
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private fbfunc: AngularFireFunctions, 
    public db: AngularFirestore) { }

  ngOnInit() {
    this.app.setTitle('Users');
    this.getData(); 
  }

  private getData(){ 
    if (this.auth.isAdmin()) {
      /* fetch all Dashboard Users */
      this.subscription = this.fs.getUsersAll().subscribe(users => {
        users.forEach(async user => {
          user.referralCode = await this.fs.getReferralCode(user.ref); 
          let coll = await this.fs.getbyRef(user.providerId); 
          user.providerName = coll.name;
        });      
        this.users = users;  
        let uids = this.users.map(x => {return x.uid;});
        this.getCognitoUsers(uids.toString());
      }); 
    } else if (this.auth.isProviderAdmin()){
      /* Only fetch Dashboard Users by providerID */
      this.subscription = this.fs.getUsersByProvider(this.auth.intuiUser.providerId).subscribe(users => {
        users.forEach(async user => {
          user.referralCode = await this.fs.getReferralCode(user.ref); 
        });      
        this.users = users;  
        let uids = this.users.map(x => {return x.uid;});
        this.getCognitoUsers(uids.toString());
      });       
    }
  } 

  async getCognitoUsers(uids){    
      
    let cognitoUserMap = new Map<string, CognitoMember>(); 
    let url = environment.awsApis.userList + '&uids=' + uids;  

    const res = await this.http.get(url).toPromise() 
    .then(res => { 

      res['Users'].map(item => {
        const row = new CognitoMember(item); 
        cognitoUserMap.set(row.username, row);  
      }); 
      
      this.users.map(member => {
        let row = cognitoUserMap.get(member.uid) as CognitoMember; 
        if(row){ 
          member.firstName = row.firstName;
          member.lastName = row.lastName;
          member.email = row.email;
          member.gender = row.gender; 
          member.phoneNumber = row.phoneNumber; 
          member.dob = new Date(row.birthdate); 
        }
      });  

      console.log(this.users);   
    }).catch(err=>{  
      console.log('this.data.err 222===' + err); 
    }); 
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  addClinician() { 
    this.dialogRef = this.dialog.open(UsersDialogComponent, {
      height: 'auto',
      width: '60vw',
      data: {userObj: null, providerId: this.auth.intuiUser.providerId, isAdmin: this.auth.isAdmin()}
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      console.log('Dialog Results (add)',result);
      if (result) {
        this.register(result); 
      }
    }); 
}


  async register(registerObj) { 
    
    const authData = environment.awsConfig;
    this.awsAuth = new CognitoUserPool(authData);
    
    const email = registerObj.email.toLowerCase().trim();

    const attributeEmail = new CognitoUserAttribute(
      {Name: 'email', Value: email}); 
    const attributePhoneNumber = new CognitoUserAttribute(
      {Name: 'phone_number', Value: registerObj.phone}); 
    const attributeName = new CognitoUserAttribute(
      {Name: 'name', Value: `${registerObj.firstName} ${registerObj.lastName}`});
    const attributeFirstName = new CognitoUserAttribute(
      {Name: 'given_name', Value: registerObj.firstName});
    const attributeLastName = new CognitoUserAttribute(
      {Name: 'family_name', Value: registerObj.lastName}); 
    //const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: registerObj.gender});
    //const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: registerObj.dob});
    const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: 'male'});
    const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: '2001-05-21'});
      
    await this.awsAuth.signUp(email, registerObj.pass, [attributeEmail, attributeBirthdate, attributeGender, 
                                          attributeFirstName, attributeLastName, attributePhoneNumber], null,
      async (err, r: any) => { 

        if (err) {
          console.error(err);
          this.snackBar.open(err.message, null, {
            duration: 5000,
          });
        } else {
          this.addUser(registerObj, r.userSub).then(async (usr) => { 
            this.snackBar.open(`${registerObj.firstName} account created!`, null, {
              duration: 5000,
            });
            return usr;
          }).catch(async e => {
            this.snackBar.open(e.message, null, {
              duration: 5000,
            });
            return e;
          });
        }
      });
  }
  
  async addUser(user, uuid) { 

    /*Provider-Admin default is set to the same PROVIDER*/
    let providerRef = await this.db.doc(this.auth.intuiUser.providerId).get().toPromise();

    if (this.auth.isAdmin) {
      /*TODO: Admin can select the PROVIDER*/
      providerRef = await this.db.doc(user.providerId).get().toPromise();
    } 

    const dbUser = new User(false, providerRef.ref.path,  uuid);
  
    dbUser.roles = [user.roles];
    dbUser.created = user.created;
    //dbUser.interum = true;

    return await this.fbfunc.httpsCallable('complete_user')({user: dbUser}).toPromise().then(r => {
      console.log('complete_user', r);
      return dbUser;
    });
  }


  getAge(date: Date) {
    const timeDiff = Math.abs(Date.now() - date.getTime() );
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  getAvatar(client) {
    return (client.avatarUrl != null) ?
      client.avatarUrl : `https://ui-avatars.com/api/?size=130&font-size=0.4&color=ff7f5c&name=${client.firstName}+${client.lastName}`;
  }

  viewClient(client) {
    console.log(client);
    // TODO open up whole client screen.

  }

  edit(user) {
    this.dialogRef = this.dialog.open(UsersDialogComponent, {
      height: 'auto',
      width: '60vw', data: {userObj: user, providerId: this.auth.intuiUser.providerId, isAdmin: this.auth.isAdmin()}
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      console.log('Dialog Results (edit)', result);
      if (result) {
        const providerRef = await this.db.doc(result.providerId).get().toPromise();
        result.providerId = providerRef.ref;
        result.ref = user.ref;

        const userUpdated = {roles: [result.roles], updated: result.created};
        console.log('User Update', userUpdated);

        this.fs.updateById('users', user.uid, userUpdated);
      }
    });
  }

  delete(user) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      height: '260px',
      width: '340px',
      data: {collection: 'users', obj: {id : user.uid, ref: user.ref}, msg: 'Are you sure you wish to permanently remove this user:', delete: true}
    });
  }

  code(user) {
    this.dialogRef = this.dialog.open(CodeDialogComponent, {
      height: '260px',
      width: '340px',
      data: {collection: 'users', obj: user, provider: this.auth.intuiUser.providerId, isClient: false}
    });
  }
};

