import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FirebaseService} from '../../../services/firebase.service';
import {Location} from '@angular/common';
import {FirebaseApp} from '@angular/fire';
import {AngularFireFunctions} from '@angular/fire/functions';
import HttpsCallableOptions = functions.HttpsCallableOptions;
import {functions} from 'firebase';
import {HttpClient} from '@angular/common/http';

export interface IObject {
  name: string;
  isClient: boolean;
  obj: any;
  provider: any;
  collection: string;
}

@Component({
  selector: 'app-code-dialog',
  templateUrl: './code.dialog.component.html',
  styleUrls: ['./code.dialog.component.scss']
})
export class CodeDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CodeDialogComponent>,
              private fbfunc: AngularFireFunctions,
              private location: Location,
              @Inject(MAT_DIALOG_DATA) public data: IObject) {

  }

  ngOnInit() {
  }

  generate() {

    if (this.data.isClient) {
      this.fbfunc.httpsCallable('generate_code')({client_id: this.data.obj.ref.path, provider_id: this.data.provider.path, email: this.data.obj.email}).subscribe(res => {
        console.log(res);
      }, err => {
        console.error(err);
      });
    } else {
      this.fbfunc.httpsCallable('generate_code')({user_id: this.data.obj.ref.path, provider_id: this.data.provider.path, email: this.data.obj.email}).subscribe(res => {
        console.log(res);
      }, err => {
        console.error(err);
      });
    }

    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
