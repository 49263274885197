import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AngularFireAuthGuard} from '@angular/fire/auth-guard';
import {HomeComponent} from './pages/home/home.component';
import {ProfileComponent} from './pages/settings/profile/profile.component';
import {UsersComponent} from './pages/users/users.component';
import {ReportsComponent} from './pages/reports/reports.component';
import {LoginComponent} from './pages/login/login.component';
import {AccountForgottenComponent} from './pages/account-forgotten/account-forgotten.component'; 
import {SettingsComponent} from './pages/settings/settings.component';
import {ProvidersComponent} from './pages/providers/providers.component';
import {ClientsComponent} from './pages/clients/clients.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {AdminComponent} from './pages/admin/admin.component';
import {HealthAssessmentComponent} from './pages/health-assessment/health-assessment.component';
import {CodeComponent} from './pages/code/code.component';


const routes: Routes = [
  { path: 'users', component: UsersComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'providers', component: ProvidersComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'home', component: ClientsComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'client/:id', component: ClientsComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'client/:id/action/:actionId', component: ClientsComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'users', component: UsersComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'assessments', component: ReportsComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'client/:clientId/assessment/:assessmentId', component: HealthAssessmentComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate : [AngularFireAuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'account-forgotten', component: AccountForgottenComponent }, 
  { path: 'code_register', component: LoginComponent },
  { path: 'code/:code', component: CodeComponent },
  { path: '', component: LoginComponent, pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
