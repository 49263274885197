import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../../services/firebase.service';
import {firestore} from 'firebase/app';
import * as moment from 'moment';
import {AngularFirestore, DocumentReference, Query} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import {AngularFireFunctions} from '@angular/fire/functions';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {CognitoMember} from '../../../models/cognito_member';
import {Client} from '../../../models/client';
import { v4 as uuid } from 'uuid';

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoAccessToken
} from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';
import Timestamp = firestore.Timestamp;

export interface ClientDialogData {
  ref: DocumentReference;
  firstName: string;
  lastName: string;
  email: string;
  created: Date;
  dob: Date;
  phone: string;
  gender: string;
  thirdPartyId: string;
  clientRelationship: string;
  package: string;
  title: string;
  isCircleMember: boolean;
  clientIdForCircleMember: string;
  isScreened: boolean;
  isEdit: boolean;
}

@Component({
  selector: 'app-users-dialog',
  templateUrl: './clients.dialog.component.html',
  styleUrls: ['./clients.dialog.component.scss'],
})
export class ClientsDialogComponent implements OnInit, OnDestroy {

  // public project: any = {};
  clientGroup: FormGroup;
  title = 'Register New Client';
  mode = 'Create';

  clientFirstName = new FormControl('', [Validators.required]);
  clientLastName = new FormControl('', [Validators.required]);
  clientEmail = new FormControl('');
  clientDob = new FormControl('', [Validators.required]);
  clientPhone = new FormControl('', [Validators.maxLength(10), Validators.minLength(10)]);
  clientThirdPartyId = new FormControl('');
  clientPackage = new FormControl('');
  clientGender = new FormControl('', [Validators.required]);
  clientRelationship = new FormControl('', [Validators.required]);
  isCircleMember: boolean;
  clientIdForCircleMember: string;
  isScreened: boolean;
  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ClientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientDialogData,
    private fireauth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public auth: AuthService,
    private fbfunc: AngularFireFunctions,
    private db: AngularFirestore) {

    if (this.data) {
      if (this.data.firstName) { this.mode = 'Save'} else { this.mode = 'Create'}
      this.isCircleMember = data.isCircleMember;
      this.clientIdForCircleMember = data.clientIdForCircleMember;
      this.title =  this.data.title;
      this.clientFirstName.setValue(data.firstName);
      this.clientLastName.setValue(data.lastName);
      this.clientPhone.setValue(data.phone);
      if (data.dob) { this.clientDob.setValue(moment((data.dob)).format('YYYY-MM-DD')); }
      this.clientEmail.setValue(data.email);
      this.clientPackage.setValue(data.package);
      this.clientGender.setValue(data.gender);
      this.clientThirdPartyId.setValue(data.thirdPartyId);
      this.clientRelationship.setValue(data.clientRelationship);
      this.isScreened = data.isScreened;
      this.isEdit = data.isEdit;
    } else {
      this.isScreened = false;
    }

  }

  getFirstNameErrorMessage() {
    return this.clientFirstName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getLastNameErrorMessage() {
    return this.clientLastName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getEmailErrorMessage() {
    return this.clientEmail.hasError('required') ? 'You must enter a value' :
      '';
  }

  getPhoneErrorMessage() {
    const req = this.clientPhone.hasError('required') ? 'You must enter a value' :
      '';

    const maxlength = this.clientPhone.hasError('maxlength');
    const minlength = this.clientPhone.hasError('minlength');

    return `${req} ${(maxlength || minlength) ? 'Phone must be 10 digits' : ''}`;
  }

  getDobErrorMessage() {
    return this.clientDob.hasError('required') ? 'You must enter a value' :
      '';
  }

  getGenderErrorMessage() {
    return this.clientGender.hasError('required') ? 'You must select a value' :
      '';
  }

  getRelationshipErrorMessage() {
    return this.clientRelationship.hasError('required') ? 'You must select a value' :
      '';
  }

  ngOnInit(): void {
    this.clientGroup = new FormGroup({
      firstname: this.clientFirstName,
      lastname: this.clientLastName,
      email: this.clientEmail,
      dob: this.clientDob,
      gender: this.clientGender,
      thirdPartyId: this.clientThirdPartyId,
      phone: this.clientPhone,
      relationship: this.clientRelationship
  });
}

  ngOnDestroy(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
}

async onSave() {
    if (!this.clientFirstName.invalid && !this.clientLastName.invalid
      && !this.clientDob.invalid && !this.clientGender.invalid) {
        /*
      const payload = {
        firstName: this.clientFirstName.value,
        lastName: this.clientLastName.value,
        email: this.clientEmail.value,
        created: new Date(Date.now()),
        gender: this.clientGender.value,
        relationship: this.clientRelationship.value,
        thirdPartyId: this.clientThirdPartyId.value,
        phone: this.clientPhone.value,
        dob: new Date(Date.parse(this.clientDob.value)),
        avatarUrl: null, deleted: false,
        isScreened: this.isScreened
      };
      this.dialogRef.close(payload);
      */
      await this.register();
    } else {
      this.clientGroup.markAllAsTouched();
    }
  }



  async register() {

    const authData = environment.awsConfig;
    const awsAuth = new CognitoUserPool(authData);

    let temporaryEmail :boolean = false;
    let email = '';
    if(this.clientEmail.value){
      email = this.clientEmail.value.toLowerCase();
    }else{
      email = 'intui-care-client' + uuid() + '@goact.com.au';
      temporaryEmail = true;
      console.log(email);
    }
    let phoneNumber = '';
    if(this.clientPhone.value){
      phoneNumber = '+61'+ this.clientPhone.value.trim();
    }

    const attributeEmail = new CognitoUserAttribute(
      {Name: 'email', Value: email});
    //const attributePhoneNumber = new CognitoUserAttribute(
    //  {Name: 'phone_number', Value:  phoneNumber});
    const attributePhoneNumber = new CognitoUserAttribute(
      {Name: 'phone_number', Value:  phoneNumber});
    const attributeName = new CognitoUserAttribute(
      {Name: 'name', Value: `${this.clientFirstName.value} ${this.clientLastName.value}`});
    const attributeFirstName = new CognitoUserAttribute(
      {Name: 'given_name', Value: this.clientFirstName.value});
    const attributeLastName = new CognitoUserAttribute(
      {Name: 'family_name', Value: this.clientLastName.value});
    //const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: registerObj.gender});
    //const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: registerObj.dob});
    const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: this.clientGender.value});

    const dateOfBirth = new Date(Date.parse(this.clientDob.value));
    var datestring = dateOfBirth.getFullYear() + "-" + ("0"+(dateOfBirth.getMonth()+1)).slice(-2) + "-" + ("0" + dateOfBirth.getDate()).slice(-2);
    //const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: '2001-05-21'});
    const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: datestring});
    console.log('datestring', datestring);
    await awsAuth.signUp(email, 'AdadsfE081!', [attributeEmail, attributeBirthdate, attributeGender,
                                          attributeFirstName, attributeLastName, attributePhoneNumber], null,
      async (err, r: any) => {

        if (err) {
          console.error(err);
          this.snackBar.open(err.message, null, {  duration: 5000, });
        } else {
          console.log(r);
          const relationship = this.clientRelationship.value ? this.clientRelationship.value : '';
          const thirdPartyId = this.clientThirdPartyId.value ? this.clientThirdPartyId.value : '';

          const payload = {
            created: new Date(Date.now()),
            relationship: relationship,
            thirdPartyId: thirdPartyId,
            phone: phoneNumber,
            avatarUrl: null,
            deleted: false,
            interum: true,
            providers: [this.auth.intuiUser.providerId],
            isScreened: this.isScreened ? this.isScreened : false,
            tempEmailUser: temporaryEmail,
            uid :r.userSub
          };
          this.addUser(payload, r.userSub).then(async (usr) => {
            this.snackBar.open(`${this.clientFirstName.value} account created!`, null, { duration: 5000, });
            this.dialogRef.close({add: true});
          }).catch(async e => {
            this.snackBar.open(e.message, null, {
              duration: 5000,
            });
            return e;
          });

        }
      });
  }


  async addUser(user, uuid) {
    if(this.isCircleMember){
      user.primary = false;
      user.uid = uuid;
      return await this.db.doc(`clients/${this.clientIdForCircleMember}/circle/${uuid}`).set(user);
    }else{
      //dbUser.interum = true;
      user.userRef = this.auth.intuiUser.ref;
      return await this.db.doc(`clients/${uuid}`).set(user);
      /*
      return await this.fbfunc.httpsCallable('complete_user')({user: dbUser}).toPromise().then(r => {
        console.log('complete_user', r);
        return dbUser;
      });
      */
    }
  }

  async onEdit() {
    const relationship = this.clientRelationship.value ? this.clientRelationship.value : '';
    const thirdPartyId = this.clientThirdPartyId.value ? this.clientThirdPartyId.value : '';

    const payload = {
      relationship: relationship,
      thirdPartyId: thirdPartyId,
      phone: this.clientPhone.value,
      avatarUrl: null,
      deleted: false,
      isScreened: this.isScreened ? this.isScreened : false
    };

    await this.db.doc(`${this.data.ref.path}`).set(payload,{merge:true});
    this.dialogRef.close(payload);
  }

}
