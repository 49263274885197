import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {CodeDialogComponent} from '../code/code.dialog.component';

@Component({
  selector: 'app-auth.dialog',
  templateUrl: './auth.dialog.component.html',
  styleUrls: ['./auth.dialog.component.scss']
})
export class AuthDialogComponent implements OnInit {
  private loggingIn: boolean;
  loginEmail = new FormControl('', [Validators.required]);
  loginPassword = new FormControl('', [Validators.required]);

  login: FormGroup = new FormGroup({
    loginEmail: this.loginEmail,
    loginPassword: this.loginPassword
  });

  constructor(public dialogRef: MatDialogRef<AuthDialogComponent>, public auth: AuthService, private snackBar: MatSnackBar) {}

  ngOnInit() {
  }

  getLoginEmailErrorMessage() {
    return this.loginEmail.hasError('required') ? 'You must enter a value' :
      '';
  }

  getLoginPasswordErrorMessage() {
    return this.loginPassword.hasError('required') ? 'You must enter a value' :
      '';
  }
 
  submit() {
    this.loggingIn = true;
    if (this.login.valid) {
      // TODO Hit firebase and auth..
      this.auth.loginAccount(this.login.value.loginEmail, this.login.value.loginPassword).then(res => {
        this.dialogRef.close(res);
      }, (error) => {
        this.snackBar.open(error.message, 'Ok');
      }) ;
    } else {
      this.login.markAllAsTouched();
      this.loggingIn = false;
    }
  }

  async loginSocial(type) {
    switch (type) {
      case 'google':
        const user = await this.auth.loginGoogle();
        if (user) {
          this.dialogRef.close(user);
        }
        break;
      default:
        break;
    }
  }

}
