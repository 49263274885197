import { Injectable } from '@angular/core'; 
import { Activity } from '../models/activity';
import {FirebaseService} from './firebase.service';
import {map} from 'rxjs/internal/operators';
import {Subscription} from 'rxjs';
import {AngularFirestore, Query, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore';



@Injectable({
  providedIn: 'root'
})

export class ActivitiesService {
    activities: Activity[] = []; 
    private subscription: Subscription;
    private activitiesConfig: any[];

    constructor(private firestore: FirebaseService, 
        private db: AngularFirestore) {
        //this.storage = storage; 
    }

    async init(uid) {
        //Comment out By Seoyong
        //if (!this.activitiesConfig) {
        //    await this.getActivities();
        //}
        
        this.activities = [];
        let collection = await this.db.collection(`clients/${uid}/activities`, ref => ref) ; 
        await collection.get().toPromise()
        .then(activities => {   
            if (activities.docs && activities.docs.length > 0) {
                
                activities.docs.map(doc => {
                    const data: any = doc.data();
                    data.ref =  doc.ref;
                    this.activities.push(data as Activity); 
                });
                //comment out by Seoyong
                //this.activities = this.activities.filter((x) => {
                //    return x.inuse === true;
                //});
 
            } else if (this.activitiesConfig) {
                this.activitiesConfig.forEach((x: Activity) => {
                    x.timesused = 0;
                    x.visibletimesused = 0;
                    x.categories = null;
                    this.add(x);
                });
            } 
        }); 
    }


    async getActvityCategories(providerRef : DocumentReference) {  
        let entriesPerCat = {}; 
        const snap = await this.db.collection(`${providerRef.path}/activity_cat_bank`, ref => ref).get().toPromise();
        snap.docs.forEach((doc: any) =>  {     
            const activity = doc.data(); 
            entriesPerCat[activity.name] = {count: 0, drilldown: []}; 
        });  
        return entriesPerCat; 
    }
    
    /**
     * Query the data (return the array of activities)
     * @return {Activity[]} this.activities
     */
    query() {
        return this.activities.filter((activity) => {
            //Comment out by Seoyong, later we might need this.
            //if (activity.inuse) { return activity; }
            return activity;
        });
    }

    queryWithCategories() {
        return this.activitiesConfig;
    }

    /**
     * Adds the activity to the list of activities
     * @param activity
     */
    async add(activity: Activity) {
        this.activities.push(activity);
        this.firestore.add('activities', activity);
        return this.activities;
    }

    /**
     * Deletes the activity from the list of activities
     * @param activity
     */
    delete(id: number) {
        return this.getAsync(id).then(activity => {
            activity.inuse = false;
            this.update(activity);
            return this.activities;
        });
    }

    update(activity: Activity) {
        this.firestore.update('activities', activity);
    }
/*
    undoDelete(id: number) {
        this.get(id).inuse = true;
        this.storage.set('activities', JSON.stringify(this.activities));
    }
*/    
    /**
     * Returns the activity that has a specific ID
     * @param id
     */
    get(id: number) {
        for (const activity of this.activities) {
            if (activity.id == id) { return activity; }
        }
    }

    async getAsync(id: number) {
        if (this.activities.length == 0) {
            await this.getActivities();
        }
        for (const activity of this.activities) {
            if (activity.id === id) {
                return activity;
            }
        }
    }

    /**
     * Returns the highest activity ID plus one.
     */
    getNextID() {
        let highestNumber = 0;
        for (const activity of this.activities) {
            if (activity.id > highestNumber) {
                highestNumber = activity.id;
            }
        }
        return (highestNumber + 1);
    }

    async getActivities() {
         await this.firestore.watchCollection('configurations/activities/list').subscribe(x => {
             this.activitiesConfig = x;
         });
    }

    randomColor() {
        let color = Math.floor(Math.random() * Math.pow(256, 3)).toString(16);
        while(color.length < 6) {
            color = '0' + color;
        }
        return '#' + color;
    }
}
