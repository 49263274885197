import { Injectable } from '@angular/core'; 

import { Activity_Category } from '../models/activity_category';
import {FirebaseService} from './firebase.service';
import {Activity} from '../models/activity';
import {map} from 'rxjs/internal/operators';
import {Subscription} from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class ActivityCategoriesService {
    activity_categories: Activity_Category[] = []; 
    private uid: string;
    private activity_categories_config: any[];
    private subscription: Subscription;


    constructor(private firestore: FirebaseService) { 
    }

    async init(uid) {
        this.uid = uid;
        
        //if (!this.activity_categories_config) {
        //    await this.getActivityConfigs();
        //}
 
        this.subscription = await this.firestore.watchCollection(`clients/${uid}/activity_categories`).subscribe(categories => {
            if (this.activity_categories.length > 0) {
                this.activity_categories = categories.filter((x) => {
                    return x.inuse === true;
                });
            } else if (this.activity_categories_config) {
                this.activity_categories_config.forEach((x: Activity) => {
                    x.timesused = 0;
                    x.visibletimesused = 0;
                    this.add(x);
                });
            }
        }); 

    }

    /**
     * Query the data (return the array of activity_categories)
     * @return {Activity_Category[]} this.activity_categories
     */
    query() {
        return this.activity_categories.filter((activity_category) => {
            if (activity_category.inuse) {
                return activity_category;
            }
        });
    }

    /**
     * Adds the activity_category to the list of activity_categories
     * @param activity_category
     */
    add(activity_category: Activity_Category) {
        this.activity_categories.push(activity_category);
        this.firestore.add('activity_categories', activity_category);
        return this.activity_categories;
        // this.storage.set('activity_categories', JSON.stringify(this.activity_categories));
    }

    /**
     * Deletes the activity_category from the list of activity_categories
     * @param activity_category
     */
    delete(id: number) {
        return this.getAsync(id).then(activity_category => {
            activity_category.inuse = false;
            this.update(activity_category);
            return this.activity_categories;
        });
    }

    undoDelete(id: number) {
        return this.getAsync(id).then(activity => {
            activity.inuse = true;
            this.update(activity);
            return this.activity_categories;
        });
    }
    /**
     * Returns the activity_category that has a specific ID
     * @param id
     */
    get(id: number) {
        for (const activity_category of this.activity_categories) {
            if (activity_category.id === id) {
                return activity_category;
            }
        }
    }

    async getAsync(id: number) {
        if (this.activity_categories.length == 0) {
            await this.init(this.uid);
        }
        for (const activity_category of this.activity_categories) {
            if (activity_category.id == id) {
                return activity_category;
            }
        }
    }

    update(activity_category: Activity_Category) {
        this.firestore.update('activity_categories', activity_category);
    }

    /**
     * Returns the highest activity_category ID plus one.
     */
    getNextID() {
        let highestNumber = 0;
        for (const activity_category of this.activity_categories) {
            if (activity_category.id > highestNumber) {
                highestNumber = activity_category.id;
            }
        }
        return (highestNumber + 1);
    }


    getActivityConfigs() {
        this.firestore.watchCollection('configurations/activities/categories').subscribe(x => {
            this.activity_categories_config = x;
        });
    }
}