import {Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-range-with-text-trigger',
  templateUrl: './range-with-text-trigger.component.html',
  styleUrls: ['./range-with-text-trigger.component.scss']
})
export class RangeWithTextTriggerComponent implements OnInit {

  @Output()
  response = new EventEmitter();

  @Input() options;
  @Input() question;
  @Input() id;
  @Input() domain;

  public val: any;
  public text: any;
  public selected: any;
  public rangeSelected: boolean;

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.text = '';
  }

  select(i) {
    this.rangeSelected = true;
    this.selected = i;
    this.val = [i, this.options[i]];
    this.response.emit(JSON.stringify({id: this.id, question: this.question, response: this.val, reason: this.text, domain: this.domain}));
  }

  isSelected(i) {
    return (this.selected === i) ? 'check' : 'close';
  }

  onChange() {
    this.response.emit(JSON.stringify({id: this.id, question: this.question, response: this.val, reason: this.text, domain: this.domain}));
  }

}
