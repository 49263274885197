import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FirebaseService} from '../../../services/firebase.service';
import {Location} from '@angular/common';

export interface IObject {
  name: string;
  obj: any;
  collection: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete.dialog.component.html',
  styleUrls: ['./delete.dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
              private firebase: FirebaseService,
              private location: Location,
              @Inject(MAT_DIALOG_DATA) public data: IObject) {

  }

  ngOnInit() {
  }

  delete(obj) {
    this.firebase.delete(this.data.collection, obj);
    this.dialogRef.close();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
