import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, DocumentReference, Query} from '@angular/fire/firestore';
import {map} from 'rxjs/internal/operators';
import {notEqual} from 'assert';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private collection: AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) {
   //this.seed();
  }

  watchCollection(collectionName) {
    this.collection = this.db.collection(collectionName, ref => ref.where('deleted', '==', null));
    return this.collection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            data.ref = a.payload.doc.ref;
            return {ref: id, ...data};
          });
        }));
  }

  getProviders(showArchived) {
    this.collection = this.db.collection('providers', ref => ref
      .where('active', '==', !showArchived));
      console.log(this.collection);
    return this.collection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const ref = a.payload.doc.ref;
            return {ref: ref, ...data};
          });
        }));
  }
  
  getClientsByProvider(providerId, showArchived) {
    this.collection = this.db.collection('clients', ref => ref
      .where('providers', 'array-contains', providerId)
      .where('deleted', '==', showArchived));
    return this.collection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const ref = a.payload.doc.ref;
            return {ref: ref, ...data};
          });
        }));
  } 

  async getbyRef(id: DocumentReference) {
    return id.get().then(doc => {
      return doc.data();
    });
  } 

  async get(collectionName, id: string) {
    this.collection = this.db.collection(collectionName);
    return this.collection.ref.doc(id.toString()).get().then(doc => {
      return doc.data();
    });
  }

  async add(collectionName, obj) {
    this.collection = this.db.collection(collectionName);
    const sanitary = this.sanitiseObject(obj);
    sanitary.created = new Date(Date.now());
    this.collection.add(sanitary);
  }


  async update(collectionName, obj) {
    console.warn('UPDATE ', obj);
    this.collection = this.db.collection(collectionName);
    this.collection.doc(obj.ref.id).update(this.sanitiseObject(obj)).catch(ex => {
      console.error('firebase error: ', ex);
    });
  }
  
  async updateById(collectionName, uid, obj) {
    console.warn('UPDATE ', uid, obj);  
    this.db.collection(collectionName).doc(uid).
      set(this.sanitiseObject(obj),{merge:true}).catch(ex => {
        console.error('firebase error: ', ex);
      }); 
  }
  

  async delete(collectionName, obj) {
    console.warn('DELETE ' + collectionName, obj.id);
    this.collection = this.db.collection(collectionName);
    obj.deleted = true;
    obj.ref.update(obj).catch(ex => {
      console.error('firebase error: ', ex);
    });
  }

  sanitiseArray(obj: any[]) {
    obj.forEach(i => {
      for (const propName in i) {
        if (i[propName] === null || i[propName] === undefined) {
          delete i[propName];
        }
      }
    });
    return obj;
  }

  sanitiseObject(obj: any) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || 
          propName == 'email' || propName == 'gender'  || 
          propName == 'firstName' || propName == 'lastName' || propName == 'dob') {
        delete obj[propName];
      }
    }
    return obj;
  }

  getUsersByProvider(providerId: DocumentReference) {
    this.collection = this.db.collection('users', ref =>{ 
        let query : Query = ref; 
        query = query.where('providerId', '==', providerId); 
        query = query.where('deleted', '==', false);
        return query;
      });  
    return this.collection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const ref = a.payload.doc.ref;
            return {ref: ref, ...data};
          });
        }));
  }

  getUsersAll() {
    this.collection = this.db.collection('users', ref => ref.where('deleted', '==', false));
    return this.collection.snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data(); 
            const ref = a.payload.doc.ref; 
            return {ref: ref, ...data};
          }); 
        }));
  }

  async getReferralCode(userRef: DocumentReference) {
     
    this.collection = this.db.collection('access_codes', ref => ref.where('userRef', '==', userRef));
    const code = await this.collection.get().toPromise().then(res => { 
      for(var doc of res.docs){
        const data = doc.data();  
        if(data.code){
          return data.code; 
        }
      } 
      return null; 
    });
    return code;
  }

  getClientById(clientId: string) {
    return this.db.doc('clients/' + clientId);
  }

  async seed() {
    setTimeout(() => {
      this.add('/domains/Z2l6GmqOeV1gumc6rMD7/screening',
        {}
      );
    }, 1000);
  }
}
