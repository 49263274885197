import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms'; 
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Navigation, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import { MustMatch } from '../../../helpers/must-match.validator'; 
import {MatSnackBar} from '@angular/material';
import get = Reflect.get;
import {User} from '../../../models/user';

 
export interface PasswordDialogData {
  firstName: string;
  lastName: string;
  email: string;
  created: Date;
  phone: string;
  roles: any;
  providerName: string;
}

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.dialog.component.html',
  styleUrls: ['./password-reset.dialog.component.scss']
})
 
export class PasswordResetDialogComponent implements OnInit {
  
  current = new FormControl('', [Validators.required]); 
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  password2 = new FormControl('', [Validators.required]);

  newPasswordForm: FormGroup = new FormGroup({   
      current: this.current,
      password: this.password,
      password2: this.password2
    }, 
    [MustMatch(this.password, this.password2)]
  );
 
  codeSent = false; 
  public loading: boolean =false; 

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogData>,
              public auth: AuthService,
              private router: Router,
              private fba: AngularFireAuth,
              private snackBar: MatSnackBar, 
              private activatedRoute: ActivatedRoute) { 
  }

  ngOnInit() { 
  }
    

  getCurrentErrorMessage() {
    return this.current.hasError('required') ? 'You must enter a value' : '';
  }

  getPasswordErrorMessage() {
    let messages = '';
    messages += this.password.hasError('required') ? 'You must enter a value' : '';
    messages += this.password.hasError('minlength') ? 'password must be at least 6 characters' : '';
    return messages;
  }

  getPassword2ErrorMessage() {
    let messages = '';
    messages += this.password2.hasError('required') ? 'You must enter a value' : '';
    messages += this.password2.hasError('mustMatch') ? 'password must match' : '';
    return messages;
  } 
   
  
  onCancelClick(): void {
    const payload = { 
      changed: false
    };      
    this.dialogRef.close(payload);
    this.dialogRef.close(payload);
  }

  /**
   * Request the change to the account password then
   * Return the user to the account pages
   */
  async onSave() {   
    if (!this.current.invalid && !this.password.invalid && !this.password2.invalid) { 
      await this.auth.updatePassword(
        this.newPasswordForm.value.current,
        this.newPasswordForm.value.password
      ).then(async (res: any) => {
        this.loading = false; 
        
        const payload = { 
          changed: true
        };      
        this.dialogRef.close(payload);
      }).catch(async err => {
        this.loading = false; 
        const payload = { 
          changed: true,
          err: err.message
        };      
        this.dialogRef.close(payload); 
      });  
    } else {
      this.newPasswordForm.markAllAsTouched();
    }
  } 
}
