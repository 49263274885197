import {Component, Inject, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {AssessmentDialogData, HealthAssessmentDialogComponent} from '../dialog/health-assessment/health-assessment.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FirebaseService} from '../../services/firebase.service';
import {Router} from '@angular/router'; 
import {Domain} from '../../models/domain';
import {DocumentReference} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AuthService} from '../../services/auth.service';
import {AssessmentService} from '../../services/assessment.service';
import {ScoringService} from '../../services/scoring.service';
import {StatisticsService} from '../../services/statistics.service';
import {NotificationService} from '../../services/notification.service'; 
import {CloudService} from '../../services/cloud.service';  
import {compare} from '../../helpers/utils';
import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import {HttpClientModule, HttpClientJsonpModule, HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';

import jsPDF from 'jspdf';
import 'jspdf-autotable';   
 
 
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
  fillColor = [255, 127, 92];
  textColor = [255,255,255];
  charts = new Map<string, any>();
  loaded: boolean;

  base64Imgs :any[] = [];
  trendPeriod :string = "0";
  domains: Domain[];
  questions: any[];
  emaChartData: any[];
  assessmentChartData: any[];
  notifications: any[];
  private emas: any;
  private emaTrends: any;
  private assessments: firebase.firestore.QueryDocumentSnapshot[];

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<HealthAssessmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssessmentDialogData,
              private router: Router,
              public auth: AuthService,  
              private assessmentService: AssessmentService,
              public scoringService: ScoringService,
              private snackBar: MatSnackBar,
              private fs: FirebaseService,
              public stats: StatisticsService,
              public notificationService: NotificationService,
              private cloudService: CloudService,
              private fbfunc: AngularFireFunctions,
              private http: HttpClient
              ) { }


  @Input() client: any;

  ngOnInit() {
    this.loaded = false;
    this.getData();
  }

  async getData() {

    this.domains = await this.assessmentService.domains.filter((x) => 
    (x.name !== 'Screening' && x.name !== 'Smoking' && x.name !== 'Cognitive')).sort((a, b) => {
      return compare( b.name, a.name);
    });
    this.assessments = await this.assessmentService.getLastAssessmentsForClient(this.client.ref);

    this.stats.restat(this.auth.intuiUser.providerId, this.client.uid).then( async r => {
      this.emaChartData = this.stats.emaResponseData; 
      this.emaTrends = await this.getLast28daysEmasForClient(this.stats.getEntries(this.client.uid));
    }); 

    this.assessmentService.getLastEmasForClient(this.client.ref).then(result => {
      this.emas = result;
    });
    
    this.notifications = await this.notificationService.getAllNotifications(this.client.ref, false, 'New');

    await this.getChartData(); 
    this.base64Imgs = await this.cloudService.getBase64ChartImages(this.charts);
    
    this.loaded = true;
  } 

  getLastAssessmentDate(domainRef: DocumentReference) {
    if (this.assessments) {
      const assessments = this.assessments.filter(x => x.data().domain.id === domainRef.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });
      if (assessments.length > 0) {
        return assessments[0].data().created.toDate().toLocaleDateString();
      } else {
        return 'No Data';
      }
    } else {
      return null;
    }
  } 

  calculate(domain) {

    if (this.assessments) {
      const assessments = this.assessments.filter(x => x.data().domain.id === domain.ref.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });

      if (assessments.length > 0) {
        return this.scoringService.calculate(assessments[0].data());
      } else {
        return '';
      }
    }
  }

  // async getLastAssessmentScore(domainRef: DocumentReference) {
  //   return await this.assessmentService.getLastAssessment(this.client.ref, domainRef).;
  // }
  calculateQol(domain: Domain) {
    if (this.assessments) {
      const assessments = this.assessments.filter(x => x.data().domain.id === domain.ref.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });

      if (assessments.length > 0) {
        const res = this.scoringService.calculate(assessments[0].data());
        return `PCS-12: <b>${res[0]}</b> MCS-12: <b>${res[1]}</b>`;;
      } else {
        return null;
      }
    }
  }
 

  description(domain) {
 
    if (this.assessments) {
      //this.assessments.map(x => console.log(x.data().questionnairesRef.id ));

      const assessments = this.assessments.filter(x => x.data().questionnairesRef.id === domain.ref.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });

      if (assessments.length > 0) {
        return this.scoringService.getScoreDescription(assessments[0].data());
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  getLastEmaDate(domainRef: DocumentReference) { 
    if (this.emas && this.emas.responses) {
      const responses = this.emas.responses.filter(x => x.domain.id === domainRef.id);
      if (responses.length > 0) { 
        return moment(this.emas.created.toDate()).format('DD MMM YYYY hh:mm A'); 
      } else {
        return 'No Data';
      }
    } else {
      return null;
    }
  }
   
  onChangedTrendPeriod(event){  
    //this.emaTrends -- [before7daysMap, before14daysMap, before30daysMap]
    this.trendPeriod = event.value;  
    //this.fs.update('clients', client);
  }

  getAverageEmaScore(domainRef: DocumentReference, trendperiod: any) {
    /*TODO: Calculate Average EMA score over trend period for domain */ 
    const idx = parseInt(this.trendPeriod, 10); 
    if(this.emaTrends && this.emaTrends[idx]){  
      if(this.emaTrends[idx].get(domainRef.id)){ 
        const entries = this.emaTrends[idx].get(domainRef.id);
        const average = entries.reduce((a, c) => a + c.val, 0) / entries.length; 
        return average.toFixed(1);
      } 
    }
    return null;
  }

  getEmaEntries(domainRef: DocumentReference, trendperiod: any) {
    /*TODO: Calculate Number of EMA Entries over trend period for domain */
    const idx = parseInt(this.trendPeriod, 10); 
    if(this.emaTrends && this.emaTrends[idx]){ 
      if(this.emaTrends[idx].get(domainRef.id)){  
        return this.emaTrends[idx].get(domainRef.id).length;
      }
    }
    return 0; 
  }

  getEmaTrendOverTime(domainRef: DocumentReference) {
    /*TODO: Calculate EMA Trend over trend period for domain */
    const idx = parseInt(this.trendPeriod, 10); 
    if(this.emaTrends && this.emaTrends[idx]){

      const entries = this.emaTrends[idx].get(domainRef.id); 
      if(entries){ 
        const aDay = 24 * 60 * 60; 
        let basis_y = 0;
        let basis_x = 0;
  
        const value_y =  entries.map( (row : any) => { 
            if(basis_y == 0){
                basis_y = row.val == 0 ? 1 : row.val;//avoid infinity division
                return 1;
            }
            return row.val / basis_y;
        });
        const value_x = entries.map( (row : any) => {
            if(basis_x == 0){
                basis_x = row.created.seconds;
                return 1;
            }
            return 1 + ((row.created.seconds - basis_x) / aDay);
        }); 
  
        const lr = this.linearRegression(value_y, value_x); 

        let BASIS = 0.5; 
        const trendConfig = this.auth.getTrendConfig();
        if(trendConfig != null){
          if(trendConfig.basis){
            BASIS = trendConfig.basis;
          } 
        }

        if( lr.slope >= BASIS){
          return 1; 
        }
        return -1;   
      }
    }
    return 0; 
  }

  linearRegression(y,x) {
    let lr : any = {slope: 0, intercept: 0, r2: 0};

    let n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {

        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    }

    lr.slope = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr.intercept = (sum_y - lr.slope * sum_x)/n;
    lr.r2 = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

    return lr;
  }


  getLastEmaScore(domainRef: DocumentReference) { 
    if (this.emas && this.emas.responses) {
      const responses = this.emas.responses.filter(x => x.domain.id === domainRef.id);
      if (responses.length > 0) {
        return responses[0].response.intensity;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  
  getEmaTrend(domainRef: DocumentReference) { 
    
    let BASIS = 0.5; 
    const trendConfig = this.auth.getTrendConfig();
    if(trendConfig != null){
      if(trendConfig.basis){
        BASIS = trendConfig.basis;
      } 
    }

    if (this.client.trends && this.emas && this.emas.responses) {
      const responses = this.emas.responses.filter(x => x.domain.id === domainRef.id);
      if (responses.length > 0) { 
        const trends = this.client.trends.filter((t) => {
          return (t.id === responses[0].id);
        });  
        
        if(trends.length == 0){
          return 0;
        } 

        if(trends[0].slope >= BASIS){
          return 1; 
        }
        return -1;  
      } 
    }
    return 0;
  }

  getFamilyEmaTrend(domain) {
    //TODO implement this when we have EMA Data in future
    //return 'Not Implemented';
    return '';
  }
 
  getLast28daysEmasForClient(entries){  
    /*
    const before28days = moment(new Date()).subtract(28, 'days').toDate();
     
    const snap = await this.db.collection(`${clientRef.path}/entries`, 
        ref => ref.where('created', '>=', before28days).orderBy('created')).get().toPromise();
      
    if(snap.docs.length == 0){
      return [];
    }

    let emas : any [] = [];  
    */     

    if(!entries || entries.length == 0){
      return [];
    }
    console.log('entries', entries);

    let emas : any [] = []; 
    entries.forEach((ema: any) =>  {      
      let questionMap = new Map<string, any>(); 
      ema.questions.forEach((question: any) =>  {  
        questionMap.set(question.id, question.domainRef);   
      }); 
  
      //get Domain info
      ema.responses.forEach((res: any) =>  {    
        res.domain = questionMap.get(res.id); 
      }); 
      emas.push(ema);
    }); 
    
    return this.getPeoriodicTrends(emas); 
  }
 
  getPeoriodicTrends(emas : any []){

    const before28days = moment(new Date()).subtract(28, 'days').toDate();
    const before14days = moment(new Date()).subtract(14, 'days').toDate();
    const before7days = moment(new Date()).subtract(7, 'days').toDate();

    let before28daysMap = new Map<string, any>(); ;
    let before14daysMap = new Map<string, any>(); ;
    let before7daysMap = new Map<string, any>(); ;

    emas.forEach((ema: any) =>  { 
   
      ema.responses.forEach((res: any) =>  {
        const row = {created: ema.created, val: res.response.intensity};

        if(row.created.seconds * 1000 < before28days.getTime()){
          return;
        }
        // 28days 
        let answers28 = before28daysMap.get(res.domain.id) || [] as any[];   
        answers28.push(row);
        before28daysMap.set(res.domain.id, answers28);

        if(row.created.seconds * 1000 < before14days.getTime()){
          return;
        }
        //14 days
        let answers14 = before14daysMap.get(res.domain.id) || [] as any[];  
        answers14.push(row);
        before14daysMap.set(res.domain.id, answers14); 

        if(row.created.seconds * 1000 < before7days.getTime()){
          return;
        }
        //7 days
        let answers7 = before7daysMap.get(res.domain.id) || [] as any[];  
        answers7.push(row);
        before7daysMap.set(res.domain.id, answers7);

      });  
    });   

    return [before7daysMap, before14daysMap, before28daysMap];
  }
 

  registered(created) {
    return moment(created.toDate()).format('ddd DD MMM YYYY');
  }

  
  async getChartData() { 

    for (const obj of this.domains) {
       const domain: Domain = obj as Domain;
       const assList = await this.assessmentService.getLast5AssessmentsForClientDomain(this.client.ref, domain.ref);
       const seriesData = [];
       const assData = [];
       const assData2 = [];
       for (const ass of assList) {
         const date = ass.data().created.toDate();
         if (obj.name === 'Quality of Life') {
           assData.push([date.toLocaleString(), this.scoringService.calculate(ass.data())[0]]);
           assData2.push([date.toLocaleString(), this.scoringService.calculate(ass.data())[1]]);
         } else {
           assData.push([date.toLocaleString(), this.scoringService.calculate(ass.data())]);
         }
       }

       if (obj.name === 'Quality of Life') {
         seriesData.push({name: 'PCS', data: assData});

         if (assData2.length > 0) {
           seriesData.push({name: 'MCS', data: assData2});
         }
       } else {
         seriesData.push({name: obj.name + ' score', data: assData});
       }

       const chart = new Chart({
         global: {
           useUTC: false
         },
          chart: {
            type: 'spline',
            backgroundColor: '#f0eff7',
          }, 
          title: {
            text: domain.name
          },
          credits: {
            enabled: false
          },
           yAxis: {
             title: {
               text: 'Score'
             }
           },
           xAxis: {
             type: 'category',
             title: {
               text: 'Time'
             }
           },
         plotOptions: {
           series: {
             label: {
               connectorAllowed: false
             }
           }
         },
         series: seriesData,
         responsive: {
           rules: [{
             condition: {
               maxWidth: 500
             },
             chartOptions: {
               plotOptions: {
                 series: {
                   marker: {
                     radius: 2.5
                   }
                 }
               }
             }
           }]
         }
       } as Highcharts.Options); 
       this.charts.set(domain.ref.id, chart); 
    }
  }

  getChart(domain) {
    return this.charts.get(domain.ref.id);
  }


  getEmail(email){
    if(email == null || email === ''){
      return '';
    }else if(email.startsWith('intui-care-client') && email.endsWith('goact.com.au')){
      return '';
    }
    return email;
  }

  getAge(date) {
    if(!date || typeof date.getTime !== "function"){
      return 'unknown ';
    }
    const timeDiff = Math.abs(Date.now() - date.getTime() );
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }
  
  async exportPdf(){ 
    
    this.loaded = true;   
    
    const logoBase64Data = await this.getLogoBase64Data(); 

    await this.createPdf(logoBase64Data);   
  } 


  //https://medium.com/@ramamity94/creating-customisable-beautiful-pdfs-using-jspdf-api-aem-and-angular-991dcc988bbd
  //https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/README.md
  async createPdf(logoBase64 : any) {
    
    const reported = moment(Date.now()).format('ddd DD MMM YYYY');
    const fileName = `IntuiCare-${moment(Date.now()).format('YYYY-MM-DD-HH-mm')}.pdf`;

    const doc : any = new jsPDF('p', 'mm', 'a4');  

    doc.setFontSize(10);
    doc.setTextColor(100);

    //1. Logo on Top Right
    if(logoBase64 != null){   
      doc.addImage(logoBase64, 'png', 175 , 0, 30, 15); 
    }
    
    //2. Client details
    doc.autoTable({
      theme : 'plain',
      startY: 15,
      body: [
        [{ content: 'Client details', styles: { halign: 'left', textColor: this.textColor , fillColor: this.fillColor, fontSize: 14, fontStyle: 'bold'} }],
      ],
    })

    doc.autoTable({
      theme : 'plain', 
      bodyStyles: {fontSize: 8},
      body: [
        [{ content: 'NAME :', styles: {cellWidth: 25, halign: 'left'}}, { content:  `${this.client.firstName} ${this.client.lastName}`,  styles: {halign: 'left'}},
         { content: 'AGE :', styles: {cellWidth: 25, halign: 'left'}}, { content:  `${this.getAge(this.client.dob)} Years old` , styles: { halign: 'left' }}
        ],
        [{ content: 'GENDER :', styles: {cellWidth: 25, halign: 'left'}}, { content:  `${this.client.gender}`,  styles: { halign: 'left' }},
         { content: 'EMAIL :', styles: {cellWidth: 25, halign: 'left'}}, { content:  `${this.getEmail(this.client.email)}`, styles: { halign: 'left' }}
        ],
        [{ content: 'PHONE :', styles: {cellWidth: 25, halign: 'left'}}, { content:  `${this.client.phone}`, styles: { halign: 'left' }}, 
         { content: 'REPORTED :', styles: {cellWidth: 25, halign: 'left'}}, { content:  reported, styles: { halign: 'left' }}
        ],
      ],
    })

    //3.Real Time Data Summary
    doc.autoTable({
      theme : 'plain',
      body: [
        [{ content: 'Real Time Data Summary', styles: { halign: 'left', textColor: this.textColor , fillColor: this.fillColor, fontSize: 14, fontStyle: 'bold'} }],
      ],
    }) 
    doc.autoTable({html:'#table1', bodyStyles: {fontSize: 8}});


    //3.Health Assessment Data Summary
    doc.autoTable({
      theme : 'plain',
      bodyStyles: {fontSize: 8},
      body: [
        [{ content: 'Health Assessment Data Summary', styles: { halign: 'left', textColor: this.textColor , fillColor: this.fillColor, fontSize: 14, fontStyle: 'bold'} }],
      ],
    });
    doc.autoTable({html:'#table2', bodyStyles: {fontSize: 8}});
 
    //4.Health Assessment Charts
    doc.addPage();
    if(this.base64Imgs && this.base64Imgs.length > 0){ 
      doc.autoTable({
        theme : 'plain',
        body: [
          [{ content: 'Health Assessment Charts', styles: { halign: 'left', textColor: this.textColor , fillColor: this.fillColor, fontSize: 14, fontStyle: 'bold'} }],
        ],
      }); 

      let chartHight = 120; 
      let chatIndex = 0;
      for (let i = 0; i < this.base64Imgs.length; i++) {
        if(this.base64Imgs[i]){ 
          if(chatIndex != 0 && chatIndex % 2 == 0){
            doc.addPage();
          }
          doc.autoTable({  
            theme : 'plain', 
            bodyStyles: {minCellHeight: chartHight},
            body: [ 
              ['c'], 
            ],
            didDrawCell: (data) => {
              if (data.column.index === 0 && data.cell.section === 'body' && data.row.height >= chartHight) { 
              //if (data.column.index === 0 && data.cell.section === 'body') { 
                console.log('data', i, data);
                doc.addImage(this.base64Imgs[i], 'png', data.cell.x , data.cell.y, 180, chartHight); 
              }
            }
          }); 
          chatIndex++; 
        } 
      }
    } 

    //5.Notifications
    doc.autoTable({
      //startY: 180,
      theme : 'plain',
      body: [
        [{ content: 'Notifications', styles: { halign: 'left', textColor: this.textColor , fillColor: this.fillColor, fontSize: 14, fontStyle: 'bold'} }],
      ],
    }) 
    doc.autoTable({html:'#table3', bodyStyles: {fontSize: 8}}); 
 
    doc.save(fileName);
    this.loaded = true;  
  }
 
  readAsDataURL(file) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();//https://stackoverflow.com/questions/34495796/javascript-promises-with-filereader
        fr.onerror = reject;
        fr.onload = function() {
            resolve(fr.result);
        }
        fr.readAsDataURL(file);
    });
  }
  
  async getLogoBase64Data(){
    
    return await this.http.get('/assets/light-logo.png', { responseType: 'blob' as 'json' }).toPromise()  
    .then(async (res : Blob) => {  
      return this.readAsDataURL(res).then((base64data) => { 
        return base64data;    
      }).catch((err) =>{
        throw err;
      }) 
    }).catch(err=>{  
      console.log('getLogoBase64Data.err 111===' + JSON.stringify(err)); 
    });   
  } 
}
