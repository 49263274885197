import {DocumentReference} from '@angular/fire/firestore';
import {User} from './user';
import {Provider} from './provider';

export class Assessment {
  ref: DocumentReference;
  domain: DocumentReference;
  name: string;
  icon: string;
  created: any;
  complete: boolean;
  responses: Array<{}>;

  constructor(domain: DocumentReference, name: string, icon: string, created: any, complete: any) {
    this.domain = domain;
    this.name = name;
    this.icon = icon;
    this.created = created;
    this.complete = complete;
  }
}
