import {Component, Input, OnInit} from '@angular/core';

import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts'; 
import {Entry} from '../../../models/entry'; 
import {ColorString} from 'highcharts';

@Component({
  selector: 'app-donut-graph',
  templateUrl: './donut-graph.component.html',
  styleUrls: ['./donut-graph.component.scss']
})
export class DonutGraphComponent implements OnInit {
    charts : any;

    // private series1: any;
    // private series2: any;

    @Input()
    id: any;

    @Input()
    title: string;

    @Input()
    subTitle: string;

    @Input()
    helpText: string;

    @Input()
    series1: any;

    @Input()
    series2: any;


  //constructor(private stats: StatisticsProvider, private alertCtrl: AlertController) { }

  ngOnInit() {
      setTimeout(() => {
            //Highcharts.chart(this.id, {
            this.charts = new Chart({
              chart: {
                  type: 'pie',
                  backgroundColor: 'transparent',
                  spacingRight: 10,
              },
              title: {
                  text: (this.subTitle) ? this.subTitle : ''
              },
              plotOptions: {
                  pie: {
                      shadow: false,
                      center: ['43%', '50%']
                  }
              },
              tooltip: {
                  valueSuffix: '%'
              },
              series: [
                  {
                      data: this.series1,
                      name: '',
                      type: 'pie',
                      size: '50%',
                      dataLabels: {
                          formatter: function () {
                              return this.y > 0 ? this.point.name : null;
                          },
                          color: '#ffffff',
                          distance: -10
                      }
                  } as Highcharts.SeriesPieOptions,
                  {
                      data: this.series2,
                      name: '',
                      type: 'pie',
                      size: '70%',
                      innerSize: '60%',
                      dataLabels: {
                          formatter: function () {
                              // display only if larger than 1
                              return this.y > 2.5 ? '<b>' + this.point.name + ':</b> ' +
                                  this.y + '%' : null;
                          },
                          distance: 10
                      },
                      id: 'drilldown'
                  } as Highcharts.SeriesPieOptions,
              ],
              responsive: {
                  rules: [{
                      condition: {
                          maxWidth: 400
                      },
                      // chartOptions: {
                      //      series: [
                      //          {} as Highcharts.SeriesPieOptions,
                      //          {
                      //          id: 'drilldown',
                      //          dataLabels: {
                      //              enabled: false
                      //          }
                      //      } as Highcharts.SeriesPieOptions,
                      //      ]
                      //  }
                  }]
              }
          });
      }, 200);
  }
  
  getChart() {  
    return this.charts;
  } 
  
/*
  async presentAlert(text) {

      const alert = await this.alertCtrl.create({
          header: this.title,
          message: text,
          buttons: ['OK']
      });
      alert.present();
  }
*/

}
