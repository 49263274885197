import {Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss']
})
export class RangeComponent implements OnInit {

  @Output()
  response = new EventEmitter();

  @Input()
  options;
  @Input() domain;

  @Input()
  question: any;

  @Input()
  id: any;

  @Input()
  reverse = false;

  @Input()
  nonZeroIndex = false;

  public val: any;
  selected: any;

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.val = null;
  }

  select(i) {
      this.selected = i;
      if (this.nonZeroIndex) {
        this.val = [i+1, this.options[i]];
      } else {
        this.val = [i, this.options[i]];
      }
      this.response.emit(JSON.stringify({id: this.id, question: this.question, response: this.val, domain: this.domain}));
  }

  isSelected(i) {
    return (this.selected === i) ? 'check' : 'close';
  }

}
