import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {MatDialog} from '@angular/material';
import {FirebaseService} from '../../services/firebase.service';
import {ProvidersDialogComponent} from './providers.dialog/providers.dialog.component';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {DeleteDialogComponent} from '../../components/dialog/delete/delete.dialog.component';
import {CodeDialogComponent} from '../../components/dialog/code/code.dialog.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireFunctions} from '@angular/fire/functions';
import {ProviderService} from '../../services/provider.service';
import {HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Provider} from '../../models/provider';


@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit, OnDestroy {

  providers: any[];
  private dialogRef;
  private subscription: Subscription;
  private collName: string;

  constructor(private app: AppComponent, 
      private fireauth: AngularFireAuth,
      private dialog: MatDialog, 
      private fs: FirebaseService, 
      public auth: AuthService,  
      private providerService : ProviderService,
      private snackBar: MatSnackBar,
      private http: HttpClient,
      private fbfunc: AngularFireFunctions,
      public db: AngularFirestore) { }

  ngOnInit() {
    this.app.setTitle('Providers');
    this.getData();
  } 

  private getData(){ 
    if (this.auth.isAdmin()) {
      /* fetch all Providers */
      this.subscription = this.fs.getProviders(false).subscribe(providers => {
        providers.forEach(async provider => {
          let coll = await this.fs.getbyRef(provider.ref);
          console.log(coll);
          provider.name = coll.name;
          provider.logoUrl = coll.logoUrl;
        });      
        this.providers = providers;  
      }); 
    } 
  }

  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  addProvider() { 
      this.dialogRef = this.dialog.open(ProvidersDialogComponent, {
        height: 'auto',
        width: '60vw'
      });

      this.dialogRef.afterClosed().subscribe(async (result) => {
        console.log(result);
        if (result) {
          /* TODO: Add Provider to Firebase */ 
        }
      }); 
  }


  getlogoUrl(provider) {
    return (provider.logoUrl != null) ?
    provider.logoUrl : `https://ui-avatars.com/api/?size=130&font-size=0.4&color=3ea0bb&name=${provider.firstName}+${provider.lastName}`;
  }

  viewProvider(provider) {
    console.log(provider);
    // TODO open up whole provider screen.
  }

  edit(provider) {
    this.dialogRef = this.dialog.open(ProvidersDialogComponent, {
      height: 'auto',
      width: '60vw',
      data: provider
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      console.log(result);
      if (result) {
        const providerRef = await this.db.doc(result.providerId).get().toPromise();

        const providerUpdated = {name: provider.name};
        this.fs.updateById('providers', provider.uid, providerUpdated);
      }
    });
  }

  delete(provider) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      height: '260px',
      width: '340px',
      data: {collection: 'providers', obj: provider, msg: 'Are you sure you want to permanently remove this provider?'}
    });
  }

  code(provider) {
    this.dialogRef = this.dialog.open(CodeDialogComponent, {
      height: '260px',
      width: '340px',
      data: {collection: 'providers', obj: provider}
    });
  }

  
  async createCollection(){
    
    const srcProviderId = 'RW3DXGqTQ1YJo0ernNYn';
    const descProviderId = 'Mng3NkgL8f1fz39XOKsS';

    await this.providerService.copyProvider(srcProviderId, descProviderId); 
    /*
    await this.providerService.copyCollectionWithSameId(`providers/${srcProviderId}/ema_bank`, `providers/${descProviderId}/ema_bank`); 
    await this.providerService.copyContentDocument(`providers/${srcProviderId}/content`, `providers/${descProviderId}/content`); 
    await this.providerService.copyCollectionWithSameId(`providers/${srcProviderId}/activity_cat_bank`, `providers/${descProviderId}/activity_cat_bank`); 
    await this.providerService.copyACBContentDocument(
        srcProviderId, `providers/${srcProviderId}/activity_bank`, 
        descProviderId, `providers/${descProviderId}/activity_bank`); 
    */
    return;
  }

  async assessmentSmoking(){
    await this.providerService.assessmentSmoking();
  }

  async assessmentCognitive(){
    //await this.providerService.assessmentCognitive();
  }
};

