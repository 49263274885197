import {Injectable, OnDestroy} from '@angular/core'; 

import { Emotion } from '../models/emotion';
import {QuestionControls} from '../models/question_controls'; 
import {AngularFirestore, Query, AngularFirestoreCollection, DocumentReference} from '@angular/fire/firestore'; 

import {map} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class ControlsService {
	emotioncuts: { id: number, cut1: string, cut2: string, cut3: string, cut4: string, cut5: string}[] = [];
    private assessment: any;
    private controls: QuestionControls[] = [];
    private assessment_controls: QuestionControls[] = [];

    private controlIds: string [] = [];

    constructor(private db: AngularFirestore) {
		  this.emotioncuts = [
            { id: 10001, cut1: 'V Bad', cut2: 'Bad', cut3: 'OK', cut4: 'Good', cut5: 'V Good'},
            { id: 10002, cut1: 'V Poor', cut2: 'Poor', cut3: 'OK', cut4: 'Well', cut5: 'V Well'},
            { id: 10003, cut1: 'None', cut2: 'Minor', cut3: 'Some', cut4: 'Major', cut5: 'Extreme'},
            { id: 10004, cut1: 'None', cut2: 'Minor', cut3: 'Some', cut4: 'Major', cut5: 'Extreme'},
            { id: 10005, cut1: 'None', cut2: 'Minor', cut3: 'Some', cut4: 'Major', cut5: 'Extreme'},
            { id: 10006, cut1: 'None', cut2: 'Minor', cut3: 'Some', cut4: 'Major', cut5: 'Extreme'},
            { id: 10007, cut1: 'None', cut2: 'Minor', cut3: 'Some', cut4: 'Major', cut5: 'Extreme'},
            { id: 10008, cut1: 'None', cut2: 'Minor', cut3: 'Some', cut4: 'Major', cut5: 'Extreme'},
			];


    }

    async init(uid) {                    
        this.controlIds = [];     
        this.controls = []; 
        let collection = await this.db.collection(`clients/${uid}/emas`, ref=>ref) ;

        await collection.get().toPromise()
        .then(emas => {
            if(emas.docs && emas.docs.length > 0){    
                emas.docs.map(async doc => {
                    const data: any = await doc.data() ; 
                    this.setControls(data); 
                });
            } 
        }).catch(err=>{  
            console.log('ControlsService.err ===' + err); 
        });   
    }  

    /**
     * Returns the list of controls
     */
    query(isAssessment) {
        if (isAssessment) {
            return this.assessment_controls;
        } else {
            return this.controls;
        }
    }

    /**
     * Returns the emotion that has an id the same as the parameter.
     * @param id Emotion id
     */
    get(id: number) {
        for (const control of this.controls) {
            if (control.id == id) {
                return control;
            }
        }
    }


    /**
     * Returns the emotion cut set that has an id the same as the parameter.
     * @param id Emotion id
     */
    getcut(id: number) {
        for (const emotion of this.emotioncuts) {
            if (emotion.id == id) return emotion;
        }
    }

    /**
     * Returns a list of emotions that are the children of the parameter emotion.
     * @param emotion
     */
    getChildEmotions(emotion: Emotion) {
        return (<any[]>this.controls).filter((emotion2) => {
            for (const child of emotion.children) {
                if (emotion2.id == child.id) { return emotion2; }
            }
        });
    }

	getIntensityRating(id: number, intensity: number, range: number) {

		let cut1 = 0;
		let cut2 = 0;
		let cut3 = 0;
		let cut4 = 0;

		if (range == 10) {
			cut1 = 2;
			cut2 = 4;
			cut3 = 6;
			cut4 = 8;
		} else {
			cut1 = 20;
			cut2 = 40;
			cut3 = 60;
			cut4 = 80;
		}

		if (intensity < cut1) {
			return this.getcut(id).cut1;
		}

		if (intensity < cut2) {
			return this.getcut(id).cut2;
		}

		if (intensity < cut3) {
			return this.getcut(id).cut3;
		}

		if (intensity < cut4) {
			return this.getcut(id).cut4;
		}

		return this.getcut(id).cut5;

	}

    /**
     * Adds the emotion to the list
     * @param questionControls Emotion to be added
     */
    add(control: QuestionControls) {
        (<any[]>this.controls).push(control);
    }

    /**
     * Deletes the emotion from the list
     * @param emotion Emotion to be deleted
     */
    delete(control: QuestionControls) {
        (<any[]>this.controls).splice(this.controls.indexOf(control), 1);
    }


    setControls(ema) {
        this.assessment_controls = [];
        if (ema) {
            ema.pages.forEach((p) => {
                p.controls.forEach((c) => {
                    if(this.controlIds.includes(c.id)){
                        return;
                    }
                    this.controlIds.push(c.id);

                    this.controls.push(c);
                    if (c.positive) {
                        this.controls.push(c.positive);
                    }
                    if (c.negative) {
                        this.controls.push(c.negative);
                    }
                });
            });
            return this.controls;
        }
    }

    setAssessmentControls(ass) {
        this.controls = [];
        if (ass) {
            ass.object.pages.forEach((p) => {
                p.controls.forEach((c) => {
                    this.controls.push(c);
                    if (c.positive) {
                        this.controls.push(c.positive);
                    }
                    if (c.negative) {
                        this.controls.push(c.negative);
                    }
                });
            });
        }
    }
}
