import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {map} from 'rxjs/internal/operators';
import {Client} from '../models/client';
import {Assessment} from '../models/assessment';
import {AssessmentService, AssessmentStatus} from './assessment.service';
import {plainifyObj} from '../helpers/utils';
import {DomainService} from './domain.service';


@Injectable({
  providedIn: 'root'
})
export class ClientService implements OnDestroy {
  private sub: any;
  clients: Client[];

  constructor(private fs: FirebaseService, private db: AngularFirestore, private assessmentService: AssessmentService) {
  }

  async init(providerRef) {
    // this.domainsSub = this.db.collection('/domains').get().subscribe((r:any) => {
    //   this.domains = r.data();
    // });
    /*
    const collection = await this.db.collection('/clients', ref => ref.where('providers', 'array-contains', providerRef));
    const list = await collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data:any = a.payload.doc.data();
          data.ref = a.payload.doc.ref;
          return data as Client;
        });
      }));
    list.subscribe((clients) => {
      this.clients = clients;
      console.log(this.clients);
    });
    */ 
  }

  async create(obj): Promise<DocumentReference> {
    return await this.db.collection('/clients').add(obj);
  }

  ngOnDestroy(): void {
    if (this.sub != null) {
      setTimeout(() => {
        this.sub.unsubscribe();
      }, 1000);

    }
  }

  async getClientById(clientId: string) {  
    const temp = await this.db.doc('clients/' + clientId).get().toPromise();
    const data = temp.data() as Client;
    data.ref = temp.ref;  
    return data;
  }

  async getOutstandingClientAssessments(clientRef: any) {
    //const collection = this.db.collection(clientRef.path + '/assessments', ref => ref.where(AssessmentStatus.COMPLETE, '==', false));
    const collection = this.db.collection(clientRef.path + '/assessments', ref => ref.orderBy('created', 'desc'));
    const list = await collection.snapshotChanges().pipe(
      map(actions => {
        let  names : any[] = [];
        return actions.reduce((result, a) => {
          const data: any = a.payload.doc.data();
          data.ref = a.payload.doc.ref;
          //return data as Assessment; 
          if(!names.includes(data.name)){
            result.push(data); 
            names.push(data.name);
          }
          return result;
         }, []);
       }));
     return list;
  }

  async persistAssessmentForClient(client: Client, assessment: Assessment) {
    const reqSnap = await this.db.collection(client.ref.path + '/assessments', ref => ref.where('name', '==', assessment.name)
      .where('complete', '==', false)).get().toPromise();
    if (reqSnap.docs.length === 0) {
      return await this.db.collection(client.ref.path + '/assessments').add(plainifyObj(assessment));
    } else {
      return reqSnap.docs[0].ref;
    }
  }
}
