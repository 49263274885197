import {ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {AssessmentService} from './services/assessment.service';
import {ClientService} from './services/client.service';
import {DomainService} from './services/domain.service';
import {ProviderService} from './services/provider.service';
import {NotificationService} from './services/notification.service';
import {CloudService} from './services/cloud.service';
import { MatSidenav } from '@angular/material';
import * as Events from 'events';
import {filter} from 'rxjs/internal/operators';
import {User} from "./models/user";
import {AngularFirestore} from "@angular/fire/firestore";
import {environment} from "../environments/environment";
import {CognitoUserPool} from "amazon-cognito-identity-js";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private afa: AngularFireAuth,
              private db: AngularFirestore,
              public auth: AuthService,
              public router: Router,
              private assessmentService: AssessmentService,
              private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private domainService: DomainService,
              private providerService: ProviderService,
              private cloudService: CloudService,
              private changeDetect: ChangeDetectorRef,
              public notificationService: NotificationService) {


    this.router.events.subscribe( (n:any) => {
        if (n.navigationTrigger === 'popstate' && this.assessmentInProgress && this.userMode) {
          router.navigateByUrl(this.currentAssessmentUrl);
        } else if (n instanceof NavigationStart) {
          if (n.url.indexOf('/assessment/') > -1) {
            console.log('ASSESSMENT STARTED', n);
            this.assessmentInProgress = true;
            this.currentAssessmentUrl = n.url;
          } else {
            this.assessmentInProgress = false;
          }
        }
    });

  }
  provider;

  @Output()
  menuOpened = new EventEmitter();

  @ViewChild('sidenav', null) sidenav: MatSidenav;
  public sideNavShowing = true;
  private currentAssessmentUrl: string;
  userMode: boolean;

  user: firebase.User;
  loggedIn: boolean;
  title: string;
  avatar: string;
  roles: string[] = [];
  menuItems = [];
  selected: any;
  assessmentInProgress: boolean;
  public events: EventEmitter<string> = new EventEmitter<string>();

  opened = false;
  sideNavMode = 'side';
  loading = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log('event.target.innerWidth', event.target.innerWidth);
    if (event.target.innerWidth < 1025 && this.sidenav) {
      this.sidenav.close();
      this.sideNavMode = 'push';
      this.sideNavShowing = false;
    } else if (this.sidenav) {
      this.sidenav.open();
      this.sideNavMode = 'side';
      this.sideNavShowing = true;
    }
  }

  setSiveNav() {
    console.log('window.innerWidth', window.innerWidth);
    if (window.innerWidth < 1025 && this.sidenav) {
      this.sidenav.close();
      this.sideNavMode = 'push';
      this.sideNavShowing = false;
    }
  }t

   ngOnInit(): void {

     this.afa.auth.onAuthStateChanged(async x => {
         if (x) {
           this.auth.loggingIn = true;
           const awsUser = await this.auth.getCurrentAwsUser();
           if (awsUser) {
             this.db.doc('/users/' + x.uid).get().toPromise().then(async res => {
               const usr = res.data();
               if (usr) {
                 usr.ref = res.ref;
                 const providerSnap = await usr.providerId.get();
                 this.auth.provider = providerSnap.data();
                 this.auth.provider.ref = providerSnap.ref;
                 this.auth.intuiUser = usr as User;
                 
                 const profile = awsUser.signInUserSession.idToken.payload;
                 //console.log('awsUser', awsUser.signInUserSession.idToken.payload); 
                 //this.intuiUser.gender = user.gender;
                 this.auth.intuiUser.firstName = profile.given_name;
                 this.auth.intuiUser.lastName = profile.family_name;
                 this.auth.intuiUser.email =  profile.email;
                 
                 this.navTo('home');
               }
               this.auth.loggingIn = false;
             }).catch(e => {
               this.auth.loggingIn = false;
             });
           }
         } else {
           this.auth.loggingIn = false;
         }
     });

    this.auth.events.subscribe(next => {
      console.log('auth.events', next);
      if(next === 'loggedIn'){
        this.setMenu();
        this.setSiveNav();
      }
    })


     this.menuItems = [
       {url: '/clients', icon: 'home', label: 'Home'},
      // {url: '/clients', icon: 'face', label: 'Clients' },
       {url: '/reports', icon: 'assessments', label: 'Reports' },
       {url: '/settings', icon: 'settings_applications', label: 'Settings' },
     ];

     setTimeout(() => {
       this.setMenu();
     }, 1000);

  }

  navTo(url) {
    this.selected = url;
    this.router.navigateByUrl(url);
    if (window.innerWidth < 1025 && this.sidenav) {
      this.sidenav.close();
    }
  }

  toggle() {
    this.sidenav.toggle().then(r => {
       this.sideNavShowing = (r === 'close') ? false : true;
       this.menuOpened.emit(this.sideNavShowing);
    });
  }

  setTitle(title: string) {
     this.title = title;
  }

  logout() {
     this.provider = null;
     this.menuItems = [];
     this.auth.logout();
  }

  setMenu() {

    if (window.innerWidth < 1025 && this.sidenav) {
      this.sidenav.close();
      this.sideNavMode = 'push';
      this.sideNavShowing = false;
    } else if (this.sidenav) {
      this.sidenav.open();
      this.sideNavMode = 'side';
      this.sideNavShowing = true;
    }

    if (this.auth.intuiUser && (this.auth.intuiUser.roles.indexOf('admin') > -1  ||  this.auth.intuiUser.roles.indexOf('provider-admin') > -1)) {
      if (!this.auth.intuiUser.providerId || this.auth.intuiUser.providerId && this.auth.intuiUser.providerId === 'pending') {
        this.menuItems = [
          {url: '/home', icon: 'home', label: 'Home'},
          {url: '/settings', icon: 'settings_applications', label: 'Settings' },
        ];
      } else {
        this.auth.intuiUser.providerId.get().then(res => {
          this.provider = res.data();
          this.startServices();
        });
        this.menuItems = [
          {url: '/home', icon: 'home', label: 'Home'},
          {url: '/notifications', icon: 'notifications_none', label: 'Notifications'},
          {url: '/settings', icon: 'settings_applications', label: 'Settings'},
          {url: '/admin', icon: 'free_breakfast', label: 'Admin'},
        ];
      }
    } else {

      if (this.auth.intuiUser && this.auth.intuiUser.providerId) {
        this.auth.intuiUser.providerId.get().then(res => {
            this.provider = res.data();
            this.startServices();
          });
      } else {
        this.auth.logout();

      }

      this.menuItems = [
        {url: '/home', icon: 'home', label: 'Home'},
        {url: '/settings', icon: 'settings_applications', label: 'Settings' },
      ];
    }

    this.changeDetect.detectChanges();
    this.loading = false;
  }



  private async validateUser() {

    if (this.activatedRoute.snapshot.params.code) {
      console.log('code register...');
    } else {

      const u = await this.auth.getCurrentUser();
        if (u) {
          this.avatar = u.photoURL;
          this.auth.getIntuiUser(u.uid).then(async user => {
            this.auth.validateRole(this.auth.awsAccessToken.payload).then(res => {
              if (res) {
                this.loggedIn = true;
                this.setMenu();
                this.events.emit('loggedIn');
                this.router.navigateByUrl('home');
                this.auth.loggedOut = false;
              }
            });
          });

        } else {
          this.router.navigateByUrl('/login');
        }
    }
  }

  public startServices() {
    console.log('staring services');
    this.clientService.init(this.auth.intuiUser.providerId);
    this.assessmentService.init(this);
    this.providerService.init();
    this.notificationService.init();
    this.domainService.init();
    this.cloudService.init();
  }
}
