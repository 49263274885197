import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Domain} from '../../models/domain';
import {AssessmentService} from '../../services/assessment.service';
import * as Highcharts from 'highcharts';
import {ScoringService} from '../../services/scoring.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {
  charts = [];
  loaded: boolean;

  constructor(private assessmentService: AssessmentService, private scoringService: ScoringService) { }

  domains: Domain[];
  private assessments: firebase.firestore.QueryDocumentSnapshot[];

  @Input() client: any;

  ngOnInit() {
    this.loaded = false;
    this.getData();
  }

  async getData() {
    this.domains = await this.assessmentService.domains.filter((x) => {
      return (x.name !== 'Screening' && x.name !== 'Smoking' && x.name !== 'Cognitive');
    });

    for (const obj of this.domains) {
       const domain: Domain = obj as Domain;
       const assList = await this.assessmentService.getLast5AssessmentsForClientDomain(this.client.ref, domain.ref);
       const seriesData = [];
       const assData = [];
       const assData2 = [];
       for (const ass of assList) {
         const date = ass.data().created.toDate();
         if (obj.name === 'Quality of Life') {
           assData.push([date.toLocaleString(), this.scoringService.calculate(ass.data())[0]]);
           assData2.push([date.toLocaleString(), this.scoringService.calculate(ass.data())[1]]);
         } else {
           assData.push([date.toLocaleString(), this.scoringService.calculate(ass.data())]);
         }
       }

       if (obj.name === 'Quality of Life') {
         seriesData.push({name: 'PCS', data: assData});

         if (assData2.length > 0) {
           seriesData.push({name: 'MCS', data: assData2});
         }
       } else {
         seriesData.push({name: obj.name + ' score', data: assData});
       }

       this.charts[domain.ref.id] = new Chart({
         global: {
           useUTC: false
         },
          chart: {
            type: 'spline',
            backgroundColor: '#f0eff7',
          }, 
          title: {
            text: domain.name
          },
          credits: {
            enabled: false
          },
           yAxis: {
             title: {
               text: 'Score'
             }
           },
           xAxis: {
             type: 'category',
             title: {
               text: 'Time'
             }
           },
         plotOptions: {
           series: {
             label: {
               connectorAllowed: false
             }
           }
         },
         series: seriesData,
         responsive: {
           rules: [{
             condition: {
               maxWidth: 500
             },
             chartOptions: {
               plotOptions: {
                 series: {
                   marker: {
                     radius: 2.5
                   }
                 }
               }
             }
           }]
         }
       } as Highcharts.Options);

    }
    this.loaded = true;
  }

  getChart(domain) {
    return this.charts[domain.ref.id];
  }
}
