import {EventEmitter, Injectable, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/firestore';   // for cloud firestore
import 'firebase/functions';   // for cloud functions

import {map} from 'rxjs/internal/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {User} from '../models/user';
import {FirebaseService} from './firebase.service';
import {MatSnackBar} from '@angular/material';
import {AngularFireFunctions} from '@angular/fire/functions';
import {environment} from '../../environments/environment';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoAccessToken
} from 'amazon-cognito-identity-js';
import * as Events from 'events';
import Amplify, { Auth } from 'aws-amplify';
import GetOptions = firebase.firestore.GetOptions;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  get provider(): any {
    return this._provider;
  }

  set provider(value: any) {
    this._provider = value;
  }
  private _provider: any;
  private validationAttempts = 0;
   loggedOut: boolean = false;

  set intuiUser(value: User) {
    this._intuiUser = value;
  }
  get intuiUser(): User {
    return this._intuiUser;
  }

  private subscription: Subscription;
  awsAccessToken: CognitoAccessToken;
  user: firebase.User;
  private _intuiUser: User;

  loggingIn: boolean = false;
  awsAuth: CognitoUserPool;
  private awsCognito: CognitoUser;
  fireBaseCred: firebase.auth.UserCredential | void;
  public events: EventEmitter<string> = new EventEmitter<string>();

  constructor(private fireauth: AngularFireAuth,
              private db: AngularFirestore,
              private fb: FirebaseService,
              private fbfunc: AngularFireFunctions,
              private http: HttpClient,
              private snackBar: MatSnackBar,
              //public events: EventEmitter<string>,
              private router: Router) {
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
    });
  }

  loginGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.fireauth.auth.signInWithPopup(provider).then(r => {
      return this.user = r.user;
    }).catch(e => {
      console.error(e);
      return e;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async loginAccount(email, password) {
    this.loggingIn = true;
    //AWS nonsense!
    const authData = environment.awsConfig;
    this.awsAuth = new CognitoUserPool(authData);
    const authenticationData = {
      Username: email,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: this.awsAuth
    };
    const cognitoUser = new CognitoUser(userData);


    await cognitoUser.authenticateUser(authenticationDetails,{
      onSuccess: async (r) => {
          // User authentication was successful
          this.awsAccessToken = r.getAccessToken();
          const jwtToken = await r.getIdToken();
          this.validateUser(this.awsAccessToken.payload.sub, await r.getIdToken().getJwtToken(), true).then( u => {
            if (u != null) {
               this.validateRole(jwtToken.payload).then(r => {
                if (r != null) {
                  this.events.emit('loggedIn');
                  this.router.navigateByUrl('home');
                  this.loggedOut = false;
                }
              });
            } else {
              this.snackBar.open('Invalid user', null, {
                duration: 5000,
              });
            }
            this.loggingIn = false;
          });
      },
      onFailure: async (err) => {
        this.loggingIn = false;
        await this.snackBar.open(err.message, null, {
            duration: 5000,
        });
      }
    });
  }

  logout() {
    try {
      if(this.intuiUser != null && this.intuiUser.ref != null){
        this.intuiUser.ref.set({push_token: null}, {merge: true});
      }
      if(this.awsAuth != null && this.awsAuth.getCurrentUser() != null){
        this.awsAuth.getCurrentUser().signOut();
      }
    } catch(e) {
      console.error(e);
    }
    this._intuiUser = null;
    this.awsAccessToken = null;
    this.fireauth.auth.signOut();
    this.loggedOut = true;
    this.router.navigateByUrl('');
  }

  async addUser(user, uuid) {

    const mindtickProvider = await this.db.doc(environment.awsApis.single_provider);
    const dbUser = new User(false,
      mindtickProvider.ref.path,
      uuid);

    return await this.fbfunc.httpsCallable('complete_user')({user: dbUser}).toPromise().then(r => {
      return dbUser;
    });
  }

  private async finishReg(payload: any) {
    return this.fbfunc.httpsCallable('complete_user')(payload).subscribe(r => {
      this.user = payload.user;
      this.router.navigateByUrl('login');
      return this.user;
    });
  }


   async register(registerObj) {
    console.log('registerObj', registerObj);

    //AWS nonsense!
    const authData = environment.awsConfig;
    this.awsAuth = new CognitoUserPool(authData);

    const attributeEmail = new CognitoUserAttribute(
      {Name: 'email', Value: registerObj.email.toLowerCase()});
    //const attributePhoneNumber = new CognitoUserAttribute(
    //  {Name: 'phone_number', Value: registerObj.phone});
    const attributePhoneNumber = new CognitoUserAttribute(
      {Name: 'phone_number', Value: ''});
    const attributeName = new CognitoUserAttribute(
      {Name: 'name', Value: `${registerObj.firstName} ${registerObj.lastName}`});
    const attributeFirstName = new CognitoUserAttribute(
      {Name: 'given_name', Value: registerObj.firstName});
    const attributeLastName = new CognitoUserAttribute({Name: 'family_name', Value: registerObj.lastName});
    //const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: registerObj.gender});
    //const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: registerObj.dob});
    const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: 'male'});
    const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: '2001-05-21'});

    await this.awsAuth.signUp(registerObj.email, registerObj.pass, [attributeEmail, attributeBirthdate, attributeGender,
                                          attributeFirstName, attributeLastName, attributePhoneNumber], null,
      async (err, r: any) => {
        if (err) {
          console.error(err);
          this.snackBar.open(err.message, null, {
            duration: 5000,
          });
        } else {
          const amplifyData = environment.amplifyConfig;
          Auth.configure(amplifyData);
          await Auth.signIn(registerObj.email, registerObj.pass);
          const token:any = await Auth.currentSession();

          this.http.post(environment.awsApis.aws_auth_validator, {uid: r.userSub}, {headers: {Authorization: 'Bearer ' + token.idToken.jwtToken}}).toPromise().then(async (t: any) => {
              await this.fireauth.auth.signInWithCustomToken(t.token);
              this.addUser(registerObj, r.userSub).then(async (usr) => {
                this.router.navigateByUrl('register_success');
                return usr;
              }).catch(async e => {
                this.snackBar.open(err.message, null, {
                  duration: 5000,
                });
                return e;
              });
          }, (err) => {
              console.error(err);
          });
        }
      });
  }

  registerSocial(type) {
    switch (type) {
      case 'google' :
        return this.loginGoogle().then(res => {
          if (res) {
            this.addUser(res, res.uid);
          }
        });
        break;
    }

  }

  getCurrentUser() {
    return this.fireauth.auth.currentUser;
  }

  async getCurrentAwsUser() {

    if (!this.awsAuth) {
      const authData = environment.awsConfig;
      const amplifyData = environment.amplifyConfig;
      this.awsAuth = new CognitoUserPool(authData);
      Auth.configure(amplifyData);
    }
    const user = this.awsAuth.getCurrentUser() as any;

    if (user) {
      await user.getSession((s) => {});

      const token: any = await Auth.currentSession();
      this.awsAccessToken = token.accessToken;

      //this.getProfile(user);
      return user;
    } else {
      return;
    }


  }

  getTrendConfig() {
    return this._provider.trend_config;
  }

  getProviderName() {
    return this._provider.name;
  }

  getProviderLogo() {
    return this._provider.logoUrl;
  }

  async validateRole(user) {

    if (user) {

      const snap = await this.db.collection('users', ref => ref.where('uid', '==', user.sub)).get().toPromise();

      if (snap.docs.length > 0) {
        const doc = snap.docs[0].data();
        if ( doc.roles.indexOf('pending') > -1 ) {
          console.log('doc', 'pending');
          this.router.navigateByUrl('/login');
          this.snackBar.open('Your account is pending approval. Please try again after your account is activated.', null, {duration: 5000});
          this.intuiUser = null;
          return null;
        }
        if ( doc.roles.indexOf('admin') > -1 || doc.roles.indexOf('provider') > -1 || doc.roles.indexOf('provider-admin') > -1) {

          this.intuiUser = doc as User;
          this.intuiUser.ref =  snap.docs[0].ref;
          //this.intuiUser.gender = user.gender;
          this.intuiUser.firstName = user.given_name;
          this.intuiUser.lastName = user.family_name;
          this.intuiUser.email =  user.email;
          return this.intuiUser;
        } else {
          this.router.navigateByUrl('/login');
          this.intuiUser = null;
          return null;
        }
      } else  {
        this.router.navigateByUrl('/login');
        this.intuiUser = null;
        return null;
      }
    } else {
      this.router.navigateByUrl('/login');
      this.intuiUser = null;
      return null;
    }
  }
  /*
  async validateUser(user) {
     user = await this.db.collection('/users', ref => ref.where('email', '==', user.email)).get().toPromise().then(res => {
       return res.docs[0];
     });
  }
  */

  // async validateUser(uid, token) {
  //   const payload = {uid: uid};
  //   return await this.http.post(environment.awsApis.aws_auth_validator, payload,
  //       {headers: {Authorization: 'Bearer ' + token, 'Content-Type': 'application/json'}}
  //   ).toPromise().then(async (t: any) => {
  //     return await this.db.doc('/users/' + uid).get().toPromise().then(async res => {
  //         const u = res.data() as User;
  //         if(res.exists && !u.deleted){
  //           this.fireBaseCred = await this.fireauth.auth.signInWithCustomToken(t.token);
  //           u.ref = res.ref;
  //           const providerSnap = await u.providerId.get();
  //           this.provider = providerSnap.data();
  //           return u;
  //         }else{
  //           return null;
  //         }
  //     });
  //   });
  // }

   async validateUser(uid, token, isLogin = false) {

      if (isLogin) {
        return this.getNewFirebaseToken(uid, token);
      } else {
        const user = firebase.auth().currentUser;
        if (!user && this.loggedOut) {
          return null;
        }
        if (user && !user.email) {
          // User is signed in.
          console.log(user);
          try {
            const ic = await this.getIntuiUser(uid);
            return ic;
            this.getNewFirebaseToken(uid, token);
          } catch (e) {
            return this.getNewFirebaseToken(uid, token);
          }
        } else if (!isLogin) {
          return await this.getNewFirebaseToken(uid, token);
        }
      }
  }

   async getIntuiUser(uid, source: GetOptions = {source: 'server'}) {
    if(this.intuiUser == null) {
      return this.db.doc('/users/' + uid).get(source).toPromise().then(async res => {
        const client = res.data();
        if (client) {
          client.ref = res.ref;
          const providerSnap = await client.providerId.get();
          this._provider = providerSnap.data();
          this._provider.ref = providerSnap.ref;
          this.intuiUser = client as User;

          return this.intuiUser;
        } else {
          //SOMETHING ISNT RIGHT.. GET THEM TO LOG OUT
          this.logout();
        }
      }, (e) => {
        console.error(e);
        // retry
        if (this.validationAttempts < 3) {
          console.log('failed to validate retrying...');
          this.validationAttempts++;
          this.getIntuiUser(uid);
        } else {
          console.error(e);
          throw e;
        }
      });
    } else {
      return this.intuiUser;
    }
  }

  private async getNewFirebaseToken(uid, token): Promise<any> {
    const payload = {uid: uid};

    return this.http.post(environment.awsApis.aws_auth_validator, payload,
      {headers: {Authorization: 'Bearer ' + token, 'Content-Type': 'application/json'}}
    ).toPromise().then(async (t: any) => {
      try {
        this.fireBaseCred = await this.fireauth.auth.signInWithCustomToken(t.token).catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          if (errorCode === 'auth/invalid-custom-token') {
            //alert('The token you provided is not valid.');
            console.warn('The token AWS provided is not valid.', t);
          } else {
            console.error(error);
          }
        });
        return this.getIntuiUser(uid);

      } catch (err) {
        console.error(err);
        return null;
      }
    });

  }

  isAdmin() {
    return (this._intuiUser.roles.indexOf('admin') > -1);
  }

  isProviderAdmin() {
    return (this._intuiUser.roles.indexOf('provider-admin') > -1);
  }

  isProviderUser() {
    return (this._intuiUser.roles.indexOf('provider') > -1);
  }

  isPending() {
    return (this._intuiUser.roles.indexOf('pending') > -1);
  }


  getProfile(user) {

    new Promise((resolve, reject) => {

        user.getSession((s) => {});
        user.getUserAttributes(function (err, attributes) {
            if (err) {
                // Handle error
            } else {
                // Do something with attributes
                resolve(attributes);
            }
        });

    }).then(r => {
        const awsAttributes : any = r;
        let profile = [];
        for (const item of awsAttributes) {
            profile[item.Name] = item.Value;
        }
        console.log(profile);
    });

  }

  async updateProfile(profile) {

    const usr = this.awsAuth.getCurrentUser() as any;

    return new Promise((resolve, reject) => {

        const attributeEmail = new CognitoUserAttribute(
            {Name: 'email', Value: profile.email});
        const attributePhoneNumber = new CognitoUserAttribute(
            {Name: 'phone_number', Value: ''});
        const attributeName = new CognitoUserAttribute(
            {Name: 'name', Value: `${profile.given_name} ${profile.family_name}`});
        const attributeFirstName = new CognitoUserAttribute(
            {Name: 'given_name', Value: profile.given_name});
        const attributeLastName = new CognitoUserAttribute({Name: 'family_name', Value: profile.family_name});
        const attributeGender = new CognitoUserAttribute({Name: 'gender', Value: profile.gender});
        const attributeBirthdate = new CognitoUserAttribute({Name: 'birthdate', Value: profile.birthdate.split('T')[0]});

        usr.getSession((s) => {
            console.log(s);
        });
        usr.updateAttributes([attributeName, attributeFirstName, attributeLastName, attributeEmail, attributeBirthdate, attributeGender
            //  attributeFirstName, attributeLastName, attributePhoneNumber, attributeName
        ] as CognitoUserAttribute[], (err, r) => {
            if (err) {
                reject(err);
            }
            console.log(r);
            resolve(r);
        });
    }).then(r => {
        this.getProfile(usr);
    });
  }

  async forgotPassword(username: string) {

      Amplify.configure(environment.amplifyConfig);
      return Auth.forgotPassword(username);
  }

  async validateCode(username, code, new_password) {

      Amplify.configure(environment.amplifyConfig);
      return Auth.forgotPasswordSubmit(username, code, new_password);
  }

  async updatePassword(current: string, password: string) {
      //TODO hit cognito for pw reset...

      return new Promise((resolve, reject) => {

          const usr = this.awsAuth.getCurrentUser() as any;
          usr.getSession((s) => {
              console.log(s);
          });

          usr.changePassword(current, password, (e, r) => {
              if (e) {
                  reject(e);
              } else {
                  resolve(r);
              }
          });
      });
  }

}

