import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Domain} from '../models/domain';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {map} from 'rxjs/internal/operators';
import {Assessment} from '../models/assessment';
import {Runtime} from 'inspector';
import Timestamp = firestore.Timestamp;
import {firestore} from 'firebase';
import {AppComponent} from '../app.component';
import {compare} from '../helpers/utils';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AssessmentService implements OnDestroy {
  domainsRefList: any[];
  domains: any[];
  screeningQuestions: any[];
  provider;



  constructor(private fs: FirebaseService, private db: AngularFirestore, private auth: AuthService) {
  }

  async init(app) {

    if (this.auth.intuiUser != null) {
      this.auth.intuiUser.providerId.get().then(async res => {
        this.provider = await res.data();

        this.domainsRefList = this.provider.domains.sort((a, b) => {
          return compare(a.name, b.name);
        });

        if (this.domainsRefList) {
          const domainPromises = [];
          this.domainsRefList.map((domainRef) => {
            domainPromises.push(
              domainRef.get().then(temp => {
                const dom = temp.data() as Domain;
                dom.ref = temp.ref;
                return dom;
              })
            );
          });

          await Promise.all(domainPromises).then(result => {
            this.domains = result;
          });

          if (this.screeningQuestions == null) {
            this.screeningQuestions = [];
            for (const x of this.domains.sort((a, b) => a.order - b.order)) {
              const screening = await this.db.collection(x.ref.path + '/screening').get().toPromise();
              if (screening.docs.length > 0) {
                this.screeningQuestions.push({domain: x, controls: screening.docs[0].data().controls});
              }
            }
            console.log('screenr', this.screeningQuestions);
          }

        }
      });
    }
  }

  ngOnDestroy(): void {
  }

  sendResults() {

  }

  getResults() {

  }

  async getAssessmentCompleted(clientRef: DocumentReference, assessment: Assessment) {
    const snap = await this.db.collection(clientRef.path + '/assessments', ref => ref.where('name', '==', assessment.name)
      .where('complete', '==', true).orderBy('created', 'desc').limit(1)).get().toPromise();
    if (snap.docs.length > 0) {
      snap.docs[0].data() ;
      const data = snap.docs[0].data() ;
      assessment.complete = data.complete;
      assessment.created = data.created;
    }
    return assessment;
  }

  async getScreeningAssessment(clientRef: DocumentReference) {
    const x = this.domains.find(x => x.isScreening === true);
    //return new Assessment(x.ref, x.name, x.icon, Timestamp.fromDate(new Date(Date.now())), false);
    if (x) {
      let assessment = new Assessment(x.ref, x.name, x.icon, Timestamp.fromDate(new Date(Date.now())), false);
      return await this.getAssessmentCompleted(clientRef, assessment);
    } else  {
      return null;
    }
  }

  async getMandatoryAssessments(clientRef: DocumentReference){
    let assessments : Assessment[] = [];
    const x2 = this.domains.find(x => x.name === 'Quality of Life');
    if (x2) {
      let assessment = new Assessment(x2.ref, x2.name, x2.icon, Timestamp.fromDate(new Date(Date.now())), false);
      assessments.push(await this.getAssessmentCompleted(clientRef, assessment));
    }
    const x3 = this.domains.find(x => x.name === 'Nutrition');
    if (x3) {
      let assessment = new Assessment(x3.ref, x3.name, x3.icon, Timestamp.fromDate(new Date(Date.now())), false);
      assessments.push(await this.getAssessmentCompleted(clientRef, assessment));
    }
    return assessments;
  }

  async getLastAssessmentForClientByDomainRef(clientRef: DocumentReference, dRef: DocumentReference) {
    const snap = await this.db.collection(`${clientRef.path}/assessments`, ref => ref.orderBy('created')).get().toPromise();
    if (snap.docs.length > 0) {
      return snap.docs[0].data() as Assessment;
    } else {
      return null;
    }
  }


  async getLastEmasForClient(clientRef){
    const snap = await this.db.collection(`${clientRef.path}/entries`, ref => ref.orderBy('created', 'desc').limit(1)).get().toPromise();

    if(snap.docs.length == 0){
      return [];
    }
    const response = snap.docs[0].data();

    //1. getEma info
    /*
    const emaDoc = await this.db.doc(response.emaRef.path).get().toPromise();
    const ema = emaDoc.data();
    let questionMap = new Map<string, any>();
    ema.pages.forEach((page: any) =>  {
      page.controls.forEach(async (control: any) => {
        questionMap.set(control.id, control.domainRef);
      });
    });
    */
    let questionMap = new Map<string, any>();
    response.questions.forEach((question: any) =>  {
      questionMap.set(question.id, question.domainRef);
    });

    //2. get Domain info
    response.responses.forEach((res: any) =>  {
      res.domain = questionMap.get(res.id);
    });

    return response;
  }

  async getLastAssessmentsForClient(clientRef) {
    const snap = await this.db.collection(`${clientRef.path}/assessments`,
      ref => ref.orderBy('created', 'desc').where('complete', '==', true)).get().toPromise();
    return snap.docs;
  }

  async getLast5AssessmentsForClientDomain(clientRef, domainRef) {
    const snap = await this.db.collection(`${clientRef.path}/assessments`,
        ref => ref.where('domain', '==', domainRef).orderBy('created')).get().toPromise();
    const temp = snap.docs.slice(0, 5);
    return temp;
  }
}

export class AssessmentStatus {
  public static COMPLETE = 'complete';
  public static PENDING = 'pending';
}
