import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseService} from '../../services/firebase.service';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {
  private code: any;
  private accessCode: any;

  constructor(private actRouter: ActivatedRoute, private db: AngularFirestore, private router: Router) { }

  ngOnInit() {
    this.code = this.actRouter.snapshot.params.code;
    if (this.code) {
      this.fetchCode(this.code);
    }

  }

  private async fetchCode(code: any) {
    const objSnap = await this.db.collection('access_codes', ref => ref.where('code', '==', code)).get().toPromise();
    this.accessCode = objSnap.docs[0].data();
    console.log(this.accessCode);
    this.router.navigateByUrl('code_register', {queryParams: {data: this.accessCode}} );
  }

}
