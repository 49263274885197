import { Injectable } from '@angular/core'; 
import {AngularFireFunctions} from '@angular/fire/functions';
import {HttpClientModule, HttpClientJsonpModule, HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudService { 

  constructor( 
    private fbfunc: AngularFireFunctions,
    private http: HttpClient) {
  }
 
  async init() {
    this.getDummyHghchartImage(); 
  }
 
  /**
   * Make this clold function hot
   */
  async getDummyHghchartImage() {
    var data = {};   
    const res = await this.http.post(environment.highchart.export_url, data).toPromise()  
    .then((res : any) => {     
      console.log('CloudService.getDummyHghchartImage', res.data);     
    }).catch(err=>{  
      console.log('CloudService.getDummyHghchartImage.err', JSON.stringify(err)); 
    }); 
  } 

  
  async getBase64ChartImages(charts){
       
    let urls: any[] = [];
    for (let [key, value] of charts) {
      //console.log(key, value); 

      const params = {
        options: JSON.stringify(value.options) 
      };
  
      const url = this.http.post(environment.highchart.export_url, params).toPromise()  
      //const url = this.fbfunc.httpsCallable(environment.highchart.export_url)(params).toPromise()  
      .then((res : any) => {     
        return res.data;     
      }).catch(err=>{  
        console.log('exportPdf.err ===' + JSON.stringify(err)); 
        return null;
      });  
      urls.push(url);
    }

    return await Promise.all(urls).then(function (base64Imgs: any []) {
      console.log('Promise.all:', base64Imgs);
      return base64Imgs;
    })
    .catch(function (error:any) {
      console.log('Promise.all Error:', JSON.stringify(error));
      return null;
    }); 
  }
  
}
