import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../../services/firebase.service';
import {firestore} from 'firebase/app';
import * as moment from 'moment';
import Timestamp = firestore.Timestamp;
import {ProviderService} from '../../../services/provider.service';
import {Provider} from '../../../models/provider';
import {DocumentReference} from '@angular/fire/firestore';

export interface IObject {
  isAdmin: boolean;
  userObj: any;
  providerId: any;
}

export interface UsersDialogData {
  firstName: string;
  lastName: string;
  email: string;
  created: Date;
  phone: string;
  roles: any;
  providerName: string;
  providerId: DocumentReference;
}

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users.dialog.component.html',
  styleUrls: ['./users.dialog.component.scss']
})
export class UsersDialogComponent implements OnInit, OnDestroy {
 
  disabled = false;
  userGroup: FormGroup;
  title = 'Add New User';

  firstName : any;
  lastName : any;
  email : any;
  phone : any;
  roles : any[];
  providerName : any;
  providerId : any;
  providers: Provider[];

  constructor(
    public dialogRef: MatDialogRef<UsersDialogComponent>,
    private providerService : ProviderService,
    @Inject(MAT_DIALOG_DATA) public data: IObject) {

      if (this.data.userObj) {
        // If data is present, this is an 'EDIT'
        this.disabled = true;
      }
      // Establish Form Controls - disabled if data passed in and read-only
      this.firstName = new FormControl({value:'', disabled: this.disabled}, [Validators.required]);
      this.lastName = new FormControl({value:'', disabled: this.disabled}, [Validators.required]);
      this.email = new FormControl({value:'', disabled: this.disabled}, [Validators.required]);
      this.phone = new FormControl({value:'', disabled: false}, [Validators.required]);
      this.roles = [new FormControl({value:'', disabled: false}, [Validators.required])];
      this.providerName = new FormControl({value:'', disabled: false}, [Validators.required]);

      if (this.data.userObj) {
        console.log('Data',this.data.userObj);
  
        this.title =  'Edit User';
        this.firstName.setValue(data.userObj.firstName);
        this.lastName.setValue(data.userObj.lastName);
        this.phone.setValue(data.userObj.phoneNumber);
        this.email.setValue(data.userObj.email);
        this.roles[0].setValue(data.userObj.roles);
        this.providerName.setValue(data.userObj.providerName);
  
        this.providerId = data.providerId;
      }

    }

  getFirstNameErrorMessage() {
    return this.firstName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getLastNameErrorMessage() {
    return this.lastName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      '';
  }

  getPhoneErrorMessage() {
    return this.phone.hasError('required') ? 'You must enter a value' :
      '';
  }

  getRolesErrorMessage() {
    return this.roles[0].hasError('required') ? 'You must choose a value' :
      '';
  }

  getProviderErrorMessage() {
    return this.providerName.hasError('required') ? 'You must choose a value' :
      '';
  }

  ngOnInit(): void {
    if (this.data.isAdmin) {
      //Admin can select from all PROVIDERS
      this.providers = this.providerService.get();
    } else {
      //Provider-Admin only has their associated PROVIDER
      this.providers = this.providerService.get().filter(x => x.ref.path === this.data.providerId.path)
    }

    console.log('Providers',this.providers);

    this.userGroup = new FormGroup({
      firstname: this.firstName,
      lastname: this.lastName,
      email: this.email,
      roles: this.roles[0],
      phone: this.phone,
      providerName: this.providerName,
    });
  }

  ngOnDestroy(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    if (!this.firstName.invalid && !this.lastName.invalid && !this.email.invalid
      && !this.phone.invalid) { 

        const payload = {
          firstName: this.firstName.value, lastName: this.lastName.value,
          email: this.email.value, created: new Date(Date.now()),
          pass: '',
          phone: '+61' + this.phone.value,
          providerId: this.providerName.value,
          roles: this.roles[0].value,
          invite: true,
          avatarUrl: null, 
          deleted: false
        };      
        if (this.data.userObj === null) {
          // Only generate a PASSWORD if this is an ADD
          payload.pass = this.genCode('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 1) + this.genCode('abcdefghijklmnopqrstuvwxyz', 8) + this.genCode('0123456789', 1);
        }
  
        console.log('payload', payload);
        this.dialogRef.close(payload);
    } else {
      this.userGroup.markAllAsTouched();
    }
  }


  genCode(characters, length) { 
    let result           = ''; 
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
