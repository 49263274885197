import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/internal/operators';
import {Client} from '../models/client';
import {Assessment} from '../models/assessment';
import {AssessmentStatus} from './assessment.service';
import {Observable} from 'rxjs';
import {Domain} from '../models/domain';
import {Provider} from '../models/provider';


@Injectable({
  providedIn: 'root'
})
export class ProviderService implements OnDestroy {
  private sub: any;
  providers: any[];

  constructor(private fs: FirebaseService, private db: AngularFirestore) {
  }

  async init() {
    // this.domainsSub = this.db.collection('/domains').get().subscribe((r:any) => {
    //   this.domains = r.data();
    // });

    if (this.sub != null) { 
      this.sub.unsubscribe(); 
    }

    const collection = await this.db.collection('/providers');
    const list = await collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data: any = a.payload.doc.data();
          data.ref = a.payload.doc.ref;
          return data as Provider;
        });
      }));
    this.sub =list.subscribe((providers) => {
      this.providers = providers;
      console.log(this.providers);
    });

  }

  get() {
    return this.providers.filter(x => x.active);
  }

  ngOnDestroy(): void {
    if (this.sub != null) {
      setTimeout(() => {
        this.sub.unsubscribe();
      }, 1000);

    }
  }


  

  async copyProvider(srcProviderId, destProviderId) {
    console.log(srcProviderId, destProviderId);
    const documentsSnap = await this.db.collection('providers').doc(srcProviderId).get().toPromise();
    console.log('bank 1');  
    const doc = documentsSnap.data(); 
    delete doc['mrt_config'];
    await this.db.collection('providers').doc(destProviderId).set(doc,{merge:true});  
    console.log('batch 2');  
  }

  async copyCollection(srcCollectionName, destCollectionName) {
    console.log(srcCollectionName, destCollectionName);
    const documentsSnap = await this.db.collection(srcCollectionName ).get().toPromise();
    console.log('bank 1');
    let writeBatch = this.db.firestore.batch();
    
    const destCollectionSnap = await this.db.collection(destCollectionName ).get().toPromise();
    console.log('bank 2');
    let i = 0;
    for (const doc of documentsSnap.docs) {
      const data = doc.data(); 
      this.db.collection(destCollectionName).add(data); 
      i++; 
    }
    if (i > 0) {
        console.log('Firebase batch operation completed. Doing final committing of batch operation.');
    } else {
        console.log('Firebase batch operation completed.');
    }
  }

  async copyCollectionWithSameId(srcCollectionName, destCollectionName) {
    console.log('batch 0');
    const documentsSnap = await this.db.collection(srcCollectionName ).get().toPromise();
    console.log('batch 1');  
    const destCollectionSnap = await this.db.collection(destCollectionName ).get().toPromise();
    console.log('batch 2');
    let i = 0;
    for (const doc of documentsSnap.docs) {
      const data = doc.data(); 
      console.log('batch 3', doc.ref.id);
      this.db.collection(destCollectionName).doc(doc.ref.id).set(data);  
      i++; 
    }
    if (i > 0) {
        console.log('Firebase batch operation completed. Doing final committing of batch operation.');
    } else {
        console.log('Firebase batch operation completed.');
    }
  }
  
  async copyContentDocument(srcDocumentName, destDocumentName) {
    console.log('batch 0');
    const docSnap = await this.db.doc(srcDocumentName + '/drupal').get().toPromise();
    const data = docSnap.data(); 
    console.log('batch 1', docSnap.ref.id); 
    
    await this.db.collection(destDocumentName).doc(docSnap.ref.id).set(data);  
    console.log('batch 2'); 

    await this.db.collection(destDocumentName).doc(docSnap.ref.id).collection("articles").add({});  
    console.log('batch 3'); 

    this.copyCollection(srcDocumentName +  '/' +  docSnap.ref.id + '/articles', 
                    destDocumentName + '/' + docSnap.ref.id  + '/articles');
  }

 
  async copyACBContentDocument(srcProviderId, srcCollectionName, destProviderId, destCollectionName) {
    console.log(srcCollectionName, destCollectionName);
    const documentsSnap = await this.db.collection(srcCollectionName ).get().toPromise();
    console.log('bank 1'); 
    const destCollectionSnap = await this.db.collection(destCollectionName ).get().toPromise();
    console.log('bank 2');
    let i = 0;
    for (const doc of documentsSnap.docs) {
      const data = doc.data(); 
       
      let replaced : any [] = [];
      for (const cate of data.categories) {
        const catRefPath = cate.path;
        const catSnap = await this.db.doc(catRefPath.replace(srcProviderId, destProviderId)).get().toPromise(); 
        replaced.push(catSnap.ref);
      }
      data.categories = replaced;
      this.db.collection(destCollectionName).add(data);  
    }
    if (i > 0) {
        console.log('Firebase batch operation completed. Doing final committing of batch operation.');
    } else {
        console.log('Firebase batch operation completed.');
    }
  }

  async assessmentSmoking(){ 
     
    const collection = this.db.collection('domains');
    const payload = {
      active: true,
      description: 'MOCA',
      icon: 'https://image.flaticon.com/icons/png/512/149/149347.png',
      name: 'Smoking',
      order: 13
    } 
    const ref = await collection.add(payload);

    const smoking = require("./seed/screening/smoking.json");   
    await this.db.collection(`/domains/${ref.id}/screening`).add(smoking ); 
    console.log('000000000000000000000 smoking', smoking);
  } 
  
  async assessmentCognitive(){ 
    const collection = this.db.collection('domains');
    const payload = {
      active: true,
      description: 'MOCA',
      icon: 'https://image.flaticon.com/icons/png/512/149/149347.png',
      name: 'Cognitive',
      order: 14
    } 
    const ref = await collection.add(payload);

    const cognitive = require("./seed/screening/cognitive.json");   
    await this.db.collection(`/domains/${ref.id}/screening`).add(cognitive ); 
    console.log('000000000000000000000 cognitive', cognitive);
  } 
  
}
