import {Component, Inject, Input, OnInit} from '@angular/core';
import {AssessmentDialogData, HealthAssessmentDialogComponent} from '../dialog/health-assessment/health-assessment.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FirebaseService} from '../../services/firebase.service';
import {Router} from '@angular/router'; 
import {Domain} from '../../models/domain';
import {DocumentReference} from '@angular/fire/firestore';
import {AuthService} from '../../services/auth.service';
import {AssessmentService} from '../../services/assessment.service';
import {ScoringService} from '../../services/scoring.service';
import {StatisticsService} from '../../services/statistics.service';
import {compare} from '../../helpers/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})

export class SummaryComponent implements OnInit {
  trendPeriod :string = "0";
  domains: Domain[];
  questions: any[];
  emaChartData: any[];
  assessmentChartData: any[];
  private emas: any;
  private emaTrends: any;
  private assessments: firebase.firestore.QueryDocumentSnapshot[];

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<HealthAssessmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssessmentDialogData,
              private router: Router,
              public auth: AuthService,  
              private assessmentService: AssessmentService,
              public scoringService: ScoringService,
              private snackBar: MatSnackBar,
              private fs: FirebaseService,
              public stats: StatisticsService,
              ) { }


  @Input() client: any;

  ngOnInit() {
    this.getData();
  }

  async getData() {
    this.domains = await this.assessmentService.domains.filter((x) => 
    (x.name !== 'Screening' && x.name !== 'Smoking' && x.name !== 'Cognitive')).sort((a, b) => {
      return compare( b.name, a.name);
    });
    this.assessments = await this.assessmentService.getLastAssessmentsForClient(this.client.ref);

    this.stats.restat(this.auth.intuiUser.providerId, this.client.uid).then( async r => {
      this.emaChartData = this.stats.emaResponseData; 
      this.emaTrends = await this.getLast28daysEmasForClient(this.stats.getEntries(this.client.uid));
    }); 

    this.assessmentService.getLastEmasForClient(this.client.ref).then(result => {
      this.emas = result;
    });

  }

  newHealthAssessment(client) {

    this.dialogRef = this.dialog.open(HealthAssessmentDialogComponent, {
      height: '90%',
      width: '80vw',
      data: {title: 'New Health Assessment', client: this.client, invite: false}
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      console.log(result);
      if (result) {

        const snap = await result.domain.get();
       // console.log('starting assessment..', snap.docs[0].data().name);
        this.router.navigateByUrl('/client/' + this.client.ref.id + '/assessment/' + result.ref.id);
      }
    });

  } 

  sendHealthAssessment(clientObj) {

    this.dialogRef = this.dialog.open(HealthAssessmentDialogComponent, {
      height: '90%',
      width: '80vw',
      data: {title: 'Send Health Assessment', client: clientObj, invite: true}
    });

    this.dialogRef.afterClosed().subscribe( async (result) => {
      console.log('afterClosed', result);
      if (!result) {
        //DO NOTHING when user click 'OK' button 
      }else if (result.invited) { 
        this.snackBar.open('Sent successfully!', 'Ok');  
      } else if (!result.invited) { 
        this.snackBar.open('The system  encountered an unexpected error. Please try again later.', 'Ok');
      }
    }); 
  }

  getLastAssessmentDate(domainRef: DocumentReference) {
    if (this.assessments) {
      const assessments = this.assessments.filter(x => x.data().domain.id === domainRef.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });
      if (assessments.length > 0) {
        return assessments[0].data().created.toDate().toLocaleDateString();
      } else {
        return 'No Data';
      }
    } else {
      return null;
    }
  }

  calculate(domain) {

    if (this.assessments) {
      const assessments = this.assessments.filter(x => x.data().domain.id === domain.ref.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });

      if (assessments.length > 0) {
        return this.scoringService.calculate(assessments[0].data());
      } else {
        return '';
      }
    }
  }

  // async getLastAssessmentScore(domainRef: DocumentReference) {
  //   return await this.assessmentService.getLastAssessment(this.client.ref, domainRef).;
  // }
  calculateQol(domain: Domain) {
    if (this.assessments) {
      const assessments = this.assessments.filter(x => x.data().domain.id === domain.ref.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });

      if (assessments.length > 0) {
        const res = this.scoringService.calculate(assessments[0].data());
        return `PCS-12: <b>${res[0]}</b> MCS-12: <b>${res[1]}</b>`;;
      } else {
        return null;
      }
    }
  }

  









  description(domain) {
 
    if (this.assessments) {
      //this.assessments.map(x => console.log(x.data().questionnairesRef.id ));

      const assessments = this.assessments.filter(x => x.data().questionnairesRef.id === domain.ref.id).sort((a, b) => {
        return compare(a.data().created.seconds, b.data().created.seconds);
      });

      if (assessments.length > 0) {
        return this.scoringService.getScoreDescription(assessments[0].data());
      } else {
        return null;
      }
    } else {
      return null;
    }
  }


  getLastEmaDate(domainRef: DocumentReference) { 
    if (this.emas && this.emas.responses) {
      const responses = this.emas.responses.filter(x => x.domain.id === domainRef.id);
      if (responses.length > 0) { 
        return moment(this.emas.created.toDate()).format('DD MMM YYYY hh:mm A'); 
      } else {
        return 'No Data';
      }
    } else {
      return null;
    }
  }
   
  onChangedTrendPeriod(event){  
    //this.emaTrends -- [before7daysMap, before14daysMap, before30daysMap]
    this.trendPeriod = event.value;  
    //this.fs.update('clients', client);
  }

  getAverageEmaScore(domainRef: DocumentReference, trendperiod: any) {
    /*TODO: Calculate Average EMA score over trend period for domain */ 
    const idx = parseInt(this.trendPeriod, 10); 
    if(this.emaTrends && this.emaTrends[idx]){  
      if(this.emaTrends[idx].get(domainRef.id)){ 
        const entries = this.emaTrends[idx].get(domainRef.id);
        const average = entries.reduce((a, c) => a + c.val, 0) / entries.length; 
        return average.toFixed(1);
      } 
    }
    return null;
  }

  getEmaEntries(domainRef: DocumentReference, trendperiod: any) {
    /*TODO: Calculate Number of EMA Entries over trend period for domain */
    const idx = parseInt(this.trendPeriod, 10); 
    if(this.emaTrends && this.emaTrends[idx]){ 
      if(this.emaTrends[idx].get(domainRef.id)){  
        return this.emaTrends[idx].get(domainRef.id).length;
      }
    }
    return 0; 
  }

  getEmaTrendOverTime(domainRef: DocumentReference) {
    /*TODO: Calculate EMA Trend over trend period for domain */
    const idx = parseInt(this.trendPeriod, 10); 
    if(this.emaTrends && this.emaTrends[idx]){

      const entries = this.emaTrends[idx].get(domainRef.id); 
      if(entries){ 
        const aDay = 24 * 60 * 60; 
        let basis_y = 0;
        let basis_x = 0;
  
        const value_y =  entries.map( (row : any) => { 
            if(basis_y == 0){
                basis_y = row.val == 0 ? 1 : row.val;//avoid infinity division
                return 1;
            }
            return row.val / basis_y;
        });
        const value_x = entries.map( (row : any) => {
            if(basis_x == 0){
                basis_x = row.created.seconds;
                return 1;
            }
            return 1 + ((row.created.seconds - basis_x) / aDay);
        }); 
  
        const lr = this.linearRegression(value_y, value_x); 

        let BASIS = 0.5; 
        const trendConfig = this.auth.getTrendConfig();
        if(trendConfig != null){
          if(trendConfig.basis){
            BASIS = trendConfig.basis;
          } 
        }

        if( lr.slope >= BASIS){
          return 1; 
        }
        return -1;   
      }
    }
    return 0; 
  }

  linearRegression(y,x) {
    let lr : any = {slope: 0, intercept: 0, r2: 0};

    let n = y.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    for (let i = 0; i < y.length; i++) {

        sum_x += x[i];
        sum_y += y[i];
        sum_xy += (x[i]*y[i]);
        sum_xx += (x[i]*x[i]);
        sum_yy += (y[i]*y[i]);
    }

    lr.slope = (n * sum_xy - sum_x * sum_y) / (n*sum_xx - sum_x * sum_x);
    lr.intercept = (sum_y - lr.slope * sum_x)/n;
    lr.r2 = Math.pow((n*sum_xy - sum_x*sum_y)/Math.sqrt((n*sum_xx-sum_x*sum_x)*(n*sum_yy-sum_y*sum_y)),2);

    return lr;
  }


  getLastEmaScore(domainRef: DocumentReference) { 
    if (this.emas && this.emas.responses) {
      const responses = this.emas.responses.filter(x => x.domain.id === domainRef.id);
      if (responses.length > 0) {
        return responses[0].response.intensity;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  
  getEmaTrend(domainRef: DocumentReference) { 
    
    let BASIS = 0.5; 
    const trendConfig = this.auth.getTrendConfig();
    if(trendConfig != null){
      if(trendConfig.basis){
        BASIS = trendConfig.basis;
      } 
    }

    if (this.client.trends && this.emas && this.emas.responses) {
      const responses = this.emas.responses.filter(x => x.domain.id === domainRef.id);
      if (responses.length > 0) { 
        const trends = this.client.trends.filter((t) => {
          return (t.id === responses[0].id);
        });  
        
        if(trends.length == 0){
          return 0;
        } 

        if(trends[0].slope >= BASIS){
          return 1; 
        }
        return -1;  
      } 
    }
    return 0;
  }

  getFamilyEmaTrend(domain) {
    //TODO implement this when we have EMA Data in future
    //return 'Not Implemented';
    return '';
  }
 
  getLast28daysEmasForClient(entries){  
    /*
    const before28days = moment(new Date()).subtract(28, 'days').toDate();
     
    const snap = await this.db.collection(`${clientRef.path}/entries`, 
        ref => ref.where('created', '>=', before28days).orderBy('created')).get().toPromise();
      
    if(snap.docs.length == 0){
      return [];
    }

    let emas : any [] = [];  
    */     

    if(!entries || entries.length == 0){
      return [];
    }
    console.log('entries', entries);

    let emas : any [] = []; 
    entries.forEach((ema: any) =>  {      
      let questionMap = new Map<string, any>(); 
      ema.questions.forEach((question: any) =>  {  
        questionMap.set(question.id, question.domainRef);   
      }); 
  
      //get Domain info
      ema.responses.forEach((res: any) =>  {    
        res.domain = questionMap.get(res.id); 
      }); 
      emas.push(ema);
    }); 
    
    return this.getPeoriodicTrends(emas); 
  }
 
  getPeoriodicTrends(emas : any []){

    const before28days = moment(new Date()).subtract(28, 'days').toDate();
    const before14days = moment(new Date()).subtract(14, 'days').toDate();
    const before7days = moment(new Date()).subtract(7, 'days').toDate();

    let before28daysMap = new Map<string, any>(); ;
    let before14daysMap = new Map<string, any>(); ;
    let before7daysMap = new Map<string, any>(); ;

    emas.forEach((ema: any) =>  { 
   
      ema.responses.forEach((res: any) =>  {
        const row = {created: ema.created, val: res.response.intensity};

        if(row.created.seconds * 1000 < before28days.getTime()){
          return;
        }
        // 28days 
        let answers28 = before28daysMap.get(res.domain.id) || [] as any[];   
        answers28.push(row);
        before28daysMap.set(res.domain.id, answers28);

        if(row.created.seconds * 1000 < before14days.getTime()){
          return;
        }
        //14 days
        let answers14 = before14daysMap.get(res.domain.id) || [] as any[];  
        answers14.push(row);
        before14daysMap.set(res.domain.id, answers14); 

        if(row.created.seconds * 1000 < before7days.getTime()){
          return;
        }
        //7 days
        let answers7 = before7daysMap.get(res.domain.id) || [] as any[];  
        answers7.push(row);
        before7daysMap.set(res.domain.id, answers7);

      });  
    });   

    return [before7daysMap, before14daysMap, before28daysMap];
  }
 

}
