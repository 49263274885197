import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../app.component';
import {MatDialog} from '@angular/material';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../services/auth.service';
import { PasswordResetDialogComponent } from './password-reset.dialog/password-reset.dialog.component';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  private dialogRef;
  constructor(private app: AppComponent, 
    public auth: AuthService,
    private snackBar: MatSnackBar,
    public db: AngularFirestore, 
    private dialog: MatDialog) { }

  ngOnInit() {
    this.app.setTitle('Settings');
  }

  newPassword() {
    this.dialogRef = this.dialog.open(PasswordResetDialogComponent, {
      height: 'auto',
      width: '60vw', data: {providerId: this.auth.intuiUser.providerId, isAdmin: this.auth.isAdmin()}
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      console.log('Dialog Results (edit)', result);
      if (result.err) {
        this.snackBar.open(result.err, 'Ok');    
      }else if (result.changed){
        this.snackBar.open('Password Changed successfully', 'Ok');   
      }
    });
  }
}
