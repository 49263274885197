import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material'; 
import {AngularFireFunctions} from '@angular/fire/functions';
import {FirebaseService} from '../../../services/firebase.service'; 
import {Assessment} from '../../../models/assessment';
import {AssessmentService} from '../../../services/assessment.service';
import {Domain} from '../../../models/domain';
import {ClientService} from '../../../services/client.service';
import {DomainService} from '../../../services/domain.service';
import {AuthService} from '../../../services/auth.service'; 
import Timestamp = firestore.Timestamp;
import {firestore} from 'firebase'; 
import {encodeBase64} from '../../../helpers/utils'; 
import {environment} from '../../../../environments/environment';
import * as moment from 'moment';


export interface AssessmentDialogData {
  title: string;
  client: any;
  invite: boolean;
  assessmentToSend: any;
}

@Component({
  selector: 'app-health-assessment',
  templateUrl: './health-assessment.component.html',
  styleUrls: ['./health-assessment.component.scss']
})

export class HealthAssessmentDialogComponent implements OnInit {
  outstandingAssessments: Assessment[] = [];
  domains: Domain[];
  defaultAssessments: Assessment[] = [];
  showDefaults = false;
  assessmentToSend : Assessment;
  private client: any;
  showScreeningSegue = false;
  screening: Assessment;
  mandatories: Assessment [];
  loading: boolean;
  isScreeningTest: boolean;

  constructor(private fb: FirebaseService,
              private assessmentService: AssessmentService,
              private clientService: ClientService,
              private domainService: DomainService,
              private fbfunc: AngularFireFunctions,
              public auth: AuthService,
              public dialogRef: MatDialogRef<HealthAssessmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssessmentDialogData) {}

  ngOnInit() {
    this.getData();
  }

  async getData() {

    this.loading = true;
    this.domains = this.assessmentService.domains;
    this.domains.forEach(x => {
      if (x.name !== 'Screening' 
            && x.name !== 'Smoking' && x.name !== 'Cognitive' // non assessment
            && x.name !== 'Quality of Life' && x.name !== 'Nutrition') {// mandatory assessment
        this.defaultAssessments.push(new Assessment(x.ref, x.name, x.icon, Timestamp.fromDate(new Date(Date.now())), false));
      }
    });
    this.client = this.clientService.clients.find(x => x.email === this.data.client.email);
    await this.clientService.getClientById(this.client.uid).then((res)=>{
      this.client.isScreened = res.isScreened;
    });

    const obj = await this.clientService.getOutstandingClientAssessments(this.client.ref);
    this.screening = await this.assessmentService.getScreeningAssessment(this.client.ref); 
    this.mandatories = await this.assessmentService.getMandatoryAssessments(this.client.ref); 
    if (this.client.isScreened) {
      obj.subscribe(assessments => {
        assessments = assessments.filter(x => x.name !=='Screening' && x.name !=='Quality of Life' && x.name !=='Nutrition');
        this.outstandingAssessments = assessments.sort(x => x.created);
        console.log(this.outstandingAssessments);
      });

    } else {
      this.showScreeningSegue = true;
    }

    if(this.data.assessmentToSend){//from Notfication list
      this.assessmentToSend = this.data.assessmentToSend; 
      this.showDefaults = false;
    }

    this.loading = false;

  }

  getDomain(assessment: Assessment) {
    if (assessment) {
      const obj = this.domainService.domains.find(x => x.ref.id === assessment.domain.id);
      return obj;
    } else {
      return null;
    }
  }

  async startAssessment(assessment: Assessment) {
    if(!this.data.invite){  
      this.loading = true;
    }
    /*
    if (this.screening) {
      if (assessment.domain.id === this.screening.domain.id && this.showDefaults || !this.client.isScreened) {
        this.isScreeningTest = (assessment.domain.id === this.screening.domain.id);
        //const ref = await this.clientService.persistAssessmentForClient(this.client, assessment);
        //assessment.ref = ref;
        console.log('startAssessment', 'if');
      }
    } else if (this.showDefaults && this.outstandingAssessments.find(x => x.ref === assessment.ref) !== null) {
      //const ref = await this.clientService.persistAssessmentForClient(this.client, assessment);
      //assessment.ref = ref;
        console.log('startAssessment', 'else if');
    }
    */

    //allow mutilple assessment anytime 
    const newAssessment = new Assessment(assessment.domain, assessment.name, assessment.icon, Timestamp.fromDate(new Date(Date.now())), false);  
    const ref = await this.clientService.persistAssessmentForClient(this.client, newAssessment);
    assessment.ref = ref;

    if(this.data.invite){  
      this.showDefaults = false;
      this.assessmentToSend = assessment; 
    }else{ 
      console.log(assessment);
      this.dialogRef.close(assessment); 
    }
  } 
  
  async sendAssessment(assessment: Assessment){
  
    this.loading = true;
    //console.log('this.auth.intuiUser.providerId.id', this.auth.intuiUser.providerId.id);
    //console.log('this.client.uid', this.client.uid);
    //console.log('assessment.ref.id', assessment.ref.id);
    //console.log('this.client.email', this.client.email);

    const params = encodeBase64([this.auth.intuiUser.providerId.id, this.client.uid, assessment.ref.id]);
    const messages = {
      client_name : `${this.client.firstName} ${this.client.lastName}` ,
      client_email : this.client.email,
      provider_logo : this.auth.getProviderLogo(), 
      provider_name : this.auth.getProviderName(),   
      email_subject : `New assessment: ${assessment.name}`,
      email_message : `${this.auth.intuiUser.firstName} ${this.auth.intuiUser.lastName} from ${this.auth.getProviderName()} would like you to complete the following ${assessment.name} questions.`,
      link_url : `${environment.assessment.standalone_url}/assessment/${params}`,
      client_id : this.client.uid,
      push_notification : true,
      push_text : `${this.client.firstName}, do you have a minute to answer your health questions?`,
      push_title : 'Assessment Reminder',
      push_topic : 'goact-assessment' 
    } 
    
    await this.fbfunc.httpsCallable('send_assessment_email')(messages).toPromise().then(r => { 
      //console.log('send_assessment', r); 
      this.dialogRef.close({invited :true});
    }).catch(async e => { 
      console.log('send_assessment error', e);  
      this.dialogRef.close({invited :false});
    }); 
  } 
  
  onCancelClick(): void {
    this.dialogRef.close();
  } 


  done() {
    this.dialogRef.close(null);
  }

  showAll($event) {
    if ($event.checked) {
      this.showDefaults = true;
    } else {
      this.showDefaults = false;
    }
    console.log($event);
  }

  registered(created) {
    return moment(created.toDate()).format('DD MMM YYYY');
  }
}
