import {DocumentReference} from '@angular/fire/firestore'; 

export class CognitoMember { 
  username: string;
  email: string; 
  userStatus: string;
  userCreateDate: string;
  userLastModifiedDate: string;
  enabled: boolean;
  lasUserStatustName: string; 
  firstName: string; 
  lastName: string; 
  preferredUsername: string; 
  phoneNumber: string; 
  gender :string;
  birthdate :string;

 
  constructor( json: any ) { 
    if (json.Username){
      this.username = json.Username;
    }  
    if (json.UserStatus){
      this.userStatus = json.UserStatus;
    }   
    if (json.UserCreateDate){
      this.userCreateDate = json.UserCreateDate;
    }
    if (json.UserLastModifiedDate){
      this.userLastModifiedDate = json.UserLastModifiedDate;
    }
    if (json.Enabled){
      this.enabled = json.Enabled;
    }
    if (json.LasUserStatustName){
      this.lasUserStatustName = json.LasUserStatustName;
    }

    if (json.Attributes){
      this.setAttributes(json.Attributes) ;
    } 
  } 
  
  setAttributes(attributes : any[]) { 
    attributes.map(attribute => {
      if(attribute.Name === 'email'){
        this.email = attribute.Value;
      } 
      if(attribute.Name === 'given_name'){
        this.firstName = attribute.Value;
      } 
      if(attribute.Name === 'family_name'){
        this.lastName = attribute.Value;
      } 
      if(attribute.Name === 'preferred_username'){
        this.preferredUsername = attribute.Value;
      } 
      if(attribute.Name === 'phone_number'){
        this.phoneNumber = attribute.Value;
      } 
      if(attribute.Name === 'gender'){
        this.gender = attribute.Value;
      }  
      if(attribute.Name === 'birthdate'){
        this.birthdate = attribute.Value;
      }  
    }); 
  }
}
 