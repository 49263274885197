import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {ClientService} from '../../services/client.service';
import {AssessmentService} from '../../services/assessment.service';
import {Exclusion} from 'tslint/lib/rules/completed-docs/exclusion';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AppComponent} from '../../app.component';
import {HealthAssessmentDialogComponent} from '../../components/dialog/health-assessment/health-assessment.component';
import {AuthDialogComponent} from '../../components/dialog/auth/auth.dialog.component';

@Component({
  selector: 'app-dialog-cancel',
  template: '<div>' +
  '  <h2 mat-dialog-title>Leave the assessment?</h2>' +
  '  <mat-dialog-content>This will lose all data for the health assessment so far. Are you sure?</mat-dialog-content>' +
  '  <mat-dialog-actions>' +
  '    <button mat-button mat-dialog-close>Cancel</button>' +
  '    <button mat-button [mat-dialog-close]="true">Yes</button>' +
  '  </mat-dialog-actions>' +
  '</div>'
})
export class DialogCancelDialog {}

@Component({
  selector: 'app-health-assessment',
  templateUrl: './health-assessment.component.html',
  styleUrls: ['./health-assessment.component.scss']
})
export class HealthAssessmentComponent implements OnInit {
  loading: boolean;
  questions: any;
  active: any = 0;
  private dialogRef;
  private responses: any = [];
  private clientId: any;
  private assessmentId: any;
  public assessment: any;
  private client: any;
  private assessmentRef: DocumentReference;
  private screeningTest: any;
  isScreeningTest: any;
  userModeOn: boolean;
  state: boolean;
  pageCompleted: boolean;
  pageTotal: number;
  answerCount: number;
  private screeningQuestionTotal = 0;

  constructor(private fs: AngularFirestore, private activatedRoute: ActivatedRoute,
              private snack: MatSnackBar,
              private clientService: ClientService,
              private router: Router,
              private location: Location,
              private dialog: MatDialog,
              private appComponent: AppComponent,
              private assessmentService: AssessmentService) { }

  ngOnInit() {

      this.loading = true;
      this.answerCount = 0;
      this.appComponent.assessmentInProgress = true;
      this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
      this.assessmentId = this.activatedRoute.snapshot.paramMap.get('assessmentId');
      this.getQuestions(this.clientId, this.assessmentId);

  }

  async getQuestions(clientId, assessmentId) {

    //TODO is screening, build questions from the screening questions objects in assessment service.
    const assessmentSnap = await this.fs.doc(`clients/${clientId}/assessments/${assessmentId}`).get().toPromise();
    this.assessment = await assessmentSnap.data();
    this.assessmentRef = await assessmentSnap.ref;
    const clientSnap = await this.fs.doc(`clients/${clientId}`).get().toPromise();
    this.client = await clientSnap.data();
    this.client.ref = clientSnap.ref;
    const domain = await this.assessment.domain.get();
    this.screeningTest = await this.fs.collection('domains', ref => ref.where('isScreening', '==', true)).get().toPromise();

    const questionSnap = await this.fs.collection(`domains/${domain.ref.id}/questionnaires/`, ref => ref.where('active', '==', true)).get().toPromise();
    this.isScreeningTest = this.assessment.name === 'Screening';
    if (this.assessmentService.screeningQuestions && this.isScreeningTest) {
      this.questions = this.assessmentService.screeningQuestions;
      this.loading = false;
      for(const set of this.questions) {
        this.screeningQuestionTotal = this.screeningQuestionTotal + set.controls.length;
      }
    } else if(!this.isScreeningTest) {
      this.questions = questionSnap.docs[0].data();
      this.loading = false;
    } else {
      this.loading = false;
      this.snack.open('No Questions were available for this assessment!', 'Ok');
      throw new DOMException('No Questions were available for this assessment!');

    }
  }

  isActive(i) {
    return this.active === i;
  }

  userMode($event) {
    this.state = $event.checked;
    if ($event.checked) {
      this.userModeOn = $event.checked;
      this.appComponent.userMode = this.userModeOn;
    } else {
      // re auth
      this.showAuthGuard($event.checked);
    }
  }

  private showAuthGuard(state) {
    this.dialogRef = this.dialog.open(AuthDialogComponent, {
      height: '60%',
      width: '40vw',
    });

    this.dialogRef.afterClosed().subscribe( async (result) => {
      console.log(result);
      if (result) {
        this.userModeOn = state;
        this.appComponent.userMode = this.userModeOn;
      } else {
        this.state = true;
      }
    });
  }

  finishUserMode() {
    this.showAuthGuard(false);
  }

  next() {
    this.active++;
    this.pageTotal = this.questions.pages[this.active].controls[0].controls.length;
    this.answerCount = 0;
    this.pageCompleted = false;
    this.scrollUp();
  }

  finish() {
    console.log('FINISH the thing!', this.responses);
    this.assessment.responses = this.responses;
    this.assessment.complete = true;
    this.assessment.created = new Date(Date.now());
    this.assessmentRef.update(this.assessment);
    if (this.assessment.domain.id === this.screeningTest.docs[0].ref.id) {
      this.client.isScreened = true;
      this.client.ref.set(this.client, {merge: true});
    }
    if (!this.userModeOn) {
      this.appComponent.assessmentInProgress = false;
    }
  }

  close() {
    //alert and and if yes then bail
    const dialogRef = this.dialog.open(DialogCancelDialog);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.location.back();
      }
    });
  }

  back() {
    this.active--;
    this.pageTotal = this.questions.pages[this.active].controls[0].controls.length;
    this.answerCount = this.pageTotal;
    this.pageCompleted = true;
  }

  private scrollUp() {
    const element = document.querySelector('#top');
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  recordResponse($event) {

    console.log($event);
    const payload = JSON.parse($event);
    const existing = this.responses.findIndex(x => x.id === payload.id);
    if (existing > -1) {
      this.responses.splice(existing, 1);
    } else {
      this.answerCount++;
    }

    const k = payload.question;
    this.responses.push(payload);
    console.log(this.responses);

    if (this.isScreeningTest) {
      if (this.answerCount === this.screeningQuestionTotal) {
        this.pageCompleted = true;
      } else {
        this.pageCompleted = false;
      }
    } else {
      if (this.answerCount === this.questions.pages[this.active].controls[0].controls.length) {
        this.pageCompleted = true;
      } else {
        this.pageCompleted = false;
      }
    }

  }


  repeat() {
    //create a new assessment based on given domain..
    console.log('repeat!');
    this.create();
    this.appComponent.assessmentInProgress = true;
  }

  home() {
    this.router.navigateByUrl(`client/${this.clientId}/action/notification`);
  }

  goBack() {
    this.location.back();
  } 
  
  async create() {
    this.assessment.complete = false;
    const ref = await this.clientService.persistAssessmentForClient(this.client, this.assessment);
  }
}
