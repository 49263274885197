import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../app.component';
import {AuthService} from '../../services/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../services/firebase.service';
import {AngularFirestore, SetOptions} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  code = new FormControl('', [Validators.required]);

  codeForm: FormGroup = new FormGroup({
    code: this.code,
  });

  constructor(private app: AppComponent, public auth: AuthService, private fs: AngularFirestore, private snack: MatSnackBar) { }

  ngOnInit() {
    this.app.setTitle('Home');
  }

  validateCode() {

      console.log(this.code.value);
      this.fs.firestore.collection('access_codes').where('code', '==', this.code.value).get().then(r => {
        if (r.docs.length > 0) {
          //validate user against code.
          if (r.docs[0].data().userId) {
            if (this.auth.intuiUser.ref.path === r.docs[0].data().userId.path) {
              this.auth.intuiUser.ref.set({status: 'user'}, {merge: true});
            }
          }
        } else {
          this.snack.open('Invalid Code! Please try again', 'OK');
        }
      });
  }

}
