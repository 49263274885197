import { Injectable } from '@angular/core';   

@Injectable({
  providedIn: 'root'
})

export class BoundariesService {

    //STATISTICS BOUNDARIES
    //Entries
    entriesBoundary1: number = 1;
    entriesBoundary2: number = 5;
    entriesBoundary3: number = 15;
    //Emotions
    emotionsBoundary1: number = 5;
    emotionsBoundary2: number = 10;
    emotionsBoundary3: number = 20;
    emotionsBoundary4: number = 30;
    //Activities
    activitiesBoundary1: number = 5;
    activitiesBoundary2: number = 10;
    activitiesBoundary3: number = 20;
    //People
    peopleBoundary1: number = 5;
    peopleBoundary2: number = 10;
    peopleBoundary3: number = 30;

    //STATISTICS GRAPHS etc

	//DATA Settings
	dataStreams: boolean = true;
	cloudStoreData: boolean = true;

    //FACEBOOK LOGIN
    facebookLogin: boolean = false;

    //Bug reports
    problemsReporting: boolean = false;

    //BT
    bluetooth: boolean = false;

    //Notification
    notifications: boolean = false;

    //STATISTICS PAGE INCLUDED?
    statisticsPageShown: boolean = true;

    //INSIGHTS PAGE INCLUDED?
    insightsPageShown: boolean = true;

    //IS FLINDERS STUDY?
    isStudy: boolean = true;

    //SHOW TUTORIAL BUTTON IN Account
    //Development only
    showTutorialButton: boolean = false;

    showContent: boolean = false;

    constructor() {}
}
