import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts'; 

@Component({
  selector: 'app-column-graph',
  templateUrl: './column-graph.component.html',
  styleUrls: ['./column-graph.component.scss']
})
export class ColumnGraphComponent implements OnInit {
    charts : any;

  //constructor(private alertCtrl: AlertController) {}

    @Input()
    id: any;

    @Input()
    title: any;

    @Input()
    subTitle: any;

    @Input()
    yLabel: any;

    @Input()
    xLabels: any;

    @Input()
    data: any;

    @Input()
    helpText: string;

/*
    async presentAlert() {
        const title = this.title;
        const subTitle = this.helpText;

        const alert = await this.alertCtrl.create({
            header: title,
            message: subTitle,
            buttons: ['OK']
        });
        alert.present();

    }
*/
    ngOnInit(): void {
        setTimeout(() => {
            //Highcharts.chart(this.id, {
            this.charts = new Chart({
                chart: {
                    type: 'column',
                    backgroundColor: 'transparent',
                    spacingRight: 10,
                    spacingLeft: 0,
                },
                title: {
                    text: (this.subTitle) ? this.subTitle : ''
                },
                xAxis: {
                    categories: this.xLabels,
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: this.yLabel
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: this.data,
            });
        }, 200);
    }

    getChart() {  
      return this.charts;
    } 
}
