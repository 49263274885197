import {Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bool-with-range-trigger',
  templateUrl: './bool-with-range-trigger.component.html',
  styleUrls: ['./bool-with-range-trigger.component.scss']
})
export class BoolWithRangeTriggerComponent implements OnInit {

  @Output()
  response = new EventEmitter();

  @Input() bool;
  @Input() domain;
  @Input() options;
  @Input() question;
  @Input() question2;
  @Input() id;

  public val: any;
  public val2: any;
  public selected: any;
  public selected2: any;
  public rangeSelected: boolean;

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.val2 = null;
    this.selected2 = null;
  }

  select(i) {
    if (i > 0) {
      this.rangeSelected = true;
    } else {
      this.rangeSelected = false;
      this.clear();
    }
    this.selected = i;
    this.val = [i, this.bool[i]];
    this.response.emit(JSON.stringify({id: this.id, question: this.question, response: this.val, response2: this.val2, domain: this.domain}));
  }

  select2(i) {
    this.selected2 = i;
    this.val2 = [i, this.options[i]];
    this.response.emit(JSON.stringify({id: this.id, question: this.question, question2: this.question2, response: this.val, response2: this.val2, domain: this.domain}));
  }

  isSelected(i) {
    return (this.selected === i) ? 'check' : 'close';
  }

  isSelected2(i) {
    return (this.selected2 === i) ? 'check' : 'close';
  }

}
