import { BrowserModule } from '@angular/platform-browser';
import {EventEmitter, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MAT_DIALOG_DATA,
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule,
  MatButtonModule, MatButtonToggleModule,
  MatCardModule, MatCheckboxModule, MatChipsModule, MatCommonModule, MatDatepickerModule, MatDialogModule, MatDialogRef, MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule, MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule,
  MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule,
  MatTabsModule,
  MatToolbarModule, MatTooltipModule, MatTreeModule
} from '@angular/material';
import {UsersComponent} from './pages/users/users.component';
import {ProvidersComponent} from './pages/providers/providers.component';
import {ReportsComponent} from './pages/reports/reports.component';
import {LoginComponent} from './pages/login/login.component';
import {AccountForgottenComponent} from './pages/account-forgotten/account-forgotten.component';
import {PasswordResetDialogComponent} from './pages/settings/password-reset.dialog/password-reset.dialog.component'; 
import {ProfileComponent} from './pages/settings/profile/profile.component';
import {HomeComponent} from './pages/home/home.component';
import {OrderModule} from 'ngx-order-pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';
import { SettingsComponent } from './pages/settings/settings.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { AdminComponent } from './pages/admin/admin.component';
import { DeleteDialogComponent} from './components/dialog/delete/delete.dialog.component';
import { ClientsDialogComponent} from './pages/clients/clients.dialog/clients.dialog.component'; 
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { NotificationDialogComponent} from './components/dialog/notification/notification.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AngularFireFunctions, AngularFireFunctionsModule} from '@angular/fire/functions';
import { CodeDialogComponent} from './components/dialog/code/code.dialog.component';
import { UsersDialogComponent} from './pages/users/users.dialog/users.dialog.component';
import { ProvidersDialogComponent} from './pages/providers/providers.dialog/providers.dialog.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ReportComponent } from './components/report/report.component';  
import { HealthComponent } from './components/health/health.component';
import { ExperiencesComponent } from './components/experiences/experiences.component';
import { GoalsComponent } from './components/goals/goals.component';
import { HealthAssessmentDialogComponent } from './components/dialog/health-assessment/health-assessment.component';
import {DialogCancelDialog, HealthAssessmentComponent} from './pages/health-assessment/health-assessment.component';
import { TextComponent } from './components/controls/text/text.component';
import { RangeComponent } from './components/controls/range/range.component';
import { TimeComponent } from './components/controls/time/time.component';
import { NumberComponent } from './components/controls/number/number.component';
import {RangeWithTextTriggerComponent} from './components/controls/range-with-text-trigger/range-with-text-trigger.component';
import {ColumnGraphComponent} from './components/graphs/column-graph/column-graph.component';
import {SplineGraphComponent} from './components/graphs/spline-graph/spline-graph.component';
import {DonutGraphComponent} from './components/graphs/donut-graph/donut-graph.component';
import {EntryCountComponent} from './components/graphs/entry-count/entry-count.component'; 
import {BubbleGraphComponent} from './components/graphs/bubble-graph/bubble-graph.component';
import {HorizontalBarGraphComponent} from './components/graphs/horizontal-bar-graph/horizontal-bar-graph.component';
import { CodeComponent } from './pages/code/code.component';
import {BoolWithRangeTriggerComponent} from './components/controls/bool-with-range-trigger/bool-with-range-trigger.component';
import { AuthDialogComponent } from './components/dialog/auth/auth.dialog.component';
import {ChartModule} from 'angular-highcharts';
import { HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UsersComponent,
    ProvidersComponent,
    ReportsComponent,
    LoginComponent,
    AccountForgottenComponent, 
    PasswordResetDialogComponent,
    ProfileComponent,
    SettingsComponent,
    ClientsComponent,
    NotificationsComponent,
    AdminComponent,
    UsersDialogComponent,
    ProvidersDialogComponent,
    ClientsDialogComponent, 
    DeleteDialogComponent,
    NotificationDialogComponent,
    HealthAssessmentDialogComponent,
    CodeDialogComponent,
    LoadingComponent,
    SummaryComponent,
    NotificationComponent,
    ReportComponent,
    HealthComponent,
    ColumnGraphComponent,
    DonutGraphComponent,
    EntryCountComponent,
    SplineGraphComponent,
    BubbleGraphComponent,
    HorizontalBarGraphComponent,
    ExperiencesComponent,
    GoalsComponent,
    HealthAssessmentComponent,
    TextComponent,
    RangeComponent,
    RangeWithTextTriggerComponent,
    BoolWithRangeTriggerComponent,
    TimeComponent,
    NumberComponent,
    CodeComponent,
    AuthDialogComponent,
    DialogCancelDialog
  ],
  entryComponents: [
    UsersDialogComponent,
    PasswordResetDialogComponent,
    ProvidersDialogComponent,
    ClientsDialogComponent, 
    CodeDialogComponent,
    DeleteDialogComponent,
    HealthAssessmentDialogComponent,
    NotificationDialogComponent,
    AuthDialogComponent,
    DialogCancelDialog
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAuthGuardModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OrderModule,
    ChartModule
  ],
  providers: [
    AuthService,
    //EventEmitter,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
],

  bootstrap: [AppComponent]
})
export class AppModule { }
