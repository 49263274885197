import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FirebaseService} from '../../../services/firebase.service';
import {firestore} from 'firebase/app';
import * as moment from 'moment';
import Timestamp = firestore.Timestamp;
import {ProviderService} from '../../../services/provider.service';
import {Provider} from '../../../models/provider';
import {DocumentReference} from '@angular/fire/firestore';

export interface ProviderDialogData {
  firstName: string;
  lastName: string;
  email: string;
  created: Date;
  provider: string;
  roles: any;
}

@Component({
  selector: 'app-providers-dialog',
  templateUrl: './providers.dialog.component.html',
  styleUrls: ['./providers.dialog.component.scss']
})
export class ProvidersDialogComponent implements OnInit, OnDestroy {

  // public project: any = {};
  userGroup: FormGroup;
  title = 'Add New Provider';

  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required]);
  provider = new FormControl('', [Validators.required]);
  roles = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<ProvidersDialogComponent>,
    private providerService : ProviderService,
    @Inject(MAT_DIALOG_DATA) public data: ProviderDialogData) {

    if (this.data) {
      this.title =  'Edit Provider';
      this.firstName.setValue(data.firstName);
      this.lastName.setValue(data.lastName);
      this.provider.setValue(data.provider);
      this.email.setValue(data.email);
      this.roles.setValue("provider-admin");
    }

  }

  getFirstNameErrorMessage() {
    return this.firstName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getLastNameErrorMessage() {
    return this.lastName.hasError('required') ? 'You must enter a value' :
      '';
  }

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      '';
  }

  getProviderErrorMessage() {
    return this.provider.hasError('required') ? 'You must enter a value' :
      '';
  }

  ngOnInit(): void {
    //this.providers = this.providerService.get();

    this.userGroup = new FormGroup({
      firstname: this.firstName,
      lastname: this.lastName,
      email: this.email,
      roles: this.roles,
      provider: this.provider
    });
  }

  ngOnDestroy(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSave() {
    if (!this.firstName.invalid && !this.lastName.invalid && !this.email.invalid
      && !this.provider.invalid) {

      const payload = {
        firstName: this.firstName.value, lastName: this.lastName.value,
        email: this.email.value, created: new Date(Date.now()),
        pass: '',
        provider: this.provider.value,
        roles: this.roles.value,
        invite: true,
        avatarUrl: null, 
        deleted: false
      };      
      payload.pass = this.genCode('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 1) + this.genCode('abcdefghijklmnopqrstuvwxyz', 8) + this.genCode('0123456789', 1);

      console.log('payload', payload);
      this.dialogRef.close(payload);
    } else {
      this.userGroup.markAllAsTouched();
    }
  }

  genCode(characters, length) { 
    let result           = ''; 
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
