import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';  
import * as moment from 'moment';
import {AngularFirestore, DocumentReference, Query} from '@angular/fire/firestore';  
import {MatSnackBar} from '@angular/material';
import {AngularFireAuth} from '@angular/fire/auth'; 
import {AngularFireFunctions} from '@angular/fire/functions'; 
import {AuthService} from '../../../services/auth.service';  
import {environment} from '../../../../environments/environment';
import {CognitoMember} from '../../../models/cognito_member';
import {HttpClientModule, HttpClientJsonpModule, HttpClient, HttpHeaders } from '@angular/common/http';    

export enum STATE {
  changeState = 'changeState',
  removeOrIgnore = 'removeOrIgnore',
  addComment = 'addComment',
  viewComments = 'viewComments',
}

export class Comment {
  commenterRef: DocumentReference;
  commenterName: string; 
  created: Date; 
  txt: string;  
}

export interface NotificationDialogData {
  ref: DocumentReference;
  clientRef: DocumentReference; 
  clientEmail: string;//transient
  clientName: string; //transient
  clientFirstName:string;
  clientLastName:string;
  advisorRef: DocumentReference;  
  advisorName: string;  
  created: Date; 
  modified: string; 
  state: string; 
  level: string;
  type: string; 
  recommendation: string;
  reason: string; 
  color: string;   
  comments: Comment [];  
  mode: string;  
}

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationDialogComponent implements OnInit, OnDestroy {
  
  // public project: any = {};
  notificationGroup: FormGroup; 
  title = 'Take Action';
  comments: Comment [] = [];

  state = new FormControl('', [Validators.required]); 
  inputComment = new FormControl('', [Validators.required]);  
   
  public loading: boolean;
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotificationDialogData, 
    private fireauth: AngularFireAuth,
    private snackBar: MatSnackBar, 
    public auth: AuthService, 
    private fbfunc: AngularFireFunctions, 
    private db: AngularFirestore,
    private http: HttpClient) {

    if (this.data) {  
      this.comments = this.data.comments;
      if(this.data.mode === STATE.removeOrIgnore){
        this.state.setValue('Removed');  
      }else if(this.data.mode == STATE.changeState){
        this.state.setValue('');  
      }else{
        this.state.setValue(data.state);  
      }
    } 
  }

  getStateErrorMessage() { 
    return this.state.hasError('required') ? 'You must enter a value' :
      '';
  }

  getCommentErrorMessage() {
    if (this.data.mode == STATE.addComment || this.data.mode == STATE.removeOrIgnore){
      return this.inputComment.hasError('required') ? 'You must enter a value' :
      '';
    }
    return '';
  }
 
  ngOnInit(): void {
    this.loading = false;
    this.notificationGroup = new FormGroup({
      state: this.state ,
      inputComment: this.inputComment
    });
    this.getComments();
  }

  
  async getComments(){   

    let uids = this.comments.reduce((result, element) =>{
      if(!element.commenterName && element.commenterRef && element.commenterRef.id){
        result.push(element.commenterRef.id);
      }  
      return result; 
    }, []);   

    const cognitoUserMap = await this.getCogintoUsers(uids);  
       
    //  name
    this.comments.map(comment => {
      let row = cognitoUserMap.get(comment.commenterRef.id) as CognitoMember; 
      if(row){ 
        //comment.commenterName = `${row.firstName} ${row.lastName}`; 
        comment.commenterName = row.firstName; 
      }  
    }); 
  }

  async getCogintoUsers(uidList){    

    //onlyUnique
    let uids = uidList.filter((v, i, a) => a.indexOf(v) === i); 

    const cognitoUserMap = new Map<string, CognitoMember>(); 
    uids = uids.reduce((result, uid) =>{
      if(!cognitoUserMap.get(uid)){
        result.push(uid);
      } 
      return result; 
    }, []); 
      
    const CHUNCKED = 20;

    while (uids.length > 0){
      let willRequestUids =  uids.splice(0,  CHUNCKED);
      const url = environment.awsApis.userList + '&uids=' + willRequestUids;   

      await this.http.get(url).toPromise() 
      .then(res => { 
        res['Users'].map(item => {
          const row = new CognitoMember(item); 
          cognitoUserMap.set(row.username, row);  
        }); 
          
      }).catch(err=>{  
        console.log('this.getCogintoUsers.err ', err); 
      }); 
    }
    return cognitoUserMap;
  } 
   
  registered(created) { 
    return moment(created.toDate()).format('ddd DD MMM YYYY');
  }

  timeOfDay(created) {
    return moment(created.toDate()).format('DD/MM/YYYY HH:mm');
  }

  ngOnDestroy(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  } 

  async onEdit() {       
    let isValid = false;
    let txt = '';
    if (this.data.mode == STATE.changeState && !this.state.invalid && this.data.state !== this.state.value) {
      txt = `[${this.data.state} -> ${this.state.value}]`;
      isValid = true;
    } else if (this.data.mode == STATE.addComment && !this.inputComment.invalid) {
      txt = `[${this.state.value}]`;
      isValid = true;
    } else if (this.data.mode == STATE.removeOrIgnore && !this.inputComment.invalid) {
      txt = `[${this.data.state} -> ${this.state.value}]`; 
      isValid = true;
    }

    if (isValid) {
       
      if (!this.inputComment.invalid){
        txt += ` ${this.inputComment.value}`;  
      }

      const comment = new Comment();
      comment.commenterRef = this.auth.intuiUser.ref;
      comment.created =  new Date();
      comment.txt = txt;

      const commentsToUpdate = [...this.data.comments];  
      commentsToUpdate.push(comment);
      const commentList = commentsToUpdate.map((obj)=> {return Object.assign({}, obj)});

      const payload = {
        modified: new Date(),
        state: this.state.value,
        completed: false,
        comments: commentList
      };
      
      if(this.state.value === 'Completed' || this.state.value === 'Removed'){
        payload.completed = true; 
      }

      this.loading = true;    
      await this.db.doc(`${this.data.ref.path}`).set(payload,{merge:true}); 
      this.loading = false; 
         
      this.dialogRef.close(payload);
    } else {
      this.notificationGroup.markAllAsTouched();
    } 
  } 
}
