import {DocumentReference} from '@angular/fire/firestore';

export class User {
  
  constructor(
    deleted: boolean,
    providerId: any,
    uid: string) {
    this.deleted = deleted;
    this.providerId = providerId;
    this.uid = uid;
  }

  ref: DocumentReference;
  roles: string[];
  email: string;
  avatarUrl: string;
  firstName: string;
  deleted: boolean;
  lastName: string;
  gender: string;
  created: Date;
  phone: string;
  providerId: any;
  uid: string;
}