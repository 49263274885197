import {Component, Input, OnInit} from '@angular/core'; 
import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
require('highcharts/highcharts-more')(Highcharts);

@Component({
  selector: 'app-bubble-graph',
  templateUrl: './bubble-graph.component.html',
  styleUrls: ['./bubble-graph.component.scss']
})
export class BubbleGraphComponent implements OnInit {
    charts : any;

    //constructor(private alertCtrl: AlertController) {}

    @Input()
    id: any;

    @Input()
    title: any;

    @Input()
    subTitle: any;

    @Input()
    yLabel: any;

    @Input()
    xLabel: any;

    @Input()
    data: any;

    @Input()
    helpText: string;

/*
    async presentAlert() {
        const title = this.title;
        const subTitle = this.helpText;

        const alert = await this.alertCtrl.create({
            header: title,
            message: subTitle,
            buttons: ['OK']
        });
        alert.present();

    }
*/
    ngOnInit(): void { 
        setTimeout(() => {
        //Highcharts.chart(this.id, {
        this.charts = new Chart({
                chart: {
                    type: 'bubble',
                    plotBorderWidth: 1,
                    backgroundColor: 'transparent',
                    spacingRight: 10,
                    spacingLeft: 0,
                    zoomType: 'xy'
                },
                legend: {
                    enabled: false
                },
                title: {
                    text: (this.subTitle) ? this.subTitle : ''
                },
                xAxis: {
                    min: 50,
                    title: {
                        text: this.xLabel
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: this.yLabel
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '<table>',
                    pointFormat: '<tr><th colspan="2"><h3> {point.name}</h3></th></tr>' +
                    '<tr><th>Positive Entries:</th><td> {point.z}</td></tr>' +
                    '<tr><th>Ave. Score:</th><td> {point.x}</td></tr>',
                    footerFormat: '</table>',
                    followPointer: true
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }
                },
                series: this.data,
            });
        }, 1000); 

    }

    getChart() {  
      return this.charts;
    } 

}
