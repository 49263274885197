import {Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {

  @Output()
  response = new EventEmitter();

  @Input()
  question: any;
  @Input() domain;
  @Input()
  id: any;

  public val: any;

  constructor() { }

  ngOnInit() {
  }

  clear() {
    this.val = '';
  }

  onChange() {
    this.response.emit(JSON.stringify({id: this.id, question: this.question, response: this.val, domain: this.domain}));
  }
}
